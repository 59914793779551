form {
  .background-light {
    background: $neutral-color-light !important;

    .form-control {
      background-color: #ffffff;
    }
  }

  .background-medium {
    background: $neutral-color-medium !important;

    .form-control {
      background-color: #ffffff;
    }
  }

  .background-dark {
    background: $neutral-color-dark !important;

    .form-control {
      background-color: #ffffff;
    }
  }
}

.form-group {
  position: relative;
}

.multiple-date-form {
  padding-top: .5rem;
}

.form-check-label {
  padding: 0px 1rem 0px 0.5rem;
}

select.form-control {
  padding: 1rem 1rem 0px 0.8rem;
}

.form-control {
  border: solid 1.5px #454545;
  color: $default-font-color;
  display: block;
  font-size: 0.9rem;
  min-height: 3rem;
  padding: 1.2rem 1rem 0px .5rem;
  position: relative;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  width: 100%;
  font-weight: 400;
  @include media-breakpoint-down(xs) {
    background-color: #ffffff;
  }

  &.ng-invalid.ng-touched {
    border-color: $red !important;
    color: $default-font-color;
  }
}

.form-control-sm {
  border: solid 1.5px #454545;
  color: $default-font-color;
  display: block;
  font-size: 0.9rem;
  position: relative;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  width: 100%;
  font-weight: 400;
  background-color: #ECECEC;

  @include media-breakpoint-down(xs) {
    background-color: #ffffff;
  }

  &.ng-invalid.ng-touched {
    border-color: $red  !important;
    color: $default-font-color;
  }
}

textarea.form-control {
  //* textarea had some padding difference from input*//
  padding-top: 1.3rem;
}

.zoom-label {
  position: absolute;
  top: 1.3rem;
  left: .6rem;
  color: #454545;
  font-size: .8rem;
  transform-origin: left;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 3;
  font-weight: 400;
  white-space: nowrap;

  &.label-top{
    top: 0.3em !important;
  }
}

.zoom-label-2 {
  position: absolute;
  top: 3px;
  left: .6rem;
  align-self: stretch;
  z-index: 3;
  color: var(--neutral-gray-5-secondary, #63666A);
  font-feature-settings: 'clig' off, 'liga' off; 
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width:2100px) {
    top: 10px;
  }
}

.discription-zoom-lebel{
  position: absolute;
  top: 3px;
  left: .6rem;
  align-self: stretch;
  z-index: 3;
  color: var(--neutral-gray-5-secondary, #63666A);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 90%;
 
  @media screen and (min-width:2100px) {
    top: 9px;
  }
}

.zoom-label-3 {
  position: absolute;
  bottom: 2px;
  left: .6rem;
  transform-origin: left;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 3;
  display: flex;
  width: 344px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  color: #63666A;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; 
  @include media-breakpoint-down(sm) {
    font-size: 0.75rem;
  } 
}

.zoom-label-4 {
  position: absolute;
  top: 5px;
  left: .6rem;
  align-self: stretch;
  z-index: 3;
  color: var(--neutral-gray-5-secondary, #63666A);
  font-feature-settings: 'clig' off, 'liga' off; 
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.form-row-1 {
  margin-bottom: 30px;
}

.form-control:focus ~ .zoom-label,
.form-control.hasLength ~ .zoom-label {
  top: 0.2rem;
  left: .6rem;
  transform: scale(0.8, 0.8);
  color: $formfield-focused-color;
}

.form-control.hasLength ~ .zoom-label {
  color:#454545;
}

.form-control.ng-touched.ng-invalid ~ .zoom-label {
  color: $red;
}

.form-control[ngbDatepicker] ~ .zoom-label {
  left: 0.6rem;
}

/* Other override to get the fields to have rounded corners*/
.input-group > .form-control:not(:last-child) {
  border-radius: .2rem;
}

fieldset {
  position: relative;
  border: none;
}

select.form-control:not([size]):not([multiple]) {
  height: 3rem;
}

/////////////////////////////////////////////////////
fieldset {
  margin-bottom: 1rem;
}
legend {
  color: $default-font-color;
  font-size: 0.9rem;
}

.form-check {
  padding-left: 2.2rem;
  margin-bottom: 0.5rem;

  &.single-check{
    padding-left:21px;
  }

  .form-check-inline {
    padding-left: 1.2rem;
    margin-right: -1rem;
  }

  label::before {
    content: '';
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border: 2px solid $formfield-border-color;
    border-radius: 2px;
    background: #ffffff;
  }

  label::after {
    content: '';
    display: inline-block;
    height: 8px;
    width: 12px;
    border-left: 3px solid;
    border-bottom: 3px solid;
    transform: rotate(-45deg);
  }

  input:checked + label::before {
    border: 2px solid $primary-color;
    background: $primary-color;
  }

  & input[type='checkbox'],
  & input[type='radio'] {
    opacity: 0;
  }

  & label {
    position: relative;
    font-size: 0.9rem;
    margin-bottom: 0.1rem;

    /*Outer-box*/
    &::before {
      position: absolute;
      top: 3px;
      left: -20px;
    }

    /*Checkmark*/
    &::after {
      position: absolute;
      top: 5px;
      left: -18px;
    }
  }

  /*Hide the checkmark by default*/
  input[type='checkbox'] {

    + label::after {
      content: none;
    }

    /*Unhide the checkmark on the checked state*/
    &:checked + label::after {
      content: '';
      color: #ffffff;
    }

    /*Adding focus styles on the outer-box of the fake checkbox*/
    &:focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
    }
  }

  /////////////////////////////////////////////////////////////////////

  /*Hide the checkmark by default*/
  input[type='radio'] {

    + label::after {
      content: none;
    }

    + label::before {
      border-radius: 10px;
    }

    /*Unhide the checkmark on the checked state*/
    &:checked + label::after {
      content: '';
      color: #ffffff;
      border:none;
    }

    /*Adding focus styles on the outer-box of the fake checkbox*/
    &:focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
      border-radius: 10px;
    }
  }
}



// custom styles controls
.select {
  position: relative;

  &:after {
    content: '\f107';
    font-family: $font-family-icons-secondary;
    font-weight: 900;
    color: $primary-color;
    font-size: 1.4rem;
    color: $formfield-border-color;
    right: .5rem;
    top: 1rem;
    position: absolute;
    pointer-events: none;
  }

  select {
    background: #fff;
    border: 1.5px solid $formfield-border-color;
    color: $formfield-border-color;
    box-shadow: none;
    border-radius: .2rem;

    padding: 1.2rem 2rem 0px .5rem;
    text-transform: uppercase;
    font-weight: 400;
    font-size: .9rem;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1.3rem;
    -ms-word-break: normal;
    word-break: normal;

    &::-ms-expand {
      display: none;
    }
  }
}

.inline-button-input {
  text-align: left;
  padding-left: 0;
  margin-right: 0.75rem;
  display: inline-block;
  // float: left;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  @include media-breakpoint-up(sm) {
    width: auto;
  }

  input, .form-check-input {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }

  label {
    background: transparent;
    border-radius: 0;
    border: solid 1.5px #454545;
    color: #454545;
    font-size: .85rem;
    padding: .6rem 3.5rem;
    font-weight: 500;
    border-radius: .2rem;
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:hover {
      background: #696363;
      color: #fff;
      cursor: pointer;
    }

    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  input:focus:checked + label, input:checked + label {
    background: #454545;
    color: #fff;
  }

  &.disabled::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    opacity: .8;
    z-index: 999;
  }
}

.popover-container {
  .inline-button-input {
    &.disabled::before {
      background: $accent-background;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: .8;
      z-index: 999;
    }
  }

  .input-group {
    &.disabled::before {
      background: $accent-background;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: .8;
      z-index: 999;
    }
  }

  .form-group {
    &.disabled::before {
      background: $accent-background;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: .8;
      z-index: 999;
    }
  }
}

fieldset.disabled legend {
  &.disabled::before {
    background: $accent-background;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 999;
  }
}

.select.compact {
  position: relative;
  display: inline;

  &:after {
    content: '\f107';
    font-family: $font-family-icons-secondary;
    font-weight: 900;
    color: $formfield-border-color;
    font-size: 1rem;
    right: .3rem;
    top: .1rem;
    position: absolute;
    pointer-events: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: auto;
    border: none;
    // outline: none;
    background: $neutral-color-lighter;
    padding: .2rem 2rem .2rem .5rem;
    font-size: .7rem;
    border-radius: 0;

    &::-ms-expand {
      display: none;
    }
  }
}

.drop {
  margin-bottom: 25px;
}

.select.preapproval {
  position: relative;
  display: inline;

  &:after {
    content: '\f107';
    font-family: $font-family-icons-secondary;
    font-weight: 600;
    color: $formfield-border-color;
    font-size: 1.3rem;
    right: .3rem;
    top: -0.3rem;
    position: absolute;
    pointer-events: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: auto;
    border: none;
    // outline: none;
    background: $neutral-color-lighter;
    padding: .2rem 2rem .2rem .5rem;
    font-size: .7rem;
    border-radius: 0;

    &::-ms-expand {
      display: none;
    }
  }
}

.selectDD {
  position: relative;

  &:after {
    content: '\f107';
    font-family: $font-family-icons-secondary;
    font-weight: 900;
    color: $primary-color;
    font-size: 1.4rem;
    color: $formfield-border-color;
    right: .5rem;
    top: 1rem;
    position: absolute;
    pointer-events: none;
  }

  select {
    background: #fff;
    border: 1.5px solid $formfield-border-color;
    color: $formfield-border-color;
    box-shadow: none;
    border-radius: .2rem;

    padding: 1.2rem 2rem 0px .5rem;
    text-transform: uppercase;
    font-weight: 400;
    font-size: .9rem;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1.3rem;
    -ms-word-break: normal;
    word-break: normal;

    &::-ms-expand {
      display: none;
    }
  }
}

.compactDD {
  position: relative;
  display: inline;

  &:after {
    content: '\f107';
    font-family: $font-family-icons-secondary;
    font-weight: 900;
    color: $formfield-border-color;
    font-size: 1.3rem;
    right: .3rem;
    top: -0.3rem;
    position: absolute;
    pointer-events: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    // outline: none;
    background: $neutral-color-lighter;
    padding: .2rem 2rem .2rem .5rem;
    font-size: 13px;
    border-radius: 0;
    color: #000000;
    font-family: Helvetica;
    font-weight: 600;
    border: 1px solid #63666A;
    
    &::-ms-expand {
      display: none;
    }
  }
}
@media screen and (max-width:767px) {
  .compactDD {
    select {
      width:auto;
    }
  }
}
.map {
  width: 100%;
  height: 550px;
  position: relative;
  margin-top: 2rem;
}

.mapdtl {
  width: 100%;
  height: 100%;
  position: absolute;
}

@media screen and (max-width: 767px) {
  .mapdtl {
    width: 100%;
    height: 500px;
    position: relative;
  }  
}

.map-card-detail {
  height: 110px;
  width: 290px;
  border-radius: 3px;
  background-color: #FFFEFE;
  padding-left: 17px;   
}

.infobox-body
{
  overflow: hidden;
}
.hospname-detail {
  height: 33px;
  width: 95%;
  color: #292931;
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;  
}

.group-detail {
  height: 55px;
  width: 100%;
}

.pcpaddress-detail {
  height: 35px;
  width: 265px;
  color: #292931;
  font-family: Roboto;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.1250rem;
  margin-bottom: 6px;
}

.pcpdirections-est {
  height: 16px;
  width: 234px;
  color: #292931;
  font-family: Roboto;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 6px; 
  text-decoration: underline;
}

.width25{
  width:25px;
}

.provLoc:hover {
  background-color: #ECECEC;
}
.highlight{ background-color: grey };

.Margintop25
{
  margin-top: 25px;
}

.paddingleft35 {
  padding-left: 35rem;
}
.mb-custom39{
  margin-bottom: -3.9rem!important;
}
.mt-custom15{
  margin-top: 1.5rem;
}
//specific

// PCP Result Card Map View
.infobox-info {
  max-height: 220 !important;
  .map-card {
    height: auto;
    background-color: #FFFEFE;  
    .infobox-pcpName {
      color: #313234;
      font-family: Lora;
      font-size: 1.25rem;
      letter-spacing: 0;
      line-height: 27px;
      word-wrap: break-word
    }
    .pcpcontainer {
      border: black;
      max-width: 57px !important;
      height: 25px;
      background-color: #117bab;
      color: white;
      border-radius: 15px;
      text-align: center;
      .pcptext {
        height: 24px;
        width: 60px;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 0.875rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 31px;
        text-align: center;
        margin-left: -15px;
        margin-top: -3px;
      }
    }
  }
  .pcpgroup {
    .infobox-specialty {
      color: #292931;
      font-family: Roboto;
      font-size: 0.875rem;
      letter-spacing: 0;
      line-height: 16px;  
    }
    .infobox-pcpaddress {
      color: #292931;
      font-family: Roboto;
      font-size: 0.875rem;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
  .detailsbox {
    border-radius: 5px;
    background-color: #82C341;
    color: white;
    text-decoration: none;
  }
  a.details:link,
  a.details:visited,
  ul.infobox-actions-list {
    color: #000000;
    font-family: Roboto;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.44px;
  }
  .infobox-accept,
  .infobox-notaccept{
    color: #292931;
    font-family: Roboto;
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 27px;
  }
}
@media screen and (max-width:420px) {
  .infobox-info {
    .map-card {
      .pcpcontainer {
        margin-left:unset !important
      }
    }
    .detailsbox {
      .details {
        padding-left: 10px !important;
      }
    }
  }
}

.bing-map-select {
  border: 1px solid black;
  padding: 0.4rem;
  background-color: #e8e8e8;
  option {
    background: #e8e8e8;
  }
}
.multiple-loc {
  color:#000000;
}

.claim-header {
  color: #313234;
  font-family: Lora;
  font-size: 2.65rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0px;
  //white-space: nowrap;
  /* 0% */
}

.claim-header-highlight {
  width: 3.4375rem;
  height: 6.029px;
  flex-shrink: 0;
  background: linear-gradient(270deg, #67C5C9 0%, #0099D8 100%);
}

.claim-landingpage-subheader {
  color: #313234;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.lora-font{
  font-family: Lora;
}

.claim-card {
  display: flex;
  width: 23.6250rem;
  height: 16.1250rem;
  padding: 1.8750rem 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.8750rem;
  flex-shrink: 0;
  border-radius: 3px;
  background: var(--neutral-white, #FFF);
  /* Card shadow */
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.24);
  margin-bottom: 15px;
  border: 5px solid transparent;

  &:hover{
    border: 5px solid #117BAB;
  }
  &:focus{
    border: 5px solid #117BAB;
  }
}


.claim-card-tittle {
  color: var(--neutral-gray-7, #313234);
  font-feature-settings: 'clig' off, 'liga' off;
  /* Desktop/Heading/H3 */
  font-family: Lora;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  align-self: stretch;
  /* 120% */
}

.claim-card-details {
  color: var(--neutral-gray-7, #313234);
  font-feature-settings: 'clig' off, 'liga' off;
  /* Desktop/Body/Regular */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  align-self: stretch;
  /* 150% */
}

.claim-drafts {
  color: #000; 
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 1.1250rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0px;  
  margin-bottom: 15px;
    /* 0% */
}

.claim-draft-infotext {
  color: var(--neutral-white, #FFF);
  font-family: Roboto;
  font-size: 0.8750rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3750rem;
  width: 246px;
  /* 157.143% */
}

.claim-draft-infobox {
  width: 270px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #404144;
}

.claim-draft-detail {
  color: #117BAB;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 0.8750rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}

.claim-draft-date {
  color: #63666A;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 0.8750rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5rem;
  margin-left: 15px;
  margin-right: 15px;
}

.margin-left15px {
  margin-left: 15px;
}

.btnnoborder {
  border: none;
  outline: none;
  background: none;
}

.claim-section-header {
  color: #313234;
  font-size: 1rem;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 15px;
}

.claim-section-subheader {
  color: #313234;
  font-family: Lora;
  font-size: 1.7584rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
}

.claim-selectallapply {
  color: #63666A;
  font-size: 1.1250rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}


.service-list-style{
  padding-left: 2rem;
  border: 1px solid rgb(202, 202, 247);
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  width: 50%;
}

.claim-section-chkboxlabel {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.1250rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1250rem;
  margin-bottom: 10px;
  display: block;
  padding: auto;
  white-space: nowrap;
}

.claim-selectlenstype {
  color: #63666A;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 5px;
}

.claim-section-bold-text{
  font-weight: 550;
  font-size: 1.1250rem;
  line-height: 1.3125rem;
  color: #292931;
  ;
}

.claim-section-error-text{
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  color: red;

  @media screen and (min-width: 2100px) and (max-width: 2200px){
    margin-top: 0.95rem;
  }

  @media screen and (max-width: 767px) {
    margin-top: 0.5rem;
  }
}


.claim-section-text{
  font-weight: 400;
  font-size: 1.1250rem;
  color: #292931;
  line-height: 1.6875rem; 
  li{
    margin: 0.8rem 0;
  }
}

.claim-left-side-alignment {
  width: 128px;
  margin-left: 315px;
  position:relative;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-shrink: 0;

}

.margin-top20px {
  margin-top: 20px;
}

.multipleDOS {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section-title {
  color: #313234;
  font-size: 1.063rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
@media screen and (min-width : 2799px){
  .claim-card{
    width: 23rem;
    height: 17rem;
  }
}

@include media-breakpoint-down(xl){
  .claim-card{
    width: 28rem;
    height: 21rem;
  }
}

@include media-breakpoint-down(lg){
  .claim-card{
    width: 24rem;
    height: 17rem;
  }
}

@include media-breakpoint-down(md){
  .claim-card{
    width: 22rem;
    height: 16.1250rem;
  }
}

.input-box{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  background: #FFF;
  background: var(--neutral-white, #FFF);
  border-radius: 4px;
  //border: 2px solid var(--neutral-gray-8-icons, #1F1F1F);
  background: var(--neutral-white, #FFF);
  width: 22rem;
}

.input-box-2{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  background: #FFF;
  background: var(--neutral-white, #FFF);
  border-radius: 4px;
  border: 2px solid var(--neutral-gray-8-icons, #1F1F1F);
  background: var(--neutral-white, #FFF); 
}

.input-box-3{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  background: #FFF;
  background: var(--neutral-white, #FFF);
  border-radius: 4px;
  border: 3px solid var(--neutral-gray-8-icons, #1F1F1F);
  background: var(--neutral-white, #FFF);
  width: 100%;
  margin-top: 15px;
  height: 8.438rem;
}

.input-invisible{
  padding-bottom: 70px;
  border: none;
  border-color: transparent;
  width:100%;
  height: 130px;
}

.text-align-right {
  display: inline-block;
  float: right;
}

.purchased-us-block{
  width: 55%;
  @media (max-width: 500px) {
    width: 100%;
  } 
}

.cost-input-box {
  width: 23.438rem;
  height: 3.3rem;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid var(--neutral-gray-8-icons, #1F1F1F);
  //border: 2px solid #000;
  background: #FFF; 
}

#glass-dropdown{
  width: 23.438rem;
}

.claim-border-blue-box {
  display: flex;
  max-width: 845px;
  border-radius: 20px;
  border-top: 1px solid #0099D8;
  border-right: 20px solid #0099D8;
  border-bottom: 1px solid #0099D8;
  border-left: 20px solid #0099D8;
  background: #FAFAFA;
  padding: 25px 25px 10px 25px;
}

.confirmation-circle-check {
  display: flex;
  width: 64px;
  height: 63.448px;
  padding-right: 10px;
}

.claim-confirmation-banner-title {
  color: #313234;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.none-of-these-style{
  color: var(--premera-neutral-4, #9E9E9E);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.smallLoader {
  height: 1.25Rem !important;
  width: 1.25Rem !important;
}

.description-text-area-height{
  height: 5.65em !important;
  top: 2.4rem;
  position: relative;
  border: none;
  outline: none;
  margin-left: 0.5rem;
}

.form-control.ng-touched.ng-invalid~.zoom-label-2 {
  color: #dc3545;
}

a.anchor-disable {
  color: gray;
  cursor: text;
  pointer-events: none;
  text-decoration: none;
}

.form-detail-height {
  height: 48px;
}

.provider-search{
  display: inline-block;
  width: 75%;

  @include media-breakpoint-down(sm) {
    width: 70%;
  }
}

.form-space {
  margin-top: 16px;
}

.providerSearchLayout {
  width: 25rem; 
  flex-shrink: 0;  
  background: #FFF;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 720px;
  border-radius: 4px 4px 0 0;
  border: 2px solid var(--Premera-Brand-5, #B7E3F6);
  background: var(--Premera-White, #FFF);
}

.noResultBlock { 
  color: #1F1F1F;
  font-feature-settings: 'clig' off, 'liga' off;
  //font-family: Roboto;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin: 18px 26px 18px 27px;
}

.providerSearchRow {  
  height: 100px;
  width: 25rem;
  flex-shrink: 0;
  text-wrap: nowrap;
  border-top: 1px solid var(--Premera-Brand-5, #B7E3F6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
  overflow: hidden; 
  &:hover {
    border-top: 1px solid var(--Premera-Brand-5, #B7E3F6);
    background: var(--premera-brand-6-background, #EFF9FD);
  }
}

.radio-input-size{
  height: 24px;
  width: 24px;
  border-radius: 2px;
  accent-color: #117BAB;
}

.resultProviderName {
  color: var(--Premera-Neutral-1, #1F1F1F);
  //font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 0;
  width: 100%;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resultAddress {
  color: var(--Premera-Neutral-2, #404144);
  //font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.resultPhone {
  color: var(--Premera-Neutral-2, #404144);
  //font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 0;
}

.resultPhoneNbr {
  color: var(--Premera-Neutral-2, #404144);
  //font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 0;
  margin-left: 0.5rem;
}

.providerNotListed {
  width: 25rem;
  height: 60px;
  flex-shrink: 0;  
  padding-top: 1.25rem;
  &:hover { 
    background: var(--premera-brand-6-background, #EFF9FD);
  }
}

.providerpagination {
  width: 25rem;
  flex-shrink: 0;
  border-top: 1px solid var(--Premera-Brand-5, #B7E3F6);
  border-bottom: 1px solid var(--Premera-Brand-5, #B7E3F6);
  background: #FFF;
  display: flex;
  padding-top: 0.75rem;
  flex-direction: column;
  align-items: flex-end;
}

.providerNotListedlabel {
  color: #292931; 
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 2px;
}

.hreflink {
  cursor: pointer;
  color: #117bab;
  &:hover {
    text-decoration: underline;
  }
}

.hreflink-disable {
  color: gray;
  cursor: text;
  pointer-events: none;
  text-decoration: none;
}

.providerloadingPanel {
  width: 25rem;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid var(--Premera-Brand-5, #B7E3F6);
  background: var(--Premera-White, #FFF);
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 200px
}

.provider-popup {
  position: absolute;
  top: 0;
  margin-left: -15rem;

  @media  (max-width:650px) {
    margin-left: -3.6rem;
  }

  .not-found-message{
    color: var(--Premera-Neutral-1, #1F1F1F);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    padding: 15px 14px;
    margin-bottom: 0;
  }

  .select-provider-btn{
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--Premera-White, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .select-provider{
    width: 25rem;    
    flex-shrink: 0;
    background: #FFF;
    padding-top: .5rem;
    display: flex;
    justify-content: right;
    align-items: center;
    border-radius: 0 0 4px 4px;
  }
}

.providerPageResult {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ProviderBD{
  border-bottom: 1px solid var(--Premera-Brand-5, #B7E3F6);
}

.providerResults {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.backgroundLB {
  background: var(--premera-brand-6-background, #EFF9FD);
}