// -----------------------------------------------------------------------------
// This file contains all styles related to the Table
// -----------------------------------------------------------------------------

/***************************************************
        TABLE OF CONTENTS

- table base

******************************************************/


/**
** Base Table style
**/

// Mixins
@mixin transition($transition) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

// Tables

.card .table {
  margin-bottom: 0;
  border: none;
}

//for compact tables on colored background
table.striped tr:nth-child(even) {
  background-color: $neutral-color-lightest;

  @include media-breakpoint-down(sm) {
    background-color: $neutral-color-light;
  }
}

.preapproval-table tr:nth-child(even) {
  background-color: $neutral-color-lightest;
}


.table {
  border-bottom: 1px solid $table-border-header-color;
  margin-bottom: 3rem;

  thead {
    tr {
      display: flex !important;

      th {
        border-bottom: none;
        border-top: none;
        font-size: .88rem;
        vertical-align: bottom;
        padding: 1rem 0rem;

        &:first-child {
          padding-left: .8rem;
        }

        &:last-child {
          padding-right: .8rem;
          text-align: right;
        }
      }
    }
  }

  tr {
    position: relative;

    &.collapse-toggle {
      display: flex !important;

      &:hover {
        cursor: pointer;
      }
    }

    &.show.collapse {
      display: flex !important;

      td {
        border: 0;

        &:last-child {
          padding-right: 0rem;
          text-align: left;
        }
      }
    }

    &.collapsed {
      &:after {
        font-family: $font-family-icons-secondary;
        content: '\f078';
        font-weight: 900;
        position: absolute;
        right: 0;
        top: 15%;
        font-size: 2rem;
      }
    }

    &.opened {
      &:after {
        font-family: $font-family-icons-secondary;
        content: '\f077';
        font-weight: 900;
        position: absolute;
        right: 0;
        top: 10%;
        font-size: 2rem;
      }
    }

    td,
    th {
      padding: 1.2rem 0rem;
      font-size: 1rem;
      font-weight: 500;
      border-color: $table-border-color;

      &:first-child {
        padding-left: .8rem;
      }

      &:last-child {
        padding-right: .8rem;
        text-align: right;
      }
    }
  }
}

// fix IE 11 issues
.table.no-flex thead tr {
  display: table-row !important;
}

// Nesting
.table {
  background-color: $table-bg;
}

// Remove border
.no-border {
  border: 0;
}

// Condensed table w/ half padding
.table-condensed {

  >thead,
  >tbody,
  >tfoot {
    >tr {

      >th,
      >td {
        padding: $table-condensed-cell-padding;
      }
    }
  }
}

// Bordered version
// Add horizontal borders between columns.
.table-bordered {
  border: 1px solid $table-border-color !important;
  border-radius: 0.25rem !important;

  >thead,
  >tbody,
  >tfoot {
    >tr {

      >th,
      >td {
        border: 0;
        border-bottom: 1px solid $table-border-color;
      }
    }
  }

  >thead>tr {

    >th,
    >td {
      border-bottom-width: 2px;
    }
  }
}

// Zebra-striping
// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.table-striped {
  >tbody>tr:nth-child(odd) {

    >td,
    >th {
      background-color: $neutral-color-lightest;
    }
  }
}

// Hover effect
.table-hover {
  >tbody>tr:hover {

    >td,
    >th {
      background-color: $neutral-color-light;
    }
  }
}

// Responsive tables (vertical)
// Wrap your tables in `.table-responsive-vertical` and we'll make them mobile friendly
// by vertical table-cell display. Only applies <768px. Everything above that will display normally.
// For correct display you must add 'data-title' to each 'td'
.table-responsive-vertical {
  >.table {
    >thead {
      tr {
        display: table-row !important;
      }
    }
  }

  @media screen and (max-width: 768px) {

    // Tighten up spacing
    >.table {
      margin-bottom: 0;
      background-color: transparent;
      border: none;

      >thead,
      >tfoot {
        display: none;
      }

      >tbody {
        display: block;

        >tr {
          display: block;
          border-radius: $border-radius;
          margin-bottom: $table-cell-padding;
          border: 1px solid $table-border-color;

          >td {
            background-color: $table-bg;
            display: block;
            text-align: left;
            border: none;
            padding-left: 1rem;

            &:last-child {
              padding-right: 0rem;
            }

            &:hover {
              background: $neutral-color-light;
            }
          }

          >td[data-title]:before {
            content: attr(data-title);
            float: left;
            font-size: inherit;
            font-weight: $table-header-font-weight;
            color: $table-header-font-color;
            text-align: left;
            width: 33%;
          }
        }
      }
    }

    // Special overrides for shadows
    &.shadow-z-1 {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      >.table>tbody>tr {
        border: none;
        // .shadow-z-1();
      }
    }

    // Special overrides for the bordered tables
    >.table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      >tbody {
        >tr {
          >td {
            border: 0;
            border-bottom: 1px solid $table-border-color;
          }

          >td:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    // Special overrides for the striped tables
    >.table-striped {

      >tbody>tr>td,
      >tbody>tr:nth-child(odd) {
        background-color: $table-bg;
      }

      >tbody>tr>td:nth-child(odd) {
        background-color: $neutral-color-lightest;
      }
    }

    // Special overrides for hover tables
    >.table-hover {
      >tbody {

        >tr:hover>td,
        >tr:hover {
          background-color: $table-bg;
        }

        >tr>td:hover {
          background-color: $neutral-color-light;
        }
      }
    }
  }
}


.box {
  height: 30px;

  &:hover {
    // @include animation-slide-in-left();
    animation: animation-slide-in-left 100s;
  }
}

@keyframes animation-slide-in-left {
  0% {
    transform: translate(-100%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

// styles for table alternative in mobile
.mobile-table {
  // margin-left: -3rem;
  // width: calc(100% + 6rem); styles need to be put back in new design
  margin-bottom: 3rem;

  .mobile-rows {
    padding: 1rem;
    border-bottom: 1px solid $neutral-color-medium !important;

    &:first-child {
      border-top: 1px solid $neutral-color-medium !important;
    }

    .mobile-row {
      @include make-row();

      .mobile-content {
        padding: 1rem;

        @include make-col-ready();

        @include media-breakpoint-up(xs) {
          @include make-col(10);
        }

        @include media-breakpoint-up(sm) {
          @include make-col(11);
        }
      }

      .mobile-collapse {
        //  col-2 col-sm-1 align-self-center text-right
        text-align: right;
        -ms-grid-row-align: center !important;
        align-self: center !important;

        @include make-col-ready();

        @include media-breakpoint-up(xs) {
          @include make-col(2);
        }

        @include media-breakpoint-up(sm) {
          @include make-col(1);
        }

        i:hover {
          cursor: pointer;
        }
      }
    }


  }

  .th {
    font-size: 0.85rem;
    color: #1F1F1F;
    font-weight: 500;
  }

  .td {
    font-size: 1rem;
    color: #1F1F1F;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}


.table {
  &.collapsible {
    tr {
      &.d-flex {
        cursor: pointer;
      }
    }

    .arrow {
      position: relative;

      &:after {
        font-family: "Material Icons";
        content: '\e313';
        position: absolute;
        font-size: 2rem;
        right: 0;
        top: .8rem;
      }

      &.active {
        &:after {
          content: '\e316';
        }
      }
    }
  }
}

.referral-td {
  width: 15%;
}

.referral-td-change {
  width: 20%;
}

.referral-td-space {
  width: 25%;
}

#preapprovalListTable table.striped tr:nth-child(odd) {
  background-color: $neutral-color-lightest!important;

  @include media-breakpoint-down(sm) {
    background-color: $neutral-color-light!important;
  }
}

#preapprovalListTable table.striped tr:nth-child(even) {
  background-color: transparent!important;

  @include media-breakpoint-down(sm) {
    background-color: transparent!important;
  }
}