@include media-breakpoint-up(xs) {
  .w-xs-25, .w-25 {
    width: 25% !important;
  }
  .w-xs-50, .w-50 {
    width: 50% !important;
  }
  .w-xs-75, .w-75 {
    width: 75% !important;
  }
  .w-xs-100, .w-100 {
    width: 100% !important;
  }
  .w-xs-auto, .w-auto {
    width: auto !important;
  }
  .h-xs-25, .h-25 {
    height: 25% !important;
  }
  .h-xs-50, .h-50 {
    height: 50% !important;
  }
  .h-xs-75, .h-75 {
    height: 75% !important;
  }
  .h-xs-100, .h-100 {
    height: 100% !important;
  }
  .h-xs-auto, .h-auto {
    height: auto !important;
  }
}


@include media-breakpoint-up(sm) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
}

@include media-breakpoint-up(md) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
}

@include media-breakpoint-up(lg) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
}

@include media-breakpoint-up(xl) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
}

@include media-breakpoint-up(xxl) {
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
}

@include media-breakpoint-up(xxxl) {
  .w-xxxl-25 {
    width: 25% !important;
  }
  .w-xxxl-50 {
    width: 50% !important;
  }
  .w-xxxl-75 {
    width: 75% !important;
  }
  .w-xxxl-100 {
    width: 100% !important;
  }
  .w-xxxl-auto {
    width: auto !important;
  }
  .h-xxxl-25 {
    height: 25% !important;
  }
  .h-xxxl-50 {
    height: 50% !important;
  }
  .h-xxxl-75 {
    height: 75% !important;
  }
  .h-xxxl-100 {
    height: 100% !important;
  }
  .h-xxxl-auto {
    height: auto !important;
  }
}
