.call-out {
  border-bottom: 1px solid $neutral-color-light;
  border-top: 1px solid $neutral-color-light;
  margin: 3rem 0rem;
  padding: 1.5rem;

  p:last-child {
    margin-bottom: 0;
  }
}
