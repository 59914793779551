.tooltip .arrow {
  display: none;
}

a.tip {
  border-bottom: 1px dashed;
  text-decoration: none
}
a.tip:hover {
  cursor: help;
  position: relative
}
a.tip span {
  display: none
}
a.tip:hover span {
  border: $neutral-color-medium 1px solid;
  padding: 0.2632rem 1.053rem 0.2632rem 0.2632rem;
  display: block;
  z-index: 100;
  background: $neutral-color-lightest;
  left: 0px;
  margin: 0.5263rem;
  width: 13.16rem;
  position: absolute;
  top: 0.5263rem;
  text-decoration: none
}
