.type-of-provider-header{
  position: absolute;
  top: 0.1rem;
  left: .5rem;
  color: #454545;
  font-size: 0.8rem;
  transform-origin: left;
  pointer-events: none;
  z-index: 3;
  font-weight: 500;
}

.type-of-provider-service-header{
  
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-style: normal;
  color: #000;
  font-size: 18px;
  font-weight: bold; 
  margin-bottom: 10px;
}

.type-of-provider-service{
  
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-style: normal;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.checkboxes {
  text-align: center;
}

.checkboxes input{
  margin: 0px 0px 0px 0px;
}

.checkboxes label {
  margin: 0px 20px 0px 3px;
}

.type-of-provider  {
@extend .type-of-provider-header;
  left: 2rem;
}
