.input-group {

  // append
  .input-group-append {
    .input-group-text {
      background: $formfield-background-color;
      border-bottom-right-radius: 0;
      border-right: none;
      border-top: none;
      border-bottom-color: $formfield-border-color;
      padding: 1.2rem 1rem 0 0.4rem;
    }

    button {
      background: none;
      border: none;
      position: absolute;
      right: .4rem;
      top: 1.15rem;
      z-index: 998;

      &:hover {
        cursor: pointer;
      }
    }

    button img {
      width: 1.2rem;
      height: 1rem;
      cursor: pointer;
    }
  }

  .input-group-append-sm {
    .input-group-text {
      background: $formfield-background-color;
      border-bottom-right-radius: 0;
      border-right: none;
      border-top: none;
      border-bottom-color: $formfield-border-color;
      padding: 1.2rem 1rem 0 0.4rem;
    }

    button {
      background: none;
      border: none;
      position: absolute;
      right: .4rem;
      top: 0.2rem;
      z-index: 998;

      &:hover {
        cursor: pointer;
      }
    }

    button img {
      width: 1.2rem;
      height: 1rem;
      cursor: pointer;
    }
  }

  //prepend
  .input-group-prepend {
    +input.form-control {
      border-top-left-radius: 0;
    }

    .input-group-text {
      background: $formfield-background-color;
      border-bottom-left-radius: 0;
      border-left: none;
      border-top: none;
      border-bottom-color: $formfield-border-color;
      border-top-left-radius: $formfield-border-radius;
      padding: 1.2rem 0rem 0 1rem;
    }
  }
}

.pcpradio {
  padding: 0 0 0 0.9375rem;
}

.pcpguidebtn {
  padding: 1% 0 3% 50%;
}

.pcpguidecheck {
  padding: 0.625rem 0 0 0.9375rem;
}

.pcpdiv {
  padding: 5% 0 0 9%;
}

.pcpCheckbox {
  box-sizing: border-box;
  height: 3rem;
  width: 19.0625rem;
  border: 0.1875rem solid #1F1F1F;
  border-radius: 0.25rem;
  color: #292931;
  font-size: 0.775rem;
  letter-spacing: 3;
  line-height: 1.325rem;
  padding-left: 1.25rem;
}

.pcpguidepad {
  margin-right: 3rem;
}

.pcpcard {
  margin: .8rem 0rem .8rem .8rem;
}

.reducemargin {
  margin-left: -5%;
}

.lacationpad {
  padding-bottom: 0.625rem;
}

.pcpCheck {
  margin-right: 0.3125rem;
}

.membername_selpcpmodalcheckbox {
  height: 27px;
}

@media screen and (max-width:710px) {
  .pcpcard {
    margin: .75rem 0px .75rem .75rem;

  }
}

@media screen and (max-width:820px) {
  .pcpguidebtn {
    padding: 1% 0 3% 40%;
  }
}

@media screen and (max-width:650px) {
  .pcpguidebtn {
    padding: 1% 0 3% 20%;
  }
}

@media screen and (max-width:500px) {
  .pcpguidebtn {
    padding: 1% 0 3% 0%;
  }

}

@media screen and (max-width:300px) {
  .pcpguidebtn {
    padding: 1% 0 3% -20%;
  }
}

.referral-textbox {
  border-radius: 0% !important;
  height: 2.19rem;
}

.referral-checkbox {
  display: flex;
}

.referral-checkbox label {
  cursor: pointer;
  display: flex;
}

.referral-checkbox input[type='checkbox'] {
  cursor: pointer;
  display: none;
  position: absolute;
}

.referral-checkbox label::before {
  content: '';
  box-sizing: border-box;
  height: 1.125rem;
  width: 1.125rem;
  margin-right: .5em;
  border: 0.125rem solid #1F1F1F;
}

.referral-checkbox input[type='checkbox']:checked {
  display: inline;
}

.referral-checkbox input[type='checkbox']:checked+label::before {
  border: 0rem;
  opacity: 6.25rem;
}

.referra-font {
  font-family: "Roboto", sans-serif !important;
}

.referral-input-size {
  height: 1.125rem;
  width: 1.125rem;
  border-radius: 0.125rem;
  accent-color: #117BAB;
}

.preapproval-checkbox {
  display: flex;
}

.preapproval-checkbox label {
  cursor: pointer;
  display: flex;
}

.preapproval-checkbox input[type='checkbox'] {
  cursor: pointer;
  display: none;
  position: absolute;
}

.preapproval-checkbox label::before {
  content: '';
  box-sizing: border-box;
  height: 18px;
  width: 18px;
  margin-right: .5em;
  border: 2px solid #1F1F1F;
}

.preapproval-checkbox input[type='checkbox']:checked {
  display: inline;
}

.preapproval-checkbox input[type='checkbox']:checked+label::before {
  border: 0px;
  opacity: 100px;
}

.preapproval-input-size {
  height: 18px;
  width: 18px;
  border-radius: 2px;
  accent-color: #117BAB;
}