
.step-form {
  div.step.disabled {
    &::before{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #fff;
      opacity: .8;
      z-index: 999;

      &:hover {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }

  .step {
    border-top: 1px solid #e1e0db;
    position: relative;

    &.completed {
      .step-heading {
        margin-bottom: 0;
      }

      .step-header {
        font-size: .9rem !important;
        font-weight: 400 !important;
      }

      .step-question {
        margin-left: 2.3rem;
      }

      .step-summary {
        margin-left: 2.25rem;
      }

      .step-more {
        margin-left: 2.5rem;
      }
    }



    .step-heading {
      @include media-breakpoint-up(sm) {
        @include make-col(10);
      }
      @include media-breakpoint-down(xs) {
        @include make-col(12);
      }

      margin-top: 1rem;

      .step-header {
        font-size: 1.44rem;
        margin-left: .4rem;
        font-weight: 500;
        display: inline-block;
        color: #1F1F1F;
      }

      .step-indicator {
        margin-right: .5rem;
        display: inline;

        .step-number {
          background: #ffffff;
          border: 1px solid #1F1F1F;
          color: #1F1F1F;
          border-radius: 9rem;
          display: inline-block;
          width: 1.4rem;
          height: 1.4rem;
          text-align: center;
          line-height: 1.3rem;
          font-size: .9rem;
          vertical-align: text-bottom;
        }

        i {
          font-size: 1.4rem;
          margin-left: -.12rem;
          color: #2a8703;
        }
      }
    }

    .step-change {
      @include make-col-ready();
      @include media-breakpoint-up(sm) {
        @include make-col(2);
      }
      @include media-breakpoint-down(xs) {
        @include make-col(12);
        text-align: right;
      }

      padding-top: .5rem;
      text-align: right;

      a, button {
        text-transform: uppercase;
        color: $primary-color;
        font-weight: 500;
        font-size: .9rem;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .step-question {
      margin: 2rem 0 1rem 2.3rem;
    }

    .step-summary {
      margin: 0 0 1rem 2.5rem;
    }

    .step-more {
      margin: 0 0 1rem 2.5rem;
    }

    .step-done {
      margin-bottom: 1rem;
      margin-left: 2.3rem;

      a {
        margin-right: 0;
      }
    }
  }
}
