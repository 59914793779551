.accum {
  width: 75px;
  height: 165px;
  text-align: center;
  margin-left: 1.5rem;
  font-size: .8rem;
  line-height: 1.3;

  .accum-container {
    background: $neutral-color-light;
    border-radius: .2rem;
    height: 128px;
    width: 100%;
    position: relative;

  }

  .accum-bar {
    background: $accent-tertiary-color;
    height: 40px;
    position: absolute;
    bottom: 0;
    z-index: 500;
    right: 0;
    left: 0;
    width: 100%;
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    color: #fff;
    font-weight: 300;

  }

  .accum-label {
    text-align: center;
    font-weight: 400;
  }

  .accum-used-high {
    padding-top: 5px;
  }

  .accum-used-low {
    position: relative;
    height: 100%;

    div {
      position: absolute;
      text-align: center;
      width: 100%;
      padding-bottom: 5px;
    }
  }
}


.vertical-accum {
  border-bottom: 1px solid $neutral-color-lighter;
  margin-bottom: 1rem;

  * {
    position: relative;
  }

  .accum-bar {
    background: $neutral-color-lighter;
    height: 100%;
    position: absolute;
    width: 4rem;
    margin: 0 auto;
    bottom: 0;
    right: 1rem;

    .accum-used {
      height: 33%;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0099d8+0,ffffff+100 */
      background: #0099d8; /* Old browsers */
      background: -moz-linear-gradient(45deg, #0099d8 0%, #9adff4 100%); /* FF3.6-15 */
      background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,#0099d8), color-stop(100%,#9adff4)); /* Chrome4-9,Safari4-5 */
      background: -webkit-linear-gradient(45deg, #0099d8 0%,#9adff4 100%); /* Chrome10-25,Safari5.1-6 */
      background: -o-linear-gradient(45deg, #0099d8 0%,#9adff4 100%); /* Opera 11.10-11.50 */
      background: -ms-linear-gradient(45deg, #0099d8 0%,#9adff4 100%); /* IE10 preview */
      background: linear-gradient(45deg, #0099d8 0%,#9adff4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0099d8', endColorstr='#9adff4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 9999;
      width: 120%;
      left: -10%;
      opacity: 0.7;

      &.completed {
        &:after {
          font-family: $font-family-icons-secondary;
          font-weight: 900;
          content: '\f058';
          color: #fff;
          font-size: 2rem;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          top: 50%;
        }
      }
    }


  }
  .accum-label-total {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .accum-label-left {
    //font-size: .8rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .accum-label-paid {
    //font-size: .8rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
    font-weight: 600;
  }
}
