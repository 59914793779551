// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.navbar-brand {
  margin: .85rem 0;
  padding: 0;
  &> img {
    width: 100%;
    max-width: 10rem !important;
    @include media-breakpoint-down(sm) {
      max-width: 12rem !important;
    }
    &.second-logo {
      width:80%;
      max-width: 9rem!important;
    }
  }
}

header {
  position: relative;
  padding: 0rem 3rem;

  @include media-breakpoint-down(sm) {
    padding: 0rem 2rem;
  }

  @include media-breakpoint-down(xs) {
    padding: 0rem 1rem;
  }

  border-bottom: 1px solid $neutral-color-light;


  .navbar-toggler {
    margin: 0 1rem 0 0;
    padding: 0.25rem 0 0.25rem 0rem;

    i {
      font-size: 2.6rem;
    }
  }

  nav:first-child  {
    @include make-row();
    display: flex;
    flex-wrap: wrap;
    background: $accent-background;
    margin-left: -3rem !important;
    width: calc(100% + 6rem) !important;
    justify-content: flex-end;

    @include media-breakpoint-down(sm) {
      width: calc(100% + 5rem) !important;
    }

    @include media-breakpoint-down(xs) {
      width: calc(100% + 4rem) !important;
    }

    .nav-tertiary-container {
      // col-auto
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;

      margin-left: auto !important;
      padding: .5rem 3rem;
      @include media-breakpoint-down(sm) {
        padding: .5rem 2rem;
      }
      @include media-breakpoint-down(xs) {
        padding: .5rem 1rem;
      }


      ul.nav-tertiary  {
        list-style: none;
        margin:0;
        padding:0;

        li {
          display: inline-block;

          a {
            color: $default-font-color;
            font-size: .9rem;

            @include media-breakpoint-down(xs) {
              font-size: .8rem;
            }
          }

          &:not(:last-child) {
            a:after {
              content: '|';
              margin: 0 .5rem;
            }
          }
        }
      }

      .state-toggle {
        position: relative;

        i {
          float: left;
          font-size: 0.6rem;
          margin-top: .44rem;
          margin-right: .27rem;
        }

        .choice {
          width: auto;
          font-size: .9rem;
          float:left;
          line-height: 1;
          margin-top: .33rem;
          @include media-breakpoint-down(xs) {
            font-size: .8rem;
          }

          a {
            color:$default-font-color;
          }

          &:nth-child(2n) {
            margin-right: .55rem;
          }

          &:last-child {
            margin-left: .55rem;
            float: right;
            margin-right:0;
          }
        }

        .switch {
          bottom: -.27rem;
        }
      }
    }
  }

  nav:nth-child(2)  {
    @include make-row();
    margin-right: 0;
    margin-left: 0;
    padding: 0;

    @include media-breakpoint-down(xs) {
      width: calc(100% + 1rem) !important;
    }

    .logo {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;

      @include media-breakpoint-down(sm) {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
    }

    .nav-primary-container {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;

      @include media-breakpoint-down(sm) {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
        flex: 0 0 auto;
        width: auto;
        max-width: none;


        .button {
          position: relative;
          height: 1.5rem;
          width: 1.5rem;

          i {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            font-size: 1.7rem;
            line-break: .8;
          }

          .icon-default {
            transition: opacity .3s, transform .3s;
          }

          .icon-hover {
            transition: opacity .3s, transform .3s;
            transform: rotate(-180deg) scale(.5);
            opacity: 0;
            position: relative;
          }

          &.open {
            transform: scale(1.1);
            .icon-hover {
              transform: rotate(0deg) scale(1);
              opacity: 1;
            }
            .icon-default {
              transform: rotate(180deg) scale(.5);
              opacity: 0;
            }
          }
        }

        .navbar-collapse {
          background: #fff;
          position: absolute;
          width: 100%;
          z-index: 9999;
          width: calc(100% + 4rem) !important;
          margin: 1.5rem -2rem 0 -2rem;

          @include media-breakpoint-down(xs) {
            width: calc(100% + 2rem) !important;
            margin: 1.5rem -2rem 0 -2rem;
          }

          .nav-item {
            &.show {
              .dropdown-toggle {
                &::after  {
                  content: '\f068';
                }
              }
            }

            .dropdown-toggle {
              &::after  {
                font-family: $font-family-icons-secondary;
                font-weight: 900;
                content: '\f067';
                position: absolute;
                right: 3rem;
                @include media-breakpoint-down(xs) {
                  right: 2rem;
                }
                top: 1.55rem;
                color: $primary-color;
              }
            }
          }

          .nav-link {
            border-bottom: 1px solid $neutral-color-light;
            padding: 1.5rem 0rem 1rem 2rem !important;
          }

          .dropdown-menu {
            position: relative!important;
            top:0 !important;
            box-shadow: none;
            border-radius: 0;
            border-top: none;
            min-width: 100%;



            @include media-breakpoint-down(sm) {
              padding:0;
              background: $accent-background;
            }

            &:after {
              content: '';
              height: 0;
            }
          }
        }
      }
    }

    .nav-secondary-container {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;

      ul.nav-secondary {
        li.nav-item {
          @include media-breakpoint-down(md) {
            padding: .5rem 1rem;
          }

          &:last-child {
            padding-right: 0;
            a:not(.btn) {
              padding-right: 0;
              @include media-breakpoint-down(xs) {
                padding-right: 1rem;
              }
            }
          }

          a.nav-link {
            color:$primary-color !important;
            font-size: 1.1rem !important;

            &:last-child {
              padding-right: 0;
            }

            &:hover {
              color: darken($primary-color, 10%);
            }

            i {
              font-size: 1.1rem;
              line-height: .9rem;
              margin-right: .3rem;
            }
          }
          @media screen and (min-width:1024px) and (max-width:1205px) {
            a.nav-link{
              font-size: 0.9rem !important;
            }
          }
          ul#account-nav {
            background-color: $accent-background;
            border-top: none;
            left: 1.1rem !important;
            z-index: 9999;
            margin-top: 1px;

            li {
              background-color: $accent-background;
              a {
                padding: .5rem .8rem;
                border-bottom: 0;
              }
            }

            &:after {
              color:$accent-background;
              left: auto;
              right: 1.5rem;
          }
        }
      }
    }
  }

  .navbar {
    padding: 0;
  }

  ul.nav-primary, ul.nav-secondary {

    li.nav-item {

      a.btn {
        // margin: 1.6rem 0.789rem;
      }

      a.nav-link {
        font-size: 1.1rem;
        color: $default-font-color;
        font-weight: 500;
        padding: 1.5rem 0.789rem 1rem 0.789rem;
        position: relative;

        &.dropdown-toggle::after {
          border: none;
        }

        &:hover {
          color: darken($neutral-color-medium, 30%);
          cursor: pointer;
          @include media-breakpoint-up(md) {
            border-bottom: 3px solid $tertiary-color;
            margin-bottom: -3px;
          }
          }
        }

        &.active {
          color: darken($primary-color, 30%);

          &::before {
            content: '';
            background: $brand-color;
            color: $primary-color;
            height: .27rem;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            @include media-breakpoint-down(md) {
              height: 1.88rem;
              width: .277rem;
            }
          }
        }

        &[aria-expanded="true"] {
          color: darken($primary-color, 30%);

          &::before {
            content: '';
            background: $neutral-color-medium;
            @include media-breakpoint-down(md) {
              height: 0px;
            }
          }
        }
      }

      .dropdown-menu {
          border: none;
          border-radius: 0 0 5px 5px;
          margin: 0;
          font-size: .8rem;
          min-width: 8.94rem;
          -moz-box-shadow: 2px 2px 3px #666, -2px 2px 3px #666;
          -webkit-box-shadow: 2px 2px 3px #666, -2px 2px 3px #666;
          box-shadow: 2px 2px 3px #666, -2px 2px 3px #666;
          border-top: 3px solid $primary-color;
          top: 100%!important;
          left: 50%!important;
          position: absolute!important;
          transform: translate(-50%, 0)!important;

          right: auto;
          text-align: left;

          position: absolute;
          transform-style: preserve-3d;

          &:after {
            font-family: $font-family-icons;
            font-weight: 900;
            content: '\e5c7';
            position: absolute;
            top:-1.7rem;
            font-size: 2rem;
            left: calc(50% - 1rem);
            transform: translateX(calc(-50% - 1rem));
            color: $primary-color;
            transform: translateZ(-1px);
          }

          a {
            padding: .5rem .8rem;
            font-size: 1rem;

            @include media-breakpoint-down(sm) {
              padding: 1rem 1rem 1rem 3rem;

              border-bottom: 1px solid $neutral-color-light;
            }
            color: $default-font-color;

            &:hover {
              background: transparent;
              text-decoration: underline;
            }
          }
      }
    }
  }
}



header nav.off + nav {
  position: fixed !important;
  width: 100%;
  top:0;
  background: #fff !important;
  border-bottom: 1px solid $neutral-color-light;
  margin: 0 -3rem;
  z-index: 999999;
  padding: 0rem 3rem;
  @include media-breakpoint-down(sm) {
    margin: 0 -2rem;
    padding: 0rem 2rem;
  }

  ul.nav-primary li.nav-item a.nav-link {
    padding: 0.5rem 0.789rem .5rem 0.789rem !important;
  }

  .nav-secondary-container ul.nav-secondary li.nav-item a.nav-link {
    padding:  0.5rem 0rem .5rem 0.789rem !important;
  }
}

// bug fixes
@-moz-document url-prefix() {
  .navbar-brand > img {
      width: 10rem !important;
      @include media-breakpoint-down(sm) {
        width: 12rem !important;
      }
  }
}

body#aon {
  @include media-breakpoint-down(sm) {
    header nav:nth-child(2) .nav-primary-container .navbar-collapse {
       margin: .5rem -2rem 0 -2rem;
    }
  }
}

@media screen and (min-width:1024px) and (max-width:1205px) {
  .navbar-nav{
    .nav-link.dropdown-toggle {
      font-size: 0.9rem !important;
    }
  }
  .notify-popover {
    margin-left: -4rem;
    margin-right: -2rem;
    i{
      font-size: 1.5em !important;
    }
    .notification_count{
      font-size: 0.4625rem !important;
    }
  }
}