.progress {
  border-radius: 0;
}

.progress-bar.bg-default {
  background-color: $secondary-color;
}

.progress-bar.progress-bar-animated {
  position: relative;
  animation: progress-indeterminate 2s linear infinite;
}

@keyframes progress-indeterminate {
   from { left: -25%; width: 75%; }
   to { left: 100%; width: 75%;}
}
