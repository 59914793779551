.page-title-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-title {
    font-size: 2.7rem;
    color: #373738;
    font-family: 'Lora', serif;
    letter-spacing: 0;
    padding-bottom: 0.6rem;

}

.page-title-image-padding {
    padding-bottom: 1.875rem;
    width: 13%;
}

.referral-passage {
    font-family: "Roboto", sans-serif !important;
    height: 3.5rem;
    color: #373738;
    font-size: 1.1875rem;
    font-weight: unset;
    letter-spacing: 0;
    line-height: 1.75rem;
    margin-top: -0.625rem;
}

.referral-passage-line {
    font-family: "Roboto", sans-serif !important;
    height: 1.325rem;
    width: 25.313rem;
    color: #292931;
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.325rem;
}

.referral-select {
    height: 1.1875rem;
    color: #313234;
    font-family: "Roboto", sans-serif !important;
    font-size: 0.875rem !important;
    letter-spacing: 0;
    line-height: 1.1875rem;
}

.referral-hyper {
    color: #585656;
}

.referral-noresult {
    padding-top: 1.875rem;
}