.list-group-item {
  padding: 1rem;
}

.list-group-item.active h2,
.list-group-item.active h3,
.list-group-item.active h4,
.list-group-item.active .h2,
.list-group-item.active .h3,
.list-group-item.active .h4,
.list-group-item.active>* {
  color: #fff !important;
}

.filter-label {
  height: 1.5rem;
  color: #313234;
  font-family: "Roboto", sans-serif !important;
  font-size: 1.125rem;
  letter-spacing: 0;
}

.filter-label-sm {
  height: 1.75rem;
  color: #313234;
  font-family: $font-family-sans-serif;
  font-size: 1rem;
  letter-spacing: 0;
}

.filter-scroll {
  overflow-y: scroll;
  height: 170px;
}

.filter-scroll-referral {
  overflow-y: scroll;
  height: 11.125rem;
  border: 0.0625rem solid #000;
}

.list-group-item-ms {
  position: relative;
  display: block;
  //padding: $list-group-item-padding-y $list-group-item-padding-x;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  color: $list-group-color;
  background-color: $list-group-bg;

  &:first-child {
    @include border-top-radius($list-group-border-radius);
  }

  &:last-child {
    @include border-bottom-radius($list-group-border-radius);
  }

  &.disabled,
  &:disabled {
    color: $list-group-disabled-color;
    pointer-events: none;
    background-color: $list-group-disabled-bg;
  }

  // Include both here for `<a>`s and `<button>`s
  &.active {
    z-index: 2; // Place active items above their siblings for proper border styling
    color: $list-group-active-color;
    background-color: $list-group-active-bg;
    border-color: $list-group-active-border-color;
  }

  &+& {
    border-top-width: 0;

    &.active {
      margin-top: -$list-group-border-width;
      border-top-width: $list-group-border-width;
    }
  }
}

.referral-pad {
  padding: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
}