.vision-modal-class{
  width: 52%;
  flex-shrink: 0;
  border-radius: 1.25rem;
  border: 0.625rem solid #117BAB;
  background: #FFF;
}

.popup-modal-header{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: none;
  border-radius: 0.625rem;
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
  button{
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    border: none;
    background: none;
    font-weight: bold;
    width: 1.498rem;
    color: #63666A;;
  }
  
}

.popup-modal-title{
  width: 8.5rem;
  height: 2.375rem;
  flex-shrink: 0;
}

.demo-table{
  width: 100%;
  flex-shrink: 0;
  border: 0.063rem solid #999;
  background: #FAFAFA;
  table{
    border-collapse: collapse;
    th{
      padding: 0.5rem;
      text-align: left;
    }
    td{
      padding: 0.5rem;
      text-align: left;
    }
  }
}

.arc-description{
  flex-shrink: 0;
  border: 2px solid #B00020;
  background: rgba(255, 229, 3, 0.10);
  padding-left: 0.625rem;

}

.modal-labels{
  color: #292931;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.688rem; /* 150% */

  h2{
    color: #313234;
    font-family: Lora;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.063rem; /* 137.5% */
  }

  label{
    font-weight: 600;
  }
}

.howIKnow-modal-content{
  margin: 1rem 0;
  width: 43.75rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  border: 0.625rem solid #117BAB;
  background: #FFF;
}

.howIKnow-modal-dialog{
  display: flex;
  justify-content: center;
}

@include media-breakpoint-down(xs){
  .howIKnow-modal-content{
    width: 92%;
  }

  .demo-table{
    overflow-x: auto;
    max-width: 100%;
    white-space: nowrap;

    table{
      width: 100%;
    }
  }
}