.sharing-privacy{
    h1{
        @include media-breakpoint-up(sm) {
            margin-left:25%;
        }
    }
    .sidebar {
        @include media-breakpoint-up(sm) {
            margin-top:-5.953rem;
        }
    }
}
