
.hero-background-img {
  @include make-row();
  background-repeat:no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 2rem;
  @include media-breakpoint-down(sm) {
    display: block !important;
  }

  &[style] {
    @include media-breakpoint-down(sm) {
      background-image: none !important;
    }
  }

  .hero-text-right {
    @include make-col-ready();

    @include media-breakpoint-down(sm) {
      @include make-col(12);
    }
    @include media-breakpoint-up(md) {
      @include make-col(6);
      margin-left: 50%;
    }
  }

  .hero-text-left {
    @include make-col-ready();
    @include make-col(6);
    @include media-breakpoint-down(sm) {
      @include make-col(12);
      padding: 0;
    }
  }

  .hero-background-img-text {
    padding: 2.5rem;
    background-color: #fff;
    box-shadow: $default-drop-shadow;
    border-radius: 20px;

    @include media-breakpoint-down(sm) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
