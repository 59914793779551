.modal{
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show{
  display: block;
}

.alert-dialog{
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 6px;
  background: #4A4A4A;
}

.modal-title-alert{
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Segoe UI;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.modal-body-alert{
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Segoe UI;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 1rem;
}