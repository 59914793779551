.well {
  text-align: left;
  background: $accent-background;
  border: 1px solid $neutral-color-medium;
  display: block;
  position: relative;

  .well-body {
    padding: 1.5rem;
  }

  .well-header {
    padding: 1.5rem;
    border-bottom: 1px solid $neutral-color-light;
  }

  .well-footer {
    padding: 1.5rem;
    border-top: 1px solid $neutral-color-medium;
  }

  .well-info {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }

  .bottom { // for aligning an element to the bottom of a well, must me last element
    position: absolute;
    bottom: 1.5rem;
    text-align: center;
    left: 0;
    right: 0;
    margin-bottom: 0;
  }
}
