.alert {
  padding: 1rem;
  border-radius: 0px;
  font-size: 1rem;
  color: $default-font-color;
  border: none;
  font-weight: 300;

  i {
    font-size: 1.5rem default;
    vertical-align: middle;
  }


  p:last-child {
    margin-bottom: 0;
  }
}

.alert-danger {
  // background: lighten($color: $red, $amount: 44%);
  background-color: $bg-danger;

  i {
    color: #A01C2A;
  }
}

.alert-info {
  // background: lighten($color: $blue, $amount: 45%);
  background-color: $bg-info;

  i {
    color: $blue;
  }
}

.alert-success {
  background-color: $bg-success;
  box-shadow: 0;
  border: none;

  i {
    color: $green;
  }
}

.alert-warning {
  // background: lighten($color: $yellow, $amount: 43%);
  background-color: $bg-warning;
  i {
    color: darken($color: $yellow, $amount: 18%);
  }
}

pbc-header {
  .alert {
    margin-bottom: 0px;
  }
}
