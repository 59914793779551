.select-charges-type{
  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: none;
    padding-right: 1rem;
  }

  input{
    width: 24%;
    height: 50px;
  }

  select::after{
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.suggestions-list{
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 100px;
  overflow: hidden;
  background-color: #fff;
  position: absolute;
  border: 1px solid var(--neutral-gray-8-icons, #1F1F1F);
  z-index: 1;
}

.suggestion-item{
  padding: 5px;
  cursor: pointer;
  width: 374px;
  height: 50px;
  flex-shrink: 0;
  background: #FFF;
  display: flex;
  align-items: center;
}

.service-cost-form{

  a{
    margin-right: 1rem;
    text-decoration: none;
    color: #117BAB;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .service-labels{
    width: 15.2rem;
    height: 50px;
    flex-shrink: 0;
    border-radius: 25px;
    background: #007BBA;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 1rem 0.5rem 0;
    flex-wrap: wrap;


    label{
      color: #FFF;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      margin-top: 8px;
      text-wrap: nowrap;
    }
  }
}

.service-labels-wrapper{
  display: flex;
  flex-wrap: wrap;
  width: 50%;

  button{
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    background: none;
    border: none;
    margin-left: 1rem;
  }
}

.quantity-input{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  background: #FFF;
  background: var(--neutral-white, #FFF);
  border-radius: 4px;
  border: 2px solid var(--neutral-gray-8-icons, #1F1F1F);
  background: var(--neutral-white, #FFF);
  width: 6rem;
  height: 50px;
  gap: 6px;
  margin: 1rem 1rem 0 0;

  select{
    border: none;
    width: 100%;
    margin-left: 0.4rem;
    margin-top: 1.2rem;
    outline: none;
    padding: 0;

    &:focus {
      outline: 2px solid #020a11;
    }
  }
  

  @media screen and (min-width:2100px) {
    height: 72px;
  }
}

.quantity-input-2{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  background: #FFF;
  background: var(--neutral-white, #FFF);
  border-radius: 4px;
  border: 2px solid var(--neutral-gray-8-icons, #1F1F1F);
  background: var(--neutral-white, #FFF);
  width: 8%;
  height: 63px;
  gap: 8px;
  margin: 1rem 1rem 0 0;
  select{
    border: none;
    width: 100%;
    margin-left: 8px;
    margin-top: 15px;
    margin-right: 7px;
    outline: none;
    padding-left: 10px;
  }
}
.total-input {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 6px;
  width: 375px;
  height: 63px;
  padding: 8px 13px;
  justify-content: center;
  align-self: stretch;
  border: 2px solid;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  color: var(--neutral-gray-8-icons, #1F1F1F);
  font-feature-settings: 'clig' off, 'liga' off;
}

.cost-input{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  background: #FFF;
  background: var(--neutral-white, #FFF);
  border-radius: 4px;
  border: 2px solid var(--neutral-gray-8-icons, #1F1F1F);
  background: var(--neutral-white, #FFF);
  width: 22rem;
  height: 50px;
  gap: 6px;
  margin: 1rem 1rem 0 0;

  input{
    border: none;
    width: 100%;
    margin-top: 1.2rem;
    margin-left: 0.5rem;
    outline: none;
    background: none;
  }

  @media screen and (min-width: 2100px) {
    height: 72px;
  }

  @media screen and (min-width: 1440px) and (max-width: 2099px) {
    margin-bottom: 0.5rem;
  }
}

.cost-input-2{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  background: #FFF;
  background: var(--neutral-white, #FFF);
  border-radius: 4px;
  border: 2px solid var(--neutral-gray-8-icons, #1F1F1F);
  background: var(--neutral-white, #FFF);
  width: 375px;
  height: 63px;
  gap: 8px;
  margin: 1rem 1rem 0 0;
  
  input{
    border: none;
    width: 100%;
    margin-top: -1px;
    outline: none;
    background: none;
    padding-top: 18px;
    padding-left: 10px;
  }
}