.chip-list {
  display: inline-block;
}

.chip {
    display: inline-block;
    height: 1.8rem;
    font-size: .75rem;
    font-weight: 500;
    color: $default-font-color;
    line-height: 1.7rem;
    padding: 0 1rem;
    border-radius: 1rem;
    border: 1px solid $neutral-color-light;
    margin-bottom: 1rem;
    margin-right: 1rem;
    background: #fff;

  &.active {
    background: lighten($color: $primary-color, $amount: 55%);
    border: 1px solid lighten($color: $primary-color, $amount: 30%);
  }

  &.active:hover {
    background: lighten($color: $primary-color, $amount: 55%);
    text-decoration: none;
  }

  &:visited {
    color: $default-font-color;
  }

  &:hover{
    background: $neutral-color-medium;
    text-decoration: none;
    cursor: pointer;
  }

  i {
    line-height: 1rem;
    font-size: 1rem;

    &.cancel {
      color: lighten($color: $secondary-color, $amount: 10%);
      font-size: .8rem;
    }
  }

  .close {
    cursor: pointer;
    float: right;
    line-height: 32px;
    padding-left: 8px;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
    color: $secondary-color;
  }
}


