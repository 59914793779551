@keyframes progress-bar-stripes {
  from { background-position: $progress-height 0; }
  to { background-position: 0 0; }
}

.progress {
  display: flex;
  height: $progress-height;
  overflow: hidden; // force rounded corners by cropping it
  font-size: $progress-font-size;
  background-color: $progress-bg;
  @include border-radius($progress-border-radius);
  @include box-shadow($progress-box-shadow);
}


.progress-bar-striped {
  @include gradient-striped();
  background-size: $progress-height $progress-height;
}

.progress-bar-animated {
  animation: progress-bar-stripes $progress-bar-animation-timing;
}

.progress-container {
  position: relative;

  .progress-indicator {
    position: absolute;
    text-align: right;
    min-width: 2.5rem;

    .body-small, .body-smaller {
      line-height: 1;
    }
  } 

  .progress {
    border-radius: 12px;
    border: 1px solid $neutral-color-medium;
    margin: 1rem 0rem 2rem 0;
  
    .progress-bar {
      //@include background-gradient($accent-color, $brand-color, left);
      position: relative;
      background-color: #117BAB;
  
      &:after {
        content: ' ';
        position: absolute;
        background: red;
      }
    }
  
    .rx-prog {
      background-color: rgba(17, 123, 171, 0.75);
    }
  }
}


#ngb-tooltip-0{
  padding-left:2px;
}


#ngb-tooltip-1{
  padding-left:2px;
}

#ngb-tooltip-2{
  padding-left:2px;
}


#ngb-tooltip-3{
  padding-left:2px;
} 

.tooltip-inner{
  margin-left:0.4rem;
  color:#1F1F1F;
  border-radius: 4px;
  background-color: #d5d1d1;
}

.tooltip-inner .arrow::before {
  border-top-color: black;
}

