.dropdown.show .dropdown-menu {
  opacity: 1;
}

.dropdown-item.active,
.dropdown-item:active {
  color: $default-font-color;
  text-decoration: underline;
  background-color: transparent !important;

  &[id*='ngb-typeahead'] {
    background-color: $accent-background  !important;
  }
}

.referal-option-in {
  box-sizing: border-box;
  border: 0.06rem solid #63666A;
  background-color: #EFEFEC;
  height: 1.06rem;
  width: 7.188rem;
  color: #000000;
  font-family: Helvetica;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.06rem;
}