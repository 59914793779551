.hero {
  @include make-row();
  margin-left: 0;
  margin-right: 0;
  @include media-breakpoint-down(sm) {
    text-align: center;

    ul {
      text-align: center;
      display: inline-block;
    }

    li, li a {
      text-align: left;
    }
  }

  .hero-icon {
    padding-bottom: 1rem;
  }

  .hero-img {
    @include make-col-ready();
    padding: 0 !important;

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    img {
      width: 100% !important;
    }
    &.hero-video {
      @include media-breakpoint-up(sm) {
        padding-right: 3rem !important;
      }
    }
  }

  .hero-text {
    @include make-col-ready();
    padding: 3rem;

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    @include media-breakpoint-down(sm) {
      h1:after, .h1:after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  h1, .h1 {
    margin-top: 0;
  }

  + h2, + h3 {
    margin-top: 2.5rem;
  }

  .btn-primary {
    margin-top: 3rem;
    @include media-breakpoint-down(sm) {
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }
  .btn-secondary {
    margin-top: 3rem;
  }
}

.hero-horizontal-img {
  margin-bottom: 2rem;

  .h1, h1 {
    font-size: 3.5rem;
    margin-bottom: 2rem;
  }

  .h4, h4 {
    font-weight: 500;
  }

  img {
    margin-bottom: 3rem;
  }

  * {
    text-align: center;
  }

  .btn {
    margin-top: 3rem;
  }
}

.hero-background-img {
  @include make-row();
  background-repeat:no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 3rem;
  @include media-breakpoint-down(sm) {
    display: block !important;
  }

  &[style] {
    @include media-breakpoint-down(sm) {
      background-image: none !important;
    }
  }

  .hero-text-right {
    @include make-col-ready();

    @include media-breakpoint-down(sm) {
      @include make-col(12);
    }
    @include media-breakpoint-up(md) {
      @include make-col(6);
      margin-left: 50%;
    }
  }

  .hero-text-left {
    @include make-col-ready();
    @include make-col(6);
    @include media-breakpoint-down(sm) {
      @include make-col(12);
      padding: 0;
    }
  }

  .hero-background-img-text {
    padding: 2.5rem;
    background-color: #fff;
    box-shadow: $default-drop-shadow;

    &.lrg {
      margin-bottom: 4rem;
    }
  }
}

.hero-hmo-cta{
  .btn-primary {
    margin-top: 0!important;
    border-radius: 5px;
    background-color: #82C341;
    border-color: #82C341;
    color: #000000!important;
    text-align: center;
    font-weight: 500;

    text &:hover {
      box-shadow: 0px 2px 4px 0 #000000;
      background-color: $primary1-color;
    }
  
    &:hover {
      box-shadow: 0px 2px 4px 0 #000000;
      background-color: $primary1-color;
    }
  
    &:focus {
      outline: 2px solid $primary1-color;
      outline-offset: 2px;
      box-shadow: none;
    }
  
    &:active,
    &:visited {
      box-shadow: none;
      background-color: $primary1-color !important;
      color: #fff;
    }
  
    &.alternate {
      background: #fff;
      color: $primary1-color !important;
  
      &:hover {
        color: $primary1-color;
        border-color: #fff;
      }
  
      &:active,
      &:visited {
        box-shadow: none;
        background-color: $primary1-color;
        color: #fff !important;
      }
    }
  
    &:disabled,
    &.disabled {
      background: $disabled-bg;
      border-color: $disabled-bg;
      box-shadow: none;
  
      &:hover {
        background: $disabled-bg;
        border-color: $disabled-bg;
        box-shadow: none;
  
      }
    }
  }  
  .btn-primary:active, .btn-primary:visited {
    background-color: #82C341!important;
  }
}

.hero-hmo, .hero-hmo-btn {
  .btn-tertiary{
    &:hover {
      text-decoration: none;
      &:after {
        color: $primary-color;
      }
    }
    &:focus {
      outline: 2px solid #007BBA;
      outline-offset: 2px;
    }
    &::after {
      color: #82C341;
    }
  }
}

.pcp-backto-result{
  a{
    text-decoration: none;
      &:hover{
      text-decoration: none;
      .fa-chevron-left{
          &::before {
            color: $primary-color;
          }
        }
      }
      &:focus {
        outline: 2px solid #007BBA;
        outline-offset: 2px;
      }
    }
 }


.hero-hmo{
  p {
    color: #313234;
    font-family: Lora;
    font-size: 25px;
    letter-spacing: 0;
    line-height: 32px;
  }
  img{
    width: auto!important;
  }
}

.hmo-banner{
  background-color: #004053;

  h2{
    color: #FFFFFF;
  }
  p{
    color: #FFFFFF;
    text-align: center;
  }
  .btn-tertiary{
    color: #FFFFFF!important;
    &:hover {
      text-decoration: none;
      &:after {
        color: #FFFFFF;
      }
    }
    &:focus {
      outline: 2px solid #82C341;
      outline-offset: 2px;
    }
    &::after {
      color: #82C341;
    }
  }
}

.kinwellText{
  margin-top: 0;
  @include media-breakpoint-down(sm) {
    margin-top: 1.5rem;
  }
}

.overlay-image-bottom {
  position: absolute;
  bottom: -10.5rem;
  left: -13rem;

  svg {
    width: 20rem;
    height: 20rem;
    transform: scale(-1,-1)
  }
}

.overlay-image-top {
  position: absolute;
  top: -7.5rem;
  right: -12rem;

  svg {
    width: 20rem;
    height: 20rem;
  }
}