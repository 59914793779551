.sidebar-page {
    .sidebar-page-title{
        @include media-breakpoint-up(md) {
            margin-left:25%;
            padding-left:10px;
        }
    }
    .sidebar {
        @include media-breakpoint-up(md) {
        }
        @include media-breakpoint-down(sm) {
          margin-top: 0;
        }

        margin-top: 1px;
        padding:0;
        a, .sidebar-section-title {
            color:#000;
            padding:.8rem .8rem .8rem 0;
            &.active {
                border-bottom: 1px solid $neutral-color-medium;
                white-space: pre-wrap;
                text-decoration: none;
                margin-top:-1px;
                font-family: $font-family-serif !important;
                margin-left:0;
                font-size: 1.4rem;
                padding-left: 1rem;
                & + a {
                    border-top:0;
                }
            }
        }
        a {
            font-size: 1rem;
            font-weight: 500;
            &:not(.sidebar-section-title ){
                background: $accent-background;
                padding-left: 1rem;
                border-bottom: 1px solid $neutral-color-medium;
                border-left: none;
                &.active {
                    margin-left:0;
                    padding-left: 1rem;
                    margin-top: 0;
                    border-left: 5px solid $tertiary-color;
                    font-family: $font-family-sans-serif !important;
                    font-size: 1rem;
                    background: #fff !important;
                }
            }
            &.dropdown-item {
                width:auto;
                overflow: hidden;
                text-overflow: ellipsis;
                // white-space: normal;
                &:hover {
                    background-color:$neutral-color-lightest;
                }
            }
        }

        .sidebar-section-title {
            font-size:1.15rem;
            border-top:none;
            background: #fff !important;
            &+a {
                border-top:none;
            }
            &:not(:first-child){
                padding:2rem 1.6rem 1rem 0;
            }
            padding:1rem 1rem 1rem 0;
            margin-left:1rem;
        }
        button {
            @include media-breakpoint-up(md) {
                display:none;
            }
            &.dropdown-toggle, &:hover {
                border:0;
                border-radius: 0;
                background-color: $neutral-color-light;
                width:100%;
                margin:0;
                padding: 1.6rem 1rem;
                text-align:left;
                color:#000;
                font-size:0.88rem;
                text-transform: none;
                &:after{
                    font-family: "Material Icons";
                    content: '\e313';
                    font-size: 2rem;
                    position: absolute;
                    right: 2rem;
                    top: 1rem;
                    border:none;
                    @include media-breakpoint-up(md) {
                        content: none;
                    }
                }
            }
        }
        &.show {
            > .btn {
                &.dropdown-toggle, &:hover {
                    color:$neutral-color-darkest;
                    &:after{
                        content: '\e316';
                    }
                }
            }
        }
        &.dropdown{
            .dropdown-menu{
                width:100%;
                border:0;
                border-radius: 0;
                background-color: transparent !important;
                margin:0;
                padding: 0;
                z-index:10000;
                @include media-breakpoint-up(md) {
                    display:block;
                    position:static;
                    margin-right:1.5rem;
                }
                @include media-breakpoint-up(xs) {
                  ul {
                    border-radius: 8px;
                    border: 1px solid $neutral-color-medium;
                    overflow: hidden;
                  }
                }
                @include media-breakpoint-down(sm) {
                  ul {
                    border-radius: 0;
                    border: 1px solid $neutral-color-medium;
                    overflow: hidden;
                    padding-bottom: 0 !important;
                  }
                }
                ul {
                  background: #fff;
                  padding-bottom: 3rem;
                }
            }
        }
    }

    // Override for 2 col template to prevent margin bleeds
    .hero-background-img {
      margin: 0 !important;
    }

    // Border on left column (part of template)
    // .col-12.col-md-3.background-accent {
    //     border-left: 1px solid $neutral-color-medium;
    //     border-right: 1px solid $neutral-color-medium;
    //     border-bottom: 1px solid $neutral-color-medium;

    //     @include media-breakpoint-down(sm) {
    //         border: none;
    //     }
    // }

    .bleed {
      margin-left: 0rem !important;
      width: 100% !important;

      @include media-breakpoint-down(sm) {
        margin-left: -2rem!important;
        width: calc(100% + 4rem)!important;
      }
      @include media-breakpoint-down(xs) {
        margin-left: -1rem!important;
        width: calc(100% + 2rem)!important;
      }
    }
}
