// -----------------------------------------------------------------------------
// This file contains all styles related to the button
// -----------------------------------------------------------------------------

/***************************************************
        TABLE OF CONTENTS

- button base
- primary
- secondary
- tertiary
- interactive
- link text

******************************************************/

a[role="button"]:hover {
  cursor: pointer;
}

.btn-lg,
.btn-group-lg>.btn {
  border-radius: $button-border-radius;
}


/**
** Base button style
**/
.btn {
  border-radius: $button-border-radius;
  margin-right: 1rem;
  padding: .5rem 1.3rem;
  font-weight: 500;
  font-size: .85rem;
  line-height: 1.3;
  font-family: $font-family-sans-serif;

  &.btn-sm {
    padding: .25rem 1rem;
    font-size: .75rem;
  }

  &.disabled {
    background-color: $disabled-color;
    opacity: 1 !important;
    pointer-events: none;

    &:hover {
      all: unset;
    }
  }

  .material-icons {
    line-height: 1rem;
  }

  &.shadow {
    -webkit-box-shadow: 2px 8px 10px -5px rgba(136, 136, 136, 1);
    -moz-box-shadow: 2px 8px 10px -5px rgba(136, 136, 136, 1);
    box-shadow: 2px 8px 10px -5px rgba(136, 136, 136, 1);
  }
}


/**
** Primary Button for feature flag false
**/
.btn-primary {
  background: $primary-color;
  border-color: $primary-color;
  color: #fff !important;

  &:hover {
    box-shadow: $default-drop-shadow;
    background-color: $primary-color;
  }

  &:focus {
    outline: 2px solid $primary-color;
    outline-offset: 2px;
  }

  &:active,
  &:visited {
    box-shadow: none;
    background-color: $primary-color !important;
    color: #fff;
  }

  &.alternate {
    background: #fff;
    color: $primary-color !important;

    &:hover {
      color: $primary-color;
      border-color: #fff;
    }

    &:active,
    &:visited {
      box-shadow: none;
      background-color: $primary-color;
      color: #fff !important;
    }
  }

  &:disabled,
  &.disabled {
    background: $disabled-color;
    border-color: $disabled-color;
    box-shadow: none;

    &:hover {
      background: $disabled-color;
      border-color: $disabled-color;
      box-shadow: none;

    }
  }
}

.btn-primary4 {
  background: $primary4-color;
  border-color: $primary4-color;
  color: $primary3-color !important;

  &:hover {
    box-shadow: $default-drop-shadow;
    background-color: $primary4-color;
  }

  &:focus {
    outline: 2px solid $primary4-color;
    outline-offset: 2px;
  }

  &:active,
  &:visited {
    box-shadow: none;
    background-color: $primary4-color !important;
    color: $primary3-color;
  }

  &.alternate {
    background: $primary3-color;
    color: $primary4-color !important;

    &:hover {
      color: $primary4-color;
      border-color: $primary3-color;
    }

    &:active,
    &:visited {
      box-shadow: none;
      background-color: $primary4-color;
      color: $primary3-color !important;
    }
  }

  &:disabled,
  &.disabled {
    background: $disabled-color;
    border-color: $disabled-color;
    box-shadow: none;

    &:hover {
      background: $disabled-color;
      border-color: $disabled-color;
      box-shadow: none;

    }
  }
}

/*
Primary button style when feature flag is true */
.button-font-weight {
  color: #000 !important;
  font-weight: bolder;
  background-color: $bg-hover;
  background: $bg-hover;
  box-shadow: none;
  border-color: $bg-hover;
  word-wrap: white- nowrap;
  padding-left: 0px;
  padding-right: 0px;
}

.button-width {
  width: 100%;
  color: #266726 !important;
  font-weight: normal;

  &:active,
  &:hover {
    color: #fff !important;
  }
}


.btn-primary-flag {
  background: $bg-hover;
  border-color: $bg-hover;
  color: #000 !important;

  &:hover,
  &:not(:hover) {
    color: darken($bg-hover, 3%);
    border-color: darken($bg-hover, 5%);
    background: $bg-hover;
    box-shadow: $default-drop-shadow;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  }

  &.pressed {
    box-shadow: none;
    color: #000;
    border-color: $bg-hover !important;

  }

  &:focus {
    outline: 2px solid $bg-hover;
    outline-offset: 2px;
    border-color: $bg-hover;
  }

  &:active,
  &:visited {
    box-shadow: none;
    background-color: $bg-hover !important;
    color: #fff;
  }

  &.alternate {
    background: #fff;
    color: $bg-hover !important;

    &:hover {
      color: $bg-hover;
      border-color: #fff;
    }

    &:active,
    &:visited {
      box-shadow: none;
      background-color: $bg-hover;
      color: #fff !important;
    }
  }

  &:disabled,
  &.disabled {
    background: $disabled-color;
    border-color: $disabled-color;
    box-shadow: none;

    &:hover {
      background: $disabled-color;
      border-color: $disabled-color;
      box-shadow: none;

    }
  }
}

.btn-primary1 {
  background:  $primary1-color;
  border-color:  $primary1-color;
  color: #1F1F1F   !important;
  font-weight: 500!important;

  text &:hover {
    box-shadow: 0px 2px 4px 0 #000000;
    background-color: $primary1-color;
  }

  &:hover {
    box-shadow: 0px 2px 4px 0 #000000;
    background-color: $primary1-color;
  }

  &:focus {
    outline: 2px solid $primary1-color;
    outline-offset: 2px;
    box-shadow: none;
    background-color: $primary1-color;
  }

  &:active,
  &:visited {
    box-shadow: none;
    background-color: $primary1-color !important;
    color: #fff;
  }

  &.alternate {
    background: #fff;
    color: $primary1-color !important;

    &:hover {
      color: $primary1-color;
      border-color: #fff;
    }

    &:active,
    &:visited {
      box-shadow: none;
      background-color: $primary1-color;
      color: #fff !important;
    }
  }

  &:disabled,
  &.disabled {
    background: $disabled-bg;
    border-color: $disabled-bg;
    box-shadow: none;

    &:hover {
      background: $disabled-bg;
      border-color: $disabled-bg;
      box-shadow: none;

    }
  }
}

/**
** Primary Button for dark background
**/

.btn-primary1-dark-bg {
  background:  $primary1-color;
  border-color:  $primary1-color;
  color: #000 !important;
  text-decoration: none !important;

  text &:hover {
    box-shadow: 0px 2px 4px 0 #ffffff;
    background-color: $primary1-color;
  }

  &:hover {
    box-shadow: 0px 2px 4px 0 #ffffff;
    background-color: $primary1-color;
  }

  &:focus {
    outline: 2px solid $primary1-color!important;
    outline-offset: 2px;
    box-shadow: none;
  }

  &:active,
  &:visited {
    box-shadow: none;
    background-color: $primary1-color !important;
    color: #fff;
  }

  &.alternate {
    background: #fff;
    color: $primary1-color !important;

    &:hover {
      color: $primary1-color;
      border-color: #fff;
    }

    &:active,
    &:visited {
      box-shadow: none;
      background-color: $primary1-color;
      color: #fff !important;
    }
  }

  &:disabled,
  &.disabled {
    background: $disabled-bg;
    border-color: $disabled-bg;
    box-shadow: none;

    &:hover {
      background: $disabled-bg;
      border-color: $disabled-bg;
      box-shadow: none;

    }
  }
}

.btn-secondary1 {
  background: transparent;
  border    : 0.125rem solid $secondary1-color;
  color     : #266726!important;
  font-weight: 500!important;

  &.btn-sm {
    padding  : .25rem 1rem;
    font-size: .75rem;
  }
  
  &:focus {
    outline: 2px solid $secondary1-color;
    outline-offset: 2px;
    box-shadow: none;
  }

  &:hover {
    color       : darken($secondary1-color, 3%);
    border-color: $secondary1-color;
    background  : transparent;
    box-shadow  : $default-drop-shadow;
    // box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 4px 0 #000000;
  }

  &.pressed {
    box-shadow  : none;
    color       : #000;
    border-color: #000;
  }

    &:active,
    &:visited {
      box-shadow: none;
    }

  &:disabled,
  &.disabled {
    background  : $disabled-bg;
    border-color: $disabled-bg;
    box-shadow  : none;
    &:hover{
      background  : $disabled-bg;
      border-color: $disabled-bg;
      box-shadow  : none;
      color       : #fff;
    }
  }
  &.alternate {
    background  : transparent !important;
    border-color: #ffffff;
    color       : #ffffff;

    &:hover {
      background: $neutral-color-light !important;
      color     : darken($secondary1-color, 3%) !important;
    }
  }
}

/**
** Secondary Button for dark background
**/

.btn-secondary1-dark-bg {
  background: transparent;
  border    : 0.125rem solid $secondary1-color;
  color     : #000!important;

  &.btn-sm {
    padding  : .25rem 1rem;
    font-size: .75rem;
  }
  
  &:focus {
    outline: 2px solid $secondary1-color;
    outline-offset: 2px;
    box-shadow: none;
  }

  &:hover {
    color       : darken($secondary1-color, 3%);
    border-color: $secondary1-color;
    background  : transparent;
    box-shadow  : $default-drop-shadow;
    box-shadow: 0px 2px 4px 0 #ffffff;
  }

  &.pressed {
    box-shadow  : none;
    color       : #000;
    border-color: #000;
  }

    &:active,
    &:visited {
      box-shadow: none;
    }

  &:disabled,
  &.disabled {
    background  : $disabled-bg;
    border-color: $disabled-bg;
    box-shadow  : none;
    &:hover{
      background  : $disabled-bg;
      border-color: $disabled-bg;
      box-shadow  : none;
      color       : #fff;
    }
  }
  &.alternate {
    background  : transparent !important;
    border-color: #ffffff;
    color       : #ffffff;

    &:hover {
      background: $neutral-color-light !important;
      color     : darken($secondary1-color, 3%) !important;
    }
  }
}

/**
** Secondary Button
**/
.btn-secondary {
  background: transparent;
  border: .1rem solid $primary-color;
  color: darken($primary-color, 10%);

  &.btn-sm {
    padding: .25rem 1rem;
    font-size: .75rem;
  }

  &:focus {
    outline: 2px solid $primary-color;
    outline-offset: 2px;
  }

  &:hover {
    color: darken($primary-color, 3%);
    border-color: darken($primary-color, 5%);
    background: transparent;
    box-shadow: $default-drop-shadow;
  }

  &.pressed {
    box-shadow: none;
    color: #000;
    border-color: #000;
  }

  &:active,
  &:visited {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    background: $disabled-color;
    border-color: $disabled-color;
    box-shadow: none;

    &:hover {
      background: $disabled-color;
      border-color: $disabled-color;
      box-shadow: none;
      color: #fff;
    }
  }

  &.alternate {
    background: transparent !important;
    border-color: #ffffff;
    color: #ffffff;

    &:hover {
      background: $neutral-color-light !important;
      color: darken($primary-color, 3%) !important;
    }
  }
}


/**
** Secondary Button when flag is true
**/
.btn-secondary-flag {
  background: transparent;
  border: 0.13rem solid #008000;
  color: #008000;

  &.btn-sm {
    padding: .25rem 1rem;
    font-size: .75rem;
  }

  &:focus {
    outline: 2px solid $success-primary;
    outline-offset: 2px;
  }

  &:hover {
    color: darken($success-primary, 3%);
    border-color: darken($success-primary, 5%);
    background: transparent;
    box-shadow: $default-drop-shadow;
    // box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  }

  &.pressed {
    box-shadow: none;
    color: #000;
    border-color: #000;
  }

  &:active,
  &:visited {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    background: $disabled-color;
    border-color: $disabled-color;
    box-shadow: none;

    &:hover {
      background: $disabled-color;
      border-color: $disabled-color;
      box-shadow: none;
      color: #fff;
    }
  }

  &.alternate {
    background: transparent !important;
    border-color: #ffffff;
    color: #ffffff;

    &:hover {
      background: $neutral-color-light !important;
      color: darken($bg-hover, 3%) !important;
    }
  }
}

.btn-secondary-light {
  background: transparent;
  border: .1rem solid $accent-tertiary-color;
  color: darken($accent-tertiary-color, 10%);

  &.btn-sm {
    padding: .25rem 1rem;
    font-size: .75rem;
  }

  &:focus {
    outline: 2px solid $accent-tertiary-color;
    outline-offset: 2px;
  }

  &:hover {
    color: darken($accent-tertiary-color, 3%);
    border-color: darken($accent-tertiary-color, 5%);
    background: transparent;
    box-shadow: $default-drop-shadow;
    // box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  }

  &.pressed {
    box-shadow: none;
    color: #000;
    border-color: #000;
  }

  &:active,
  &:visited {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    background: $disabled-color;
    border-color: $disabled-color;
    box-shadow: none;

    &:hover {
      background: $disabled-color;
      border-color: $disabled-color;
      box-shadow: none;
      color: #fff;
    }
  }

  &.alternate {
    background: transparent !important;
    border-color: #ffffff;
    color: #ffffff;

    &:hover {
      background: $neutral-color-light !important;
      color: darken($accent-tertiary-color, 3%) !important;
    }
  }
}

/**
** Tertiary Button
**/
.btn-tertiary {
  background: none;
  padding: 0 1rem 0 0;
  color: $primary-color !important;
  font-weight: 500;
  box-shadow: none;
  text-transform: none;
  font-size: 1rem;
  padding-right: 1rem;
  text-align: left;

  &.alternate {
    background: transparent;
    color: #fff !important;

    &:after {
      color: #fff !important;
    }

    &:hover {
      color: #fff !important;

      &:after {
        color: #fff !important;
      }
    }
  }

  &.disabled {
    color: #757575;

    &:hover {
      color: $primary-color !important;
    }
  }

  &:hover {
    color: darken($primary-color, 5%);

    &:after {
      color: $primary-color;
    }
  }

  &:focus {
    outline: 2px solid $primary-color;
    outline-offset: 2px;
  }

  &::after {
    font-family: $font-family-icons-secondary;
    content: '\f054';
    font-weight: 600;
    font-size: .8rem;
    line-height: 1rem;
    position: relative;
    bottom: .08rem;
    left: .3rem;
    color: $tertiary-color;
  }

  &.external::after {
    font-family: $font-family-icons-secondary;
    font-size: .8rem;
    bottom: .1rem;
    left: .2rem;
    margin-right: .2rem;
    margin-top: -.1rem;
    content: '\f35d';
  }
}

.btn-tertiary1 {
  background    : none;
  padding       : 0 1rem 0 0;
  color         : $primary-color !important;
  font-weight   : 500;
  box-shadow    : none;
  text-transform: none;
  font-size     : 1rem;
  padding-right : 1rem;
  text-align: left;
  text-decoration: none !important;

  &.alternate {
    background: transparent;
    color     : #fff  !important;
    &:after {
      color: #fff !important;
    }
    &:hover {
      color: #fff !important;

      &:after {
        color: #fff !important;
      }
    }
  }

  &.disabled {
    color: #757575;

    &:hover {
      color: $primary-color !important;
    }
  }

  &:hover {
    color: darken($primary-color, 5%);
    &:after {
      color: $primary-color;
    }
  }

  &:focus {
    outline: 2px solid $primary-color;
    outline-offset: 2px;
  }
  
  &::after {
    font-family: $font-family-icons-secondary;
    content    : '\f054';
    font-weight: 600;
    font-size  : .8rem;
    line-height: 1rem;
    position   : relative;
    bottom     : .08rem;
    left       : .3rem;
    color      : $primary1-color;
  }
  &.external::after {
    font-family: $font-family-icons-secondary;
    font-size  : .8rem;
    bottom     : .1rem;
    left       : .2rem;
    margin-right: .2rem;
    margin-top: -.1rem;
    content    : '\f35d';
  }
}

.btn-tertiary4 {
  background: none;
  padding: 0 1rem 0 0;
  color: $primary-color !important;
  font-weight: 500;
  box-shadow: none;
  text-transform: none;
  font-size: 1rem;
  padding-right: 1rem;
  text-align: left;

  &.alternate {
    background: transparent;
    color: #fff !important;

    &:after {
      color: #fff !important;
    }

    &:hover {
      color: #fff !important;

      &:after {
        color: #fff !important;
      }
    }
  }

  &.disabled {
    color: #757575;

    &:hover {
      color: $primary-color !important;
    }
  }

  &:hover {
    color: darken($primary-color, 5%);

    &:after {
      color: $primary-color;
    }
  }

  &:focus {
    outline: 2px solid $primary-color;
    outline-offset: 2px;
  }

  &::after {
    font-family: $font-family-icons-secondary;
    content: '\f054';
    font-weight: 600;
    font-size: .8rem;
    line-height: 1rem;
    position: relative;
    bottom: .08rem;
    left: .3rem;
    color: $primary4-color;
  }

  &.external::after {
    font-family: $font-family-icons-secondary;
    font-size: .8rem;
    bottom: .1rem;
    left: .2rem;
    margin-right: .2rem;
    margin-top: -.1rem;
    content: '\f35d';
  }
}
/**
** Interaction Button
**/
.btn-interactive {
  border-radius: .2rem;
  background: transparent;
  border-color: #454545;
  border-width: 2px;
  color: #454545;
  text-transform: none;
  padding: .75rem 1.3rem;

  &:visited {
    color: #454545;
  }

  &:hover {
    background: #454545;
    color: #ffffff;
  }

  &.pressed,
  &:active {
    background: #454545;
    color: #ffffff;
  }
}

/**
**  Link Text Button
**/
.btn-link-text {
  background: none;
  padding: 0;
  color: $primary-color;
  font-weight: normal;
  box-shadow: none;
  text-transform: none;
  font-size: 1rem;

  &.disabled {
    color: #757575;
    opacity: 1 !important;
  }
}

[class*="col-"] .btn:last-child {
  margin-right: 0 !important;
}

[class*="col-"] pbc-cta .btn {
  margin-right: 1rem !important;
}

[class*="col-"] pbc-cta+pbc-cta:last-child .btn {
  margin-right: 0 !important;
}


.clipboard {
  position: relative;
  padding-right: 1.7rem;

  &::after {
    font-family: $font-family-icons-secondary;
    content: '\f0c5';
    position: absolute;
    right: 0;
    font-size: 1rem;
    margin-right: .2rem;
    margin-top: -.1rem;
  }
}

.btn-colour1 {
  background: transparent;
  border: .15rem solid rgb(9, 90, 9);
  color: rgb(14, 102, 14);
  padding: 0.5% 3% 0.5% 3%;

  &.btn-sm {
    padding: .25rem 1rem;
    font-size: .75rem;
  }
}

.btn-colour2 {
  background: rgb(9, 90, 9);
  border-radius: 0.3125rem;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  width: 10rem;
}


.btnhyperlink-tertiary {
  background: none;
  padding: 0 1rem 0 0;
  color: $primary-color !important;
  font-weight: 500;
  box-shadow: none;
  text-transform: none;
  font-size: 0.9rem;
  padding-right: 1rem;
  text-align: left;

  .btn-tertiary::after &.alternate {
    background: transparent;
    color: #fff !important;

    &:after {
      color: #fff !important;
    }

    &:hover {
      color: #fff !important;

      &:after {
        color: #fff !important;
      }
    }
  }

  &.disabled {
    color: #757575;

    &:hover {
      color: $primary-color !important;
    }
  }

  &:hover {
    color: darken($primary-color, 5%);

    &:after {
      color: $primary-color;
    }
  }

  &:focus {
    outline: 2px solid $primary-color;
    outline-offset: 2px;
  }

  &::after {
    font-family: $font-family-icons-secondary;
    content: '\f054';
    font-weight: 600;
    font-size: .8rem;
    line-height: 1rem;
    position: relative;
    bottom: .0rem;
    left: .3rem;
    color: #F24B43 !important;

  }

  &.external::after {
    font-family: $font-family-icons-secondary;
    font-size: .8rem;
    bottom: .1rem;
    left: .2rem;
    margin-right: .2rem;
    margin-top: -.1rem;
    content: '\f35d';
  }
}

.btn-disable {
  background-color: #63666A;
  color: #000000;
}

.btn-enable {
  background-color: #82C341;
  color: #000000;
}

.btn-goto {
  height: 2.5rem;
  width: 12.541875rem;
  border-radius: 0.3125rem;
  font-family: Arial, Helvetica, sans-serif;
}

.textgoto {
  height: 1.3125rem;
  width: 11.479375rem;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.3125rem;
  text-align: center;
}

.textcontine {
  height: 1rem;
  width: 4rem;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.055rem;
  line-height: 1rem;
  text-align: center;
}

.search-button {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 110px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid #000;
  //background: #FFF;
  margin-top: 13px;
  float: right;
}

.btnguidelink-tertiary {
  background: none;
  padding: 0 1rem 0 0;
  color: $primary-color !important;
  font-weight: 500;
  box-shadow: none;
  text-transform: none;
  font-size: 0.9rem;
  padding-right: 1rem;
  text-align: left;

  .btn-tertiary::after &.alternate {
    background: transparent;
    color: #fff !important;

    &:after {
      color: #fff !important;
    }

    &:hover {
      color: #fff !important;

      &:after {
        color: #fff !important;
      }
    }
  }

  &.disabled {
    color: #757575;

    &:hover {
      color: $primary-color !important;
    }
  }

  &:hover {
    color: darken($primary-color, 5%);

    &:before {
      color: $primary-color;
    }
  }

  &:focus {
    outline: 2px solid $primary-color;
    outline-offset: 2px;
  }

  &::before {
    font-family: $font-family-icons-secondary;
    content: '\f053';
    font-weight: 600;
    font-size: .8rem;
    line-height: 1rem;
    position: relative;
    bottom: .0rem;
    left: .3rem;
    color: #82C341;
    padding-right: 5px;
  }

  &.external::after {
    font-family: $font-family-icons-secondary;
    font-size: .8rem;
    bottom: .1rem;
    left: .2rem;
    margin-right: .2rem;
    margin-top: -.1rem;
    content: '\f35d';
  }
}

.pcpsel_modal_btn {
  height: 36px;
  width: 100px;
}

.button-black-label {
  height: 16px;
  width: 44px;
  color: #000000;
  font-family: $font-family-sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.88px;
  line-height: 14px;
  text-align: center;
}

.btnhyperlink-tertiary-hmo {
  background: none;
  padding: 0 1rem 0 0;
  color: $secondary-color !important;
  font-weight: 500;
  box-shadow: none;
  text-transform: none;
  font-size: 0.8rem;
  padding-right: 1rem;
  text-align: left;

  .btn-tertiary::after &.alternate {
    background: transparent;
    color: #fff !important;

    &:after {
      color: #fff !important;
    }

    &:hover {
      color: #fff !important;

      &:after {
        color: #fff !important;
      }
    }
  }

  &.disabled {
    color: #757575;

    &:hover {
      color: $secondary-color !important;
    }
  }

  &:hover {
    color: darken($secondary-color, 5%);

    &:after {
      color: $secondary-color;
    }
  }

  &:focus {
    outline: 2px solid $secondary-color;
    outline-offset: 2px;
  }

  &::after {
    font-family: $font-family-icons-secondary;
    content: '\f054';
    font-weight: 600;
    font-size: .8rem;
    line-height: 1rem;
    position: relative;
    bottom: .0rem;
    left: .0rem;
    color: #82C341;

  }

  &.external::after {
    font-family: $font-family-icons-secondary;
    font-size: .8rem;
    bottom: .1rem;
    left: .2rem;
    margin-right: .2rem;
    margin-top: -.1rem;
    content: '\f35d';
  }
}

#searchBtn[disabled] {
  color: #FFFFFF !important;
}

#pcpSelectionCard .btnhyperlink-tertiary::after {
  color: #F24B43 !important;
}

.buttonHyperLink {
  background: none;
  padding: 0 1rem 0 0;
  color: $primary-color !important;
  font-weight: 500;
  box-shadow: none;
  text-transform: none;
  font-size: 0.9rem;
  padding-right: 1rem;
  text-align: left;

  .btn-tertiary::after &.alternate {
    background: transparent;
    color: #fff !important;

    &:after {
      color: #fff !important;
    }

    &:hover {
      color: #fff !important;

      &:after {
        color: #fff !important;
      }
    }
  }

  &.disabled {
    color: #757575;

    &:hover {
      color: $primary-color !important;
    }
  }

  &:hover {
    color: darken($primary-color, 5%);

    &:after {
      color: $primary-color;
    }
  }

  &:focus {
    outline: 2px solid $primary-color;
    outline-offset: 2px;
  }

  &::after {
    font-family: $font-family-icons-secondary;
    content: '\f054';
    font-weight: 600;
    font-size: .8rem;
    line-height: 1rem;
    position: relative;
    bottom: .0rem;
    left: .3rem;
    color: #82C341;

  }

  &.external::after {
    font-family: $font-family-icons-secondary;
    font-size: .8rem;
    bottom: .1rem;
    left: .2rem;
    margin-right: .2rem;
    margin-top: -.1rem;
    content: '\f35d';
  }
}

.button_hover{
  &:hover {
    text-decoration: none;
  }
  .buttonHyperLink{
    &:hover {
      text-decoration: none;
    }
  }
}