.stacked-collapse {

  &.mobile {
    background: $accent-background;
    padding: 1rem;

    .stacked-collapse-container {
      background: #fff;
      padding: 1rem 1rem 0rem 1rem;
      margin-bottom: 1rem;
    }

    .stacked-collapse-toggle {
      border-top: 1px solid $neutral-color-light;
      padding: 1rem 0rem;

      &:hover {
        cursor: pointer;
      }
    }

    .stacked-collapse-content {
      // border-top: 1px solid $neutral-color-light;
    }

    .stacked-collapse-footer {
      border-top: 1px solid $neutral-color-light;
      padding: 1rem;
      margin-left: -1rem;
      width: calc(100% + 2rem)
    }

  }
}
