.breadcrumb {
  padding: 0;
  margin-bottom: 4rem;
  list-style: none;
  background-color: transparent;
  border: none;

  a {
    margin-right: 2rem;
    font-size: .8rem;
    font-weight: 600;
    color: $secondary-font-color;
    position: relative;

    &.active {
      color: $accent-color;
      margin-right: 0;

      &:after {
        content: '';
      }
    }

    &:after {
      font-family: $font-family-icons-secondary;
      font-weight: 900;
      content: '\f105';
      color: $secondary-font-color;
      font-size: 1rem;
      right: -1.5rem;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
    }
  }
}

