.modal-dialog {
    border: none;
    border-radius: 0.15rem;

  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    padding: 0 1.25rem 1.25rem 1.25rem;
  }

  .modal-footer {
    border-top: none;
  }

 .close {
   opacity: 1 !important;
   color: #222529;
 }
}


