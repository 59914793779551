// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
 .clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: 100% !important; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: auto; /* 3 */
  padding-right: auto; /* 3 */
  width: 100%; /* 1 */
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.bold {
  font-weight: 500;
}

.bolder {
  font-weight: 600;
}

.text-normalcase {
  text-transform: none;
}

.absolutely-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  padding: inherit;
}

.image-background-centered {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}

p.success {
  border-top: 2px solid $success;
  border-bottom: 2px solid $success;
  text-align: center;
  padding: .5rem;

  i {
    @include media-breakpoint-down(lg) {
      margin-top: -.1rem;
    }
  }
}

p.info {
  border-top: 2px solid $brand-color;
  border-bottom: 2px solid $brand-color;
  text-align: center;
  padding: .5rem;

  -o-border-image: linear-gradient(to top, $brand-color 50%, $accent-secondary-color, 75%, $accent-color);
  border-image: linear-gradient(to top, $brand-color 50%, $accent-secondary-color, 75%, $accent-color);
  border-image-slice: 1;
}

// the  !important is not recommended for usage.
// In this case, it is specifically for overriding default behavior
.rounded-tl {
  border-radius: $formfield-border-radius 0 0 0 !important;
}
.rounded-tr {
  border-radius: 0 $formfield-border-radius 0 0 !important;
}

.rounded-bl {
  border-radius: 0 0 0 $formfield-border-radius !important;
}
.rounded-br {
  border-radius: 0 0 $formfield-border-radius 0 !important;
}


.row.divided {
  > [class*="col"]:not(:last-child):not(:only-child){
    border-right: 1px solid $neutral-color-medium;
  }
}

.text-wrap-normal {
  white-space:normal!important;
}

.text-small {
  font-size:0.75rem;
}

.small-horizontal-nav {
  border-right:1px solid #000;
  a {
    font-weight:normal;
  }
  &:last-of-type{
    border-right:none;
  }
}

.absolutely-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.shadow {
  -webkit-box-shadow: $default-drop-shadow;
  -moz-box-shadow: $default-drop-shadow;
  box-shadow: $default-drop-shadow;
}

/// This should only be used on h1's
// .dash {
//   display: none;
// }

////////////////////////////////////////DO NOT USE IN PRODUCTION CODE
  code {
    margin-bottom:2rem;
    display: block;
    white-space: pre-wrap;
    &.highlighter-rouge {
      color: #b00020 !important;
      display: inline;
      white-space: normal;
    }
  }
  .color-swatch {
    width:100%;
    padding:50%;
  }
  .grid-example{
    .grid-spacer{
      padding:50%;
      width:100%;
      position: relative;
      @include media-breakpoint-down(sm) {
        margin-bottom:15px;
      }
      .grid-item {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        @include media-breakpoint-up(sm) {
          &.grid-item-1 {
            display:block;
          }
          &.grid-item-2 {
            display: none;
          }
        }
        @include media-breakpoint-down(sm) {
          &.grid-item-1 {
            display:none;
          }
          &.grid-item-2 {
            display: block;
          }
        }
      }
    }
  }

.example-content {
  background: #fff;
  margin: 1rem 1rem 0 1rem;
  padding: 1rem;
  box-shadow: $default-drop-shadow;
}

.example-code {
  background: lighten($color: $neutral-color-light, $amount: 5%);
  padding: 1.5rem 1rem 1rem 1rem;
  position: relative;
  font-size: .8rem;
  line-height: 1.5rem;
}

.example-code::before {
  content: 'code';
  background: $neutral-color-dark;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 11px;
  padding: 0px 5px;
  color: white;
}

.b-shadow {
  box-shadow: 0 2px 5px 2px rgb(64 60 67 / 16%);
}
.kinwell-height {
  height:300px;
}

.kinwell-height-ppo {
  width: 480px;
  height: 287px;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
}

.kinwell-smallheight-ppo {
  width: 340px;
  height: 203px;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
}
