h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family; // image-bg-content-overlay-1.component.html headings would not work without this... grrr...
  font-weight: $headings-font-weight;
  line-height: $headings-line-height !important;
  color: $headings-color;
}

// h1.h1-landing, .home-content .h1 { font-size: 3.815rem; } // Do we need this????

h1,
.h1 {
  font-size: $h1-font-size;
  line-height: $h1-font-size * 1.3;
  margin-top: 2rem !important;
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 2.5rem;
  font-family: $font-family-serif !important;


  &:after {
    position: absolute;
    height: 5px;
    width: 55px;
    background: linear-gradient(to left, $accent-color, $brand-color);
    bottom: -.5rem;
    left: 0;
    content: ' ';
  }




  &.centered {
    text-align: center;

    &:after {
      left: calc(50% - 27px);
      content: ' ';
    }
  }

  &.no-dash {
    &:after {
      height: 0;
      width: 0;
      background: transparent;
    }
  }
}

h1,
.h1 {
  &:focus {
    outline: none !important;
  }

  @include media-breakpoint-up(md) {
    font-family: $font-family-serif !important;
  }
}

h2,
.h2 {
  @include media-breakpoint-up(md) {
    font-family: $font-family-serif !important;
  }
}

h3,
.h3 {
  @include media-breakpoint-up(md) {
    font-family: $font-family-serif !important;
  }
}

h1+p,
h1+.alert+p {
  margin-bottom: 3rem;
}

h2 {
  font-size: $h2-font-size;
  line-height: $h2-font-size * 1.2
}

h3 {
  font-size: $h3-font-size;
  line-height: $h3-font-size * 1
}

h4 {
  font-size: $h4-font-size;
  line-height: $h4-font-size * .9
}

h5 {
  font-size: $h5-font-size;
  line-height: $h5-font-size * 1.8
}

h6 {
  font-size: $h6-font-size;
  line-height: $h6-font-size * 2
}

// line-height needs modified for body usage
.h1 {
  font-size: $h1-font-size;
  line-height: 1.2;
  font-family: $font-family-serif !important;
}

.h2 {
  font-size: $h2-font-size;
  line-height: 1.2;
}

.h3 {
  font-size: $h3-font-size;
  line-height: 1.2;
  text-transform: unset !important;
}

.h4 {
  font-size: $h4-font-size;
  line-height: 1.2;
}

.h5 {
  font-size: $h5-font-size;
  line-height: 1.2;
}

.h6 {
  font-size: $h6-font-size;
  line-height: 1.2;
}

h4,
.h4 {
  font-family: $font-family-sans-serif !important;
  text-transform: uppercase;
}

h5,
.h5 {
  font-family: $font-family-sans-serif;
  font-weight: 500;
  text-transform: none;
}

.h7 {
  font-size: 0.8rem !important;
  line-height: 1.2;
  text-transform: unset !important;
  font-family: $font-family-sans-serif;
}

pbc-dashboard .h1 {
  font-family: $font-family-sans-serif;
}

p+.h4,
p+h4,
ul+.h4,
ul+h4 {
  margin-top: 2rem;
}

.pcpheader {
  font-size: $h1-font-size;
  font-family: Lora;
}

.header1 {
  font-size: $h1-font-size;
  line-height: 1.2;
  font-family: $font-family-serif !important;
}

.pcpguide {
  accent-color: #117BAB;
}

.pcpreferral {
  accent-color: #117BAB;
  width: 15px;
  height: 15px;
  border-color: #000;
  outline: 1px solid #000;
  border-color: yellow;
}

.pcpspace {
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 2%;
}

.pcpcenter {
  padding-top: 2%;
  text-align: center;
}

.pcpheader123 {
  background: url("/../../../../assets/image/IMAGE.png");
  background-position: center right;
  background-size: auto 100%;
  background-repeat: no-repeat;

  @media only screen and (min-device-width:1920px) {
    background-position: left !important;
  }

  @media only screen and (min-device-width:1610px) and (max-device-width:1920px) {
    background-position: center left !important;
  }
}

.containerleft {
  padding: 25px 0 25px 100px;
}

.pcpheaderradiuss {
  border-radius: 30px;
  background-color: white;
  margin-right: 48%;

}

.pcppadding {
  padding: 5% 5% 3% 4%;
}

.imagewidth {
  width: 12.5rem;
}

.paddingbottom {
  padding-bottom: 10px
}

.contentheader {
  font-size: 20px;
  font-family: "Roboto Regular";
  line-height: 27px;
  padding-bottom: 10px;
}

.pcphyperlink {
  font-size: 14px;
  line-height: 30px;
}

.pcpguideheader {
  background: url("/../../../../assets/image/PCP-guided-hmo-hero.jpg") no-repeat center center #000;
  background-position: initial;
  background-size: cover;

}

.pcpheadertext {
  height: 57px;
  width: 705px;
  color: #313234;
  font-family: Lora;
  font-size: 42.4px;
  letter-spacing: 0;
  line-height: 57px;
}

.containerguideleft {
  padding: 25px 0 25px 150px;
}

.pcpguideradiuss {
  border-radius: 1.5rem;
  background-color: white;
  margin-right: 48%;

}

.pcppaddingguide {
  padding: 2% 5% 3% 4%;
}

.pcptextcontect {
  height: 108px;
  width: 651px;
  color: #292931;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 27px;
}

.reducetop {
  margin-top: -.5rem;
  font-size: 1.125rem;
}


.pcptheme-container {
  background: url('/../../../../assets/image/search-background.png')no-repeat center center;
  background-size: 100%;
  background-position: right;
  background-size: cover;
}

.pcp-content {
  padding: 50px 0px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.pcp-border {
  border-radius: 30px;
}

.pcp-headerImage {
  height: 20.92px;
  width: 200px;
}

.pcp-cardcontent {
  padding: 2%;
}

.displaychk {
  display: none;
}

.pcpbalancingpadding {
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
}

.display-resolution-card {
  background-color: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-bottom-left-radius: 1.5625rem;
  border-bottom-right-radius: 1.5625rem;
  margin-top: 1.25rem;
}

.underlineheader {
  margin-top: -1rem;
  width: 5rem;
}

.imageguidealignpcp {
  display: none;
}

@media screen and (max-width:950px) {
  .pcpguideheader {
    display: none;
  }

  .pcpheader123 {
    display: none;
  }

  .imageguidealignpcp {
    text-align: center;
    display: inline;
  }

  .imageguidealignres {
    display: none;
  }

  .imageguidealign {
    text-align: center;
  }

  .displaychk {
    display: inline;
    padding-top: 200px;
  }
}

@media screen and (max-width:700px) {
  .imageguidealignres {
    display: inline;
    text-align: center;
  }

  .imageguidealign {
    display: none;
  }
}

@media screen and (min-width:1024px) {
  .pcp-sub-content {
    padding-left: 5.3rem;
    padding-right: 5.3rem;
  }

  .pbc-selection-subcontent {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

.pbc-selection-subcontent {
  #location,#specialtyDDL,#distanceDDL {
    border: solid 2.5px #454545;
  }
  .specialityDDLdropDown {
    .dropdown-toggle::after {
      box-sizing: border-box;
      height: 7px;
      width: 7px;
      position: relative;
      top: -5px;
      left: 10px;
      border-style: solid;
      border-color: #090c0f;
      border-width: 0px 2px 2px 0px;
      transform: rotate(45deg);
      transition: border-width 15ms;
    }
  }
}

.subpcpheader {
  font-size: 1rem;
}

.referal-option-list {
  height: 30px;
  width: 220px;
  border: 1px solid #63666A;
}
@media screen and (max-width:850px) {
  .pcpheader {
    font-size: 1.8rem;
  }
}
.notification-mb {
  margin-bottom: 3.125rem;
}
.empty-notify {
  width: 24rem;
}

.demoDropdown {
  width: 32%;
  margin-top: -0.7rem;
  margin-left: -32px;
  margin-right: 11px;
  border-bottom: none;
  font-size: 14px;
}
