.progress-referrals-tracker {
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    @include make-row();
    // margin: 0 3rem 3rem 3rem;

  i{
    height: 2.9rem;
    width: 2.9rem;
    text-align: center;
    vertical-align: middle;
  }

  .display-label{
    color: #140101
  }

  .progress-step {
    @include make-col(4);
    position: relative;
    text-align: center;
    margin: 1.9rem 0rem;

    &:not(:last-child):after {
      content: '';
      height: 1px;
      background: #0791d1;
      width: 95%;
      position: absolute;
      z-index: 99;
      left: 54%;
    }

    .display-value {
      margin-bottom: 3rem !important;
    }
    .display-value-date{
      margin-bottom: 3rem !important;
    }
  }

    .progress-indicator {
      position: absolute;
      bottom: -1.4rem;
      left: calc(50% - 1.3rem);
      z-index: 199;

      i {
        font-size: 1.9rem;
        padding: 0.47rem;
        color: #fff !important;
        border-radius: 2rem;
      }
    }

    // customizations
    &.complete {
      .progress-step {
        &:not(:last-child):after {
          background: #3fc506;
        }

        .progress-indicator {
          i {
            background: #82C341;
           
            

            &::before {
              content: '\f00c';
            }
          }
        }
      }
    }

    &.received {
      .progress-step:first-child {
        // &::before {
        //   background-color: $success;
        // }
        .progress-indicator{
          i {
            background: #3fc506;

            &::before{
              content: '\f00c';
            }
          }
        }
      }
      .progress-step:nth-child(2) {
        .progress-indicator {
          i {
            background: #0791d1;
            font-size: 2.3rem;
            padding: .33rem 0.3rem;
            color: #fff !important;
            &::before {
              content: '\f017';
            }
          }
        }
      }

      .progress-step:nth-child(3) {
        .progress-indicator {
          i {
            background: #0791d1;
            font-size: 2.3rem;
            padding: .33rem 0.3rem;
            &::before {
              content: '\f017';
            }
          }
        }
      }
    }

    &.processing {
      .progress-step:first-child, .progress-step:nth-child(2) {
        &:not(:nth-child(2)):after {
          background: #3fc506;
        }

        .progress-indicator {
          i {
            background: #3fc506;

            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: #0791d1;
            font-size: 2.3rem;
            padding: .32rem .15rem;
            &::before {
              content: '\f017';
            }
          }
        }
      }
    }

    &.processingDemo {
      .progress-step:first-child {
        &:after {
          background: $success;

          &::before {
            content: '\f111';
          }
        }

        .progress-indicator {
          i {
            background: $success;

            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:nth-child(2), .progress-step:nth-child(3) {
        &:after {
          background: $success;

          &::before {
            content: '\f111';
          }
        }

        .progress-indicator {
          i {
            background: #CCC;

            &::before {
              content: '\f00c';
            }
          }
        }
      }
      
    }

    &.attention {
      .progress-step:first-child, .progress-step:nth-child(2) {
        &:after {
          background: $success;
        }

        .progress-indicator {
          i {
            background: $success;

            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:nth-child(3) {
        .progress-indicator {
          i {
            background: #CCC;

            &::before {
              content: '\f111';
            }
          }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: $warning;
            padding: .45rem .73rem;

            &::before {
              content: '\f12a';
            }
          }
        }
      }
    }

    &.denied {
      .progress-step:first-child, .progress-step:nth-child(2), .progress-step:nth-child(3) {
        &:after {
          background: #3fc506;
        }

        .progress-indicator {
          i {
            background: #3fc506;
            

            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: $tertiary-color;
            padding: .45rem 1.15rem;

            &::before {
              content: '\f129';
            }
          }
        }
      }
    }
  }
//-----------------------------------------------------------------------
  @include media-breakpoint-down(xs) {

    &.complete {
      .progress-step {
        border-left: 1px solid #3fc506;
      }

      .progress-indicator {
        i {
          background: #3fc506;

          &::before {
            content: '\f00c';
          }
        }
      }

      .progress-step:last-child{
        border-left: transparent;
      }
    }

    &.received {
      .progress-step:first-child ,.progress-step:nth-child(2) {
        border-left: 1px solid #0791d1;
        
        .progress-indicator{
          i {
            background: #3fc506;

            &::before{
              content: '\f00c';
            }
          }
        }
      }
      .progress-step:nth-child(2) {
        
        align-items: center;
        .progress-indicator {
          i {
            background: #0791d1;
            font-size: 1.6rem;
            padding:.265rem ;
            padding-top: 0.32rem;
            text-align: center;
            vertical-align: middle;
            color: #fff !important;
            &::before {
              content: '\f017';
            }
          }
        }
      }

      .progress-step:nth-child(3) {
        .progress-indicator {
          i {
            background: #0791d1;
            font-size: 1.6rem;
            padding:.265rem;
            &::before {
              content: '\f017';
            }
          }
        }
      }
    }

    &.processing {
      .progress-step:first-child {
        border-left: 1px solid #3fc506;
        .progress-indicator {
          i {
            background: #3fc506;

            &::before {
              content: '\f00c';
            }
          }
        }
      }

       .progress-step:nth-child(2) {
        border-left: 1px solid #0791d1;
        .progress-indicator {
          i {
            background: #3fc506;
            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: #0791d1;
            font-size: 1.6rem;
            padding:.265rem;
            &::before {
              content: '\f017';
            }
          }
        }
      }
    }

    &.attention {
      .progress-step {
        border-left: 1px solid #D8D6CF;
      }

      .progress-step:first-child, .progress-step:nth-child(2) {
        .progress-indicator {
          i {
            background: $success;

            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:nth-child(3) {
        .progress-indicator {
          i {
            background: #CCC;

            &::before {
              content: '\f111';
            }
         }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: #e67300;
            padding: 0.3rem 0.6rem;

            &::before {
              content: '\f12a';
            }
          }
        }
      }
    }

    &.denied {
      .progress-step {
        border-left: 1px solid #3fc506;
      }

      .progress-indicator {
        i {
          background: #3fc506;

          &::before {
            content: '\f00c';
          }
        }
      }

      .progress-step:last-child {
        border-left: transparent;
        .progress-indicator {
          i {
            background: $tertiary-color;
            padding: .5rem .88rem;

            &::before {
              content: '\f129';
            }
          }
        }
      }
    }

    .progress-step {
      background: #fff;
      position: relative;
      padding: 0rem 0rem 1rem 1.5rem;
      
      
      .display-value {
        margin-bottom: 0 !important;
      }
      .display-value-date{
        margin-bottom: 0 !important;
      }
      .progress-indicator {
        background: white;
        position: absolute;
        height: 10px;
        top: -.2rem;
        left: -.75rem;
        i {
          // font-size: .9rem;
          padding: 0.5em;
          color: #fff !important;
          border-radius: 60px;
        }
      }
    }
  }

  @media only screen and (min-width: 1800px) {
    i{
      height: 2.9rem;
      width: 2.9rem;
      text-align: center;
      vertical-align: middle;
    }
  
    .display-label{
      color: #140101
    }
  
    .progress-step {
      @include make-col(4);
      position: relative;
      text-align: center;
      margin: 1.7rem 0rem;
  
      &:not(:last-child):after {
        content: '';
        height: 1px;
        background: #0791d1;
        width: 95%;
        position: absolute;
        z-index: 99;
        left: 54%;
      }
  
      .display-value {
        margin-bottom: 3rem !important;
      }
      .display-value-date{
        margin-bottom: 3rem !important;
      }
    }

    &.complete {
      .progress-step {
        &:not(:last-child):after {
          background: #3fc506;
        }

        .progress-indicator {
          i {
            background: #3fc506;

            &::before {
              content: '\f00c';
            }
          }
        }
      }
    }

    &.received {
      .progress-step:first-child {
        // &::before {
        //   background-color: $success;
        // }
        .progress-indicator{
          i {
            background: #3fc506;

            &::before{
              content: '\f00c';
            }
          }
        }
      }
      .progress-step:nth-child(2) {
        .progress-indicator {
          i {
            background: #0791d1;
            font-size: 2.3rem;
            padding: .29rem 0.333rem;
            color: #fff !important;
            &::before {
              content: '\f017';
            }
          }
        }
      }

      .progress-step:nth-child(3) {
        .progress-indicator {
          i {
            background: #0791d1;
            font-size: 2.3rem;
            padding: .29rem 0.333rem;
            &::before {
              content: '\f017';
            }
          }
        }
      }
    }
    
    &.processing {
      .progress-step:first-child, .progress-step:nth-child(2) {
        &:not(:nth-child(2)):after {
          background: #3fc506;
        }

        .progress-indicator {
          i {
            background: #3fc506;

            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: #0791d1;
            font-size: 2.3rem;
            padding: .29rem 0.333rem;
            &::before {
              content: '\f017';
            }
          }
        }
      }
    }

    &.denied {
      .progress-step:first-child, .progress-step:nth-child(2), .progress-step:nth-child(3) {
        &:after {
          background: #3fc506;
        }

        .progress-indicator {
          i {
            background: #3fc506;
            

            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: $secondary-font-color;
            padding: .45rem 1.15rem;

            &::before {
              content: '\f129';
            }
          }
        }
      }
    }
  }
}