.user-with-card {
  text-align: center;

  .body-small {
    overflow: hidden;
  }

  .user-img {
    width: 25%;
    margin-bottom: .5rem;

    @include media-breakpoint-down(sm) {
      width: 50%;
    }
  }

  .card-link {
    margin-top: .5rem;

    &:focus {
      outline: 2px solid $primary-color !important;
      outline-offset: 2px !important;
    }

    &.pressed {
      position: relative;

      &:after {
        content: '';
        right: 0;
        left: 2rem;
        top: 1.8rem;
        position: absolute;
        z-index: 9992;
        width: 0;
        height: 0;
        border-left: 1.3rem solid transparent;
        border-right: 1.3rem solid transparent;
        border-bottom: 1.3rem solid $accent-background;
      }
    }
  }
}

.card.id {
  margin-top: 1rem;
  background: $accent-background;
  position: relative;
  margin-bottom: 1rem;
  z-index: 10;

  .card-body {
    background: $accent-background;
  }

  .card-header {
    background: $accent-background;
    border-bottom: none;
    padding-bottom: 0;
  }

  .card-footer {
    background: $accent-background;
    border-top: none;
  }

  a.card-flip {
    font-size: .88rem;
    color: darken($primary-color, 5%);

    &:hover {
      cursor: pointer;
    }

    &.selected {
      color: $default-font-color;
    }
  }

  a.btn-secondary {
    @include media-breakpoint-down(sm) {
      display: block;
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }
}

.tile-cta-group {
  height: 40px;
  width: 100px;
  background-color: #266726;
  border-radius: 10px;
}

.button-label {
  height: 16px;
  width: 60px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 37px;
  text-align: center;
}

.rectangle {
  border-radius: .5rem;
  background-color: #FFFFFF;
  box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, 0.3);
}

.title {
  height: 1.35rem;
  width: 8.125rem;
  color: #313234;
  font-family: Lora;
  font-size: 1.25rem;
  letter-spacing: 0;
  line-height: 1.6875rem;
  padding-left: 2.1875rem;
  padding-top: 0.25rem;
}

.text {
  height: 6.25rem;
  width: 13.125rem;
  color: #292931;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  padding-right: 0.625rem;
}

.divspelcol {
  box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, 0.3);
  border: 4px solid #82C341;
}

.divintcol {
  border: 4px solid #FFFFFF;
  box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, 0.3);
}

.guided-tittle {
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
}

.guided-list {
  font-size: 1.1rem;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width:710px) {
  .title {
    font-size: 1.0625rem;
    line-height: 1.1875rem;
    padding-left: 2.8rem;
    padding-top: .3rem;
  }

  .text {
    font-size: 0.75rem;
  }
}

.referral-margin {
  margin-top: -0.5rem;
}

#referralFilter{
  .ref-filter-align {
    float: right;
  }
}

@media screen and (max-width:480px) {
  .referral-margin{
    margin-top: 3rem;
  }
}
@media screen and (max-width:767px) {
  .referral-padding {
    padding-top:1rem !important;
    padding-bottom: 1rem;
  }
}