.progress-tracker {
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    @include make-row();
    // margin: 0 3rem 3rem 3rem;

  .progress-step {
    @include make-col(3);
    position: relative;
    text-align: center;

    &:not(:last-child):after {
      content: '';
      height: 1px;
      background: #D8D6CF;
      width: 78%;
      position: absolute;
      z-index: 99;
      left: 60%;
    }

    .display-value {
      margin-bottom: 2rem !important;
    }
    .display-value-date{
      margin-bottom: 2rem !important;
    }
  }

    .progress-indicator {
      position: absolute;
      bottom: -.8rem;
      left: calc(50% - 1rem);
      z-index: 199;

      i {
        font-size: .9rem;
        padding: 0.45em;
        color: #fff !important;
        border-radius: 2rem;
      }
    }

    // customizations
    &.complete {
      .progress-step {
        &:not(:last-child):after {
          background: $success;
        }

        .progress-indicator {
          i {
            background: $success;

            &::before {
              content: '\f00c';
            }
          }
        }
      }
    }

    &.received {
      .progress-step:first-child {
        &:after {
          background-color: $success;
        }
        .progress-indicator{
          i {
            background: $success;

            &::before{
              content: '\f00c';
            }
          }
        }
      }
      .progress-step:nth-child(2) {
        .progress-indicator {
          i {
            background: $info;

            &::before {
              content: '\f2f1';
            }
          }
        }
      }
    }

    &.processing {
      .progress-step:first-child, .progress-step:nth-child(2) {
        &:after {
          background: $success;
        }

        .progress-indicator {
          i {
            background: $success;

            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:nth-child(3) {
        .progress-indicator {
          i {
            background: $info;

            &::before {
              content: '\f2f1';
            }
          }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: #CCC;

            &::before {
              content: '\f111';
            }
          }
        }
      }
    }

    &.processingDemo {
      .progress-step:first-child {
        &:after {
          background: $success;

          &::before {
            content: '\f111';
          }
        }

        .progress-indicator {
          i {
            background: $success;

            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:nth-child(2), .progress-step:nth-child(3) {
        &:after {
          background: $success;

          &::before {
            content: '\f111';
          }
        }

        .progress-indicator {
          i {
            background: #CCC;

            &::before {
              content: '\f00c';
            }
          }
        }
      }
      
    }
    //   .progress-step:nth-child(3) {
    //     .progress-indicator {
    //       i {
    //         background: $info;

    //         &::before {
    //           content: '\f2f1';
    //         }
    //       }
    //     }
    //   }

    //   .progress-step:last-child {
    //     .progress-indicator {
    //       i {
    //         background: #CCC;

    //         &::before {
    //           content: '\f111';
    //         }
    //       }
    //     }
    //   }
    // }

    &.attention {
      .progress-step:first-child, .progress-step:nth-child(2) {
        &:after {
          background: $success;
        }

        .progress-indicator {
          i {
            background: $success;

            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:nth-child(3) {
        .progress-indicator {
          i {
            background: $warning;
            padding: .45rem .73rem;

            &::before {
              content: '\f12a';
            }
          }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: #CCC;

            &::before {
              content: '\f111';
            }
          }
        }
      }
    }

    &.denied {
      .progress-step:first-child, .progress-step:nth-child(2), .progress-step:nth-child(3) {
        &:after {
          background: $success;
        }

        .progress-indicator {
          i {
            background: $success;

            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: $secondary-font-color;
            padding: .45rem .73rem;

            &::before {
              content: '\f129';
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {

    &.complete {
      .progress-step {
        border-left: 1px solid $success;
      }

      .progress-indicator {
        i {
          background: $success;

          &::before {
            content: '\f00c';
          }
        }
      }
    }

    &.processing {
      .progress-step:first-child, .progress-step:nth-child(2) {
        border-left: 1px solid $success;
        .progress-indicator {
          i {
            background: $success;

            &::before {
              content: '\f00c';
            }
          }
        }
      }

       .progress-step:nth-child(3) {
        border-left: 1px solid $info;
        .progress-indicator {
          i {
            background: $info;

            &::before {
              content: '\f2f1';
            }
          }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: #CCC;

            &::before {
              content: '\f192';
            }
          }
        }
      }
    }

    &.attention {
      .progress-step {
        border-left: 1px solid #D8D6CF;
      }

      .progress-step:first-child, .progress-step:nth-child(2) {
        .progress-indicator {
          i {
            background: $success;

            &::before {
              content: '\f00c';
            }
          }
        }
      }

      .progress-step:nth-child(3) {
        .progress-indicator {
          i {
            background: #e67300;
            padding: 0.3rem 0.6rem;

            &::before {
              content: '\f12a';
            }
         }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: #CCC;

            &::before {
              content: '\f111';
            }
          }
        }
      }
    }

    &.denied {
      .progress-step {
        border-left: 1px solid #D8D6CF;
      }

      .progress-indicator {
        i {
          background: $success;

          &::before {
            content: '\f00c';
          }
        }
      }

      .progress-step:last-child {
        .progress-indicator {
          i {
            background: #63666A;
            padding: 0.3em 0.6em !important;

            &::before {
              content: '\f129';
            }
          }
        }
      }
    }

    .progress-step {
      background: #fff;
      position: relative;
      padding: 0rem 0rem 1rem 1.5rem;
      margin: 0 auto;

      &:last-child {
        border: none;
        padding-bottom: 0 !important;
      }

      .display-value {
        margin-bottom: 0 !important;
      }
      .display-value-date{
        margin-bottom: 0 !important;
      }

      .progress-indicator {
        background: white;
        position: absolute;
        top: -.2rem;
        left: -.75rem;
        padding: 3px 0px;
        i {
          // font-size: .9rem;
          padding: 0.5em;
          color: #fff !important;
          border-radius: 60px;
        }
      }
    }
  }
}

