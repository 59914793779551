// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------


footer {

  .footer-primary {
    background: $primary-color;
    color: #fff;
    padding: 3rem;

    .h5 {
      color: #fff;
      line-height: 1rem;
      font-weight: 500;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0;

        a {
          color: #fff;
          font-size: .75rem; // same size as body-small
          font-weight: 400;

          &:visited {
            color: #fff;
          }
        }
      }
    }

  }

  .copyright {
    font-size: $font-size-sm;
    margin-bottom: 0;
  }

  .legal {
    font-size: $font-size-sm;
  }

  .footer-legal {
    background: darken($primary-color, 10%);
    color: #fff;
    padding: 3rem;

    a,
    a:visited {
      color: #fff;
    }

    h2 {
      color: #fff;
      line-height: 1rem;
    }

    #language-support {
      a {
        font-size: .75rem; // same size as body-small
        display: inline-block;

        &:after {
          content: '|';
          margin: 0 .2rem;
        }

        &:last-child:after {
          content: '';
        }
      }
    }
  }
}

// new design
footer {
  background: $accent-background;
  background-size: cover;
  padding: 3rem;

  a {
    color: #000 !important;
  }

  .fab {
    border: 8px solid #3C3C41;
    border-radius: 2.5rem;
    color: #FFFEFE;
    background: #3C3C41;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    line-height: 1.6rem;
    margin: 0 1rem 0 0;

    &:hover {
      border-color: $accent-color;
      background-color: $accent-color;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin: 0 0 .5rem 0;

      a {
        font-weight: 400;
        font-size: .9rem;
        padding: 0;

        &:hover {
          text-decoration: underline;
          color: #117bab !important;
        }
      }
    }
  }

  .footer-heading {
    font-size: 1rem;
    font-weight: 500;
    font-family: $font-family-sans-serif  !important;
    margin-bottom: .5rem;
  }

  #footer-logo {
    margin-bottom: 2rem;
    max-width: 12rem;

    @include media-breakpoint-down(xs) {
      max-width: 14.3rem;
    }
  }

  #language-support {
    a {
      font-size: 1rem; // same size as body-small
      display: inline-block;
      color: #000;

      &:after {
        content: '|';
        margin: 0 .2rem;
      }

      &:last-child:after {
        content: '';
      }
    }
  }

  &.mobile {
    padding: 3rem;

    @include media-breakpoint-down(xs) {
      padding: 3rem 1rem;
    }

    .navbar {
      margin: 0 0 2rem -3rem !important;
      padding: 0 !important;
      width: calc(100% + 6rem) !important;

      @include media-breakpoint-down(xs) {
        width: calc(100% + 4rem) !important;
      }
    }

    .nav-item {
      display: block;
      border-bottom: solid 1px $neutral-color-light;
      margin-bottom: 0;
      padding: 1rem 3rem 1rem 3rem;

      &.show {
        padding: 1rem 1rem 0rem 3rem;

        &:last-child {
          .dropdown-toggle {
            &:after {
              font-family: $font-family-icons;
              font-weight: 900;
              content: '\e316';
              top: .5rem;
              right: 4.4rem;
              font-size: 2rem;
            }
          }
        }
      }
    }

    .navbar-nav {
      margin: 0;
      width: 100%;

      .nav-link {
        font-size: 1.2rem;
      }
    }

    .dropdown-toggle[aria-expanded="true"]::after {
      content: '\f068';
    }

    .dropdown-toggle {
      &:after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0;
        /* vertical-align: 0.255em; */
        border-top: none;
        border-right: none;
        border-bottom: 0;
        right: 4rem;
        border-left: none;
        position: absolute;
        color: $primary-color;
        top: 1rem;
        font-family: $font-family-icons-secondary;
        font-weight: 900;
        content: '\f067';
        z-index: 9990;
        font-size: 18px;
      }
    }

    .dropdown-menu {
      background: transparent;
      border: none;
      top: 0px !important;
      left: 0px !important;
      width: 100%;
      border-radius: 0px;
      position: relative !important;
      transform: translate(0px, 0px) !important;
      margin-left: -3rem;
      width: calc(100% + 4rem) !important;
      padding: 0;

      .dropdown-item {
        padding: .8rem 1rem .8rem 4rem;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background: $accent-background;
        border: 0;
        font-size: 1rem;
        border-top: 1px solid $neutral-color-light;
      }
    }

    .social {
      margin: 0rem 0rem 3rem 0rem;

      img {
        float: left;
        max-width: 8rem;
      }
    }

    #footer-logo {
      // TODO: Align with header when header complete
    }

    #language-support {
      margin: 1rem 0rem;
    }
  }
}

.disclaimer {
  height: 14px;
  //width: 1169px;
  color: #292931;
  font-family: "Roboto", sans-serif !important;
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 14px;
}