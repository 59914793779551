
/***************************************************
        TABLE OF CONTENTS


******************************************************/



.clear-bg, background-clear {
  background-color: transparent !important;
}

.background-white {
  background: #fff;
}

// Background color pallette presets
.background-brand,.bg-brand {
  background: $brand-color;
  color: #ffffff;
}

.bg-primary-light {
  background: lighten($primary-color, 10);
}

.bg-primary-lighten {
  background: lighten($primary-color, 15);
}


.bg-primary-lightest {
  background: lighten($primary-color, 20);
}

.bg-primary-lightest {
  background: lighten($primary-color, 55);
}

.background-primary, .bg-primary {
  background: $primary-color !important;
  color: #fff !important;

  a, h1, h2, h3, h4, h5, p, .h1, .h2, .h3, .h4, .h5 {
    color: #fff;
  }


  h1, .h1 {
    &:after {
      background: #fff;
    }
  }

  .btn-primary {
    background: transparent;
    color: #fff !important;
    text-decoration: none !important;
    border-color: #fff !important;

    &:hover {
      border: 1px solid #fff;
      background: none;
      color: #fff !important;
    }
  }

  .btn-secondary {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    text-decoration: none !important;

    &:hover {
      background: #fff;
      color: $primary-color;
    }
  }

  .btn-tertiary {
    background: transparent;
    color: #fff !important;
    text-decoration: none !important;

    &:after {
      color: #fff;
    }

    &:hover {
      color: #fff !important;
      text-decoration: none !important;

      &:after {
        color: #fff !important;
        text-decoration: none !important;
      }
    }
  }

  * {
    color: #fff;
    a{
        &:hover, &:visited, &:link, &:active {
        color:#fff;
        text-decoration: underline;
      }

      &:focus {
        outline-color: #fff;
      }
    }
  }
}

.background-primary2 {
  background: $primary2-color !important;
  color: #fff !important;

  a, h1, h2, h3, h4, h5, p, .h1, .h2, .h3, .h4, .h5 {
    color: #fff;
  }


  h1, .h1 {
    &:after {
      background: #fff;
    }
  }

  .btn-primary {
    background: transparent;
    color: #fff !important;
    text-decoration: none !important;
    border-color: #fff !important;

    &:hover {
      border: 1px solid #fff;
      background: none;
      color: #fff !important;
    }
  }

  .btn-secondary {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    text-decoration: none !important;

    &:hover {
      background: #fff;
      color: $primary-color;
    }
  }

  .btn-tertiary {
    background: transparent;
    color: #fff !important;
    text-decoration: none !important;

    &:after {
      color: #fff;
    }

    &:hover {
      color: #fff !important;
      text-decoration: none !important;

      &:after {
        color: #fff !important;
        text-decoration: none !important;
      }
    }
  }

  * {
    color: #fff;
    a{
        &:hover, &:visited, &:link, &:active {
        color:#fff;
        text-decoration: underline;
      }

      &:focus {
        outline-color: #fff;
      }
    }
  }
}

.background-primary2c {
  background: $primary2-color !important;
  color: #292931 !important;
  a, h1, h3, h4, h5, p, .h1, .h2, .h3, .h4, .h5 {
    color: #292931;
  }
  h2 {
    color: #fff;
  }
  h1, .h1 {
    &:after {
      background: #292931;
    }
  }
  * {
    color: #292931;
    a{
        &:hover, &:visited, &:link, &:active {
        color:#292931;
        text-decoration: underline;
      }
      &:focus {
        outline-color: #292931;
      }
    }
  }
}

.background-primary3 {
  background: $primary3-color !important;
  color: #fff !important;

  a, h1, h2, h3, h4, h5, p, .h1, .h2, .h3, .h4, .h5 {
    color: #fff;
  }


  h1, .h1 {
    &:after {
      background: #fff;
    }
  }

  .btn-primary {
    background: transparent;
    color: #fff !important;
    text-decoration: none !important;
    border-color: #fff !important;

    &:hover {
      border: 1px solid #fff;
      background: none;
      color: #fff !important;
    }
  }

  .btn-secondary {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    text-decoration: none !important;

    &:hover {
      background: #fff;
      color: $primary-color;
    }
  }

  .btn-tertiary {
    background: transparent;
    color: #fff !important;
    text-decoration: none !important;

    &:after {
      color: #fff;
    }

    &:hover {
      color: #fff !important;
      text-decoration: none !important;

      &:after {
        color: #fff !important;
        text-decoration: none !important;
      }
    }
  }

  * {
    color: #fff;
    a{
        &:hover, &:visited, &:link, &:active {
        color:#fff;
        text-decoration: underline;
      }

      &:focus {
        outline-color: #fff;
      }
    }
  }
}


.background-accent1 {
  background: $accent1-background !important; 
  color: #292931!important;
}
.background-secondary, .bg-secondary {
  background: $secondary-color !important;
  color: #ffffff;
}

.background-tertiary {
  background: $tertiary-color;
  color: #ffffff;
}

.background-accent, .bg-accent {
  background: $accent-background !important;
}

.bg-neutral-color-lightest {
  background: $neutral-color-lightest !important;
}

.background-accent-secondary {
  background: $accent-secondary-color !important;
}

.background-accent-tertiary {
  background: $accent-tertiary-color !important;
}

// Background neutral pallette presets
.background-lightest, .bg-light { // DO NOT USE bg-light.  THIS IS AN OVERRIDE FOR A VENDOR STYLE
  background: $neutral-color-lightest !important;

  .btn-tertiary {
    color: darken($color: $primary-color, $amount: 2%);
  }
}

.background-lighter, .bg-lighter {
  background: $neutral-color-lighter !important;
}

.background-light {
  background: $neutral-color-light !important;
}

.background-medium {
  background: $neutral-color-medium !important;

}

.background-dark, .bg-dark {
  background: $neutral-color-dark !important;

  a, h1, h2, h3, h4, h5, p {
    color: #ffffff !important;
  }
}

.background-darkest {
  background: $neutral-color-darkest !important;
  color: #ffffff;

  * {
    color: #ffffff !important;
  }
}

// Gradient backgrounds
.background-gradient {
  background: -moz-linear-gradient(linear, left top, right top, color-stop(25%, $brand-color), to($accent-color)) !important;
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, $brand-color), to($accent-color)) !important;
  background: linear-gradient(to right, $brand-color 25%, $accent-color) !important;
}

.background-gradient-diagonal {
  background: $brand-color; /* Old browsers */
  background: -moz-linear-gradient(-45deg, $brand-color 3%, $accent-secondary-color 100%) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, $brand-color 3%,$accent-secondary-color 100%) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, $brand-color 3%,$accent-secondary-color 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$brand-color', endColorstr='$accent-secondary-color',GradientType=1 ) !important; /* IE6-9 fallback on horizontal gradient */
}

// Semantic backgrounds
.background-success {
  background: $success !important;
}

.background-warning {
  background: $warning;
}

.background-disabled {
  background: $disabled-color;
}

.background-disabled {
  background: $disabled-color;
}

// Miscellaneous backgrounds
.background-secondary-font-color {
  background: $secondary-font-color;
}

.background-display-font-color {
  background: $display-font-color;
}

.background-display-secondary-font-color {
  background: $display-secondary-font-color;
}

.background-link-font-color {
  background: $link-font-color;
}

.background-link-visited-font-color {
  background: $link-visited-font-color;
}

.background-pale{
  background: #f3fbfe !important;
}