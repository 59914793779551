.details-panel-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2{
    color: #313234;
    font-family: Lora;
    font-size: 1.758rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;

    @media screen and (min-width: 2100px) {
      font-size: 2rem !important;
    }
  }

  label{
    color: #63666A;
    //font-family: Roboto;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 133.333% */

    @media screen and (min-width: 2100px) {
      font-size: 1.3rem !important;
    }
  }

  p{
    color: #292931;
    //font-family: Roboto;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (min-width: 2100px) {
      font-size: 1.2rem !important;
    }
  }

  input{
    height: 1.125rem;
    min-width: 1.125rem;

    @media screen and (min-width: 2100px) {
      min-height: 1rem;
      min-width: 1rem;
      margin-top: 0.2rem;
      margin-right: 0.5rem;
    }
  }
}

.details-panel-container{
  display: flex;
  flex-direction: column;
}

.panel-width{
  width: 40%;

  @include media-breakpoint-down(sm) {
    width: 60%;
  }

  @include media-breakpoint-down(xs) {
    width: 75%;
  }
}

.claims-details-space{
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.33);
  background: #FFF;
  padding: 1rem 1.5rem;

  label{
    color: #292931;
    //font-family: Roboto;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (min-width: 2100px) {
      font-size: 1rem;
    }
  }

  span{
    color: #292931;
    //font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1rem;

    @media screen and (min-width: 2100px) {
      font-size: 1rem;
    }
  }
}

.claims-service-space{
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  padding: 1rem 1.5rem;
  border-right: 1px solid rgba(0, 0, 0, 0.33);
  border-bottom: 1px solid rgba(0, 0, 0, 0.33);
  border-left: 1px solid rgba(0, 0, 0, 0.33);
  background: #EFF9FD;

  label, h6{
    color: #292931;
    //font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media screen and (min-width: 2100px) {
      font-size: 1.3rem;
    }
  }
}

.discount-subtotal-class{
  border-top: 1px solid var(--premera-neutral-4, #9E9E9E);
  
  label{
    font-weight: bold;
  }

  span{
    font-weight: bold;
  }

  h2{
    font-weight: bold;
  }
}

.form-values{
  h6{
    color: #292931;
    //font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    margin-left: 1rem;

    @media screen and (min-width: 2100px) {
      font-size: 1.3rem;
    }
  }

  label{
    color: #292931;
    //font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 2rem;

    @media screen and (min-width: 2100px) {
      font-size: 1.3rem;
    }
  }

  span{
    color: #292931;
    text-align: right;
    //font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1rem;

    @media screen and (min-width: 2100px) {
      font-size: 1.3rem;
    }
  }
}

.switch-1{

  .slider:after{
    content: "NO";
  }

  input:checked+.slider:after{
    content: "Yes";
  }
}

.confirmation-list{
  text-indent: -4px;
}