.switch {
  position: relative;
  display: inline-block;
  width: 4.7rem;
  height: 2rem;
  margin: 0;
  font-size: 0;
  top: -1.5rem;
  margin-left: 30px;

  @include media-breakpoint-down(sm) {
    height: 2.05rem;
  }

  &.disabled {
    pointer-events: none;

    .slider {
      border: 1px solid $disabled-color;
      background: #fff !important;

      &:before {
        border: 1px solid $disabled-color;
        background: #fff !important;
        left: 1px;
        bottom: 0px;
      }

      &:after {
        color: $disabled-color !important;
      }
    }

    input:checked+.slider {

      &:before {
        left: -3px;
        bottom: 0;
      }

      &:after {
        color: $disabled-color;
      }
    }
  }

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $disabled-color;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 18px;

    @include media-breakpoint-down(xl) {
      border-radius: 24px;
    }

    @include media-breakpoint-up(xxl) {
      border-radius: 45px;
      bottom: 2px;
    }

    &.enabled {
      background-color: $default-font-color;
    }
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.85rem;
    width: 1.9rem;
    bottom: 1px;
    left: 1px;
    background-color: #FFF;
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 50%;
  }

  .slider:after {
    content: "OFF";
    position: absolute;
    font-size: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: $white;
    left: 2.3rem;
    font-weight: 400;
  }

  input:checked+.slider {
    background-color: $primary-color;

    &:after {
      content: "ON";
      font-size: 1rem;
      left: .8rem;
      right: auto;
      font-weight: 400;
    }

    &:before {
      left: .5rem;

      @include media-breakpoint-up(xxl) {
        left: 1.75rem;
      }

      @include media-breakpoint-down(xl) {
        left: 1.25rem;
      }

      @include media-breakpoint-down(lg) {
        left: .75rem;
      }

      @include media-breakpoint-down(md) {
        left: .5rem;
      }

      @include media-breakpoint-down(sm) {
        left: .16rem;
      }
    }

    &.enabled {
      background-color: $default-font-color;
    }
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(36px);
    -ms-transform: translateX(36px);
    transform: translateX(36px);
  }

  input:disabled+.slider {
    border: 1px solid $disabled-color;
    background: #fff !important;
    pointer-events: none;

    &:before {
      border: 1px solid $disabled-color;
      background: #fff !important;
      bottom: 0px;
    }

    &:after {
      color: $disabled-color !important;
    }
  }

  input:disabled:not(:checked)+.slider:before {
    left: -1px;
  }

}

@media only screen and (max-width:778px) {
  .switch {
    margin-left: 0px;
  }

  @media only screen and (min-width:400px) and (max-width:520px) {
    .switch {
      margin-left: 12px;
    }
  }

}

.guideswitch {
  position: relative;
  display: inline-block;
  width: 4.17rem;
  height: 1.7rem;
  margin: 0 .5rem;
  font-size: 0%;
  TOP: -1rem;
}

.zerofont{
  font-size: 0%;
}

.guideslide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #737373;
  transition: .4s;
  border-radius: 1.52rem;
}

.guideswitch input {
  display: none;
}

.guideslide:before {
  position: absolute;
  content: "";
  top: .05rem;
  left: .05rem;
  height: 1.6rem;
  width: 1.6rem;
  background-color: #FFFFFF;
  transition: .4s;
  border-radius: 2.5rem;
}

.guideslide:after {
  content: "OFF";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2rem;
  height: .95rem;
  width: 1.8rem;
  color: #FFFFFF;
  font-family: Arial, Helvetica, sans-serif;
  font-size: .9rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.2rem;
}

input:checked+.guideslide {
  background-color: $primary-color;

  &:after {
    content: "ON";
    font-size: 1rem;
    left: .8rem;
    right: auto;
    height: .95rem;
    width: 1.8rem;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: .9rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.2rem;
  }
}

input:checked+.guideslide:before {
  transform: translate(2.45rem);
}

@media screen and (max-width:710px) {
  .guideswitch {
    width: 55px;
    height: 23px;
  }

  .guideslide:before {
    height: 1.45rem;
    width: 1.45rem;
  }

  input:checked+.guideslide {
    &:after {
      font-size: 1rem;
      left: .5rem;
    }
  }

  input:checked+.guideslide:before {
    transform: translate(2.3rem);
  }

  .guideslide:after {
    left: 2rem;
    font-family: Roboto;
    font-size: 1rem;
  }
}