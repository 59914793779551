.pagination {
  font-size: 1rem;
  font-weight: 400;

  .page-item {
    &:first-child .page-link {
      border-radius: 2rem;

      &:after {
        content: 'Previous';
        color: #7C7C7C;
      }
      span {
        display: none;
      }
    }

    &:last-child .page-link {
      margin-right: 0;
      border-radius: 2rem;

      &:after {
        content: 'Next';
        color: #7C7C7C;
      }
      span {
        display: none;
      }
    }

    &.active .page-link {
      background-color: $primary-color;
      border-radius: 50%;
      color: #fff !important;

      &:hover {
        background-color: darken($primary-color, 10%) ;
      }
    }

    .page-link {
      padding: 0.15rem 0.4rem;
      border: none !important;
      border-radius: 50%;
      margin-right: .5rem;
      color: darken($primary-color, 20%) !important;
      z-index:auto;
    }
  }
}
#preapprovalPagination {
.pagination {
  .page-item {
  &.active .page-link {    
      background-color: #117BAB;
      border-radius: 50%;
      color: #fff !important;
      width: 25px;
      height: 25px;
      padding-right: 0.55rem;
      padding-top: 0.188rem;
      font-size: 16px;
      }
      .page-link {
        margin-right: .7rem;
      }
    } 
  }
}