.display-label {
  margin-bottom: 0;
  font-weight: 500;
  font-size: .8rem;
  text-transform: uppercase;
  color: #63666A;
}

.display-Label {
  color: #292931;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375rem;
  letter-spacing: 0;
  margin-bottom: 0;
  font-weight: 500;

}

.display-value {
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1rem;
}

.display-value-date {
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.0rem;

}

.display-valueref {
  color: #3e3e41;
  font-size: 1.06rem;
  font-weight: 550;
  letter-spacing: 0;
  line-height: 1.5625rem;
  word-break: break-word;
}

.display-label-tt {
  margin-bottom: 0;
  font-weight: 500;
  font-size: .8rem;
  text-transform: none;
  font-family: "Roboto", sans-serif;
  color: black;
  font-size: 0.81rem;
  letter-spacing: 0;
  line-height: 1rem;
}

.display-label-xlg {
  font-size: 1.90rem;
}

.display-grid-border {
  height: 75px;
  width: 6% !important;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #FAFAFA;
  line-height: 1.2;
  color: #313234;
  font-family: Lora;
  font-size: 1.25rem;
  letter-spacing: 0;
}

.pcp-detail-table {
  border-radius: 5px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.28);

  .border {
    border: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  }
}

.display-label-lg {
  font-size: 1.125rem;
}

.display-label-sm {
  font-size: 0.875rem;
}

.map-view {
  color: #313234;
  font-family: 'Roboto', sans-serif !important;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  vertical-align: text-bottom;
}

.search-results {
  color: #313234;
  font-family: Lora;
  font-size: 2.65rem;
  letter-spacing: 0;
  line-height: 3.5625rem;
  margin-right: 30px;
}

.pcp-pagination:hover {
  height: 25px;
  width: 25px;
  background-color: #117BAB;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.all-pc-ps-are {
  color: #292931;
  font-family: 'Roboto', sans-serif !important;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27px;
  vertical-align: middle;
}

.ln-network {
  font-family: 'Roboto', sans-serif !important;
  font-weight: bold;
  vertical-align: text-bottom;
}

.display-select-pcp {
  float: right;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  padding-top: 7px;
  padding-bottom: 6px;
  font-size: 12px;
}


.pcpoffslectcol {
  height: 31px;
  width: 75px;
  border-radius: 15px;
  background-color: #737373;
}

.pcponslectcol {
  color: #117BAB;
}

.pcptop {
  margin-top: 2rem;
}

.pcprowpad {
  padding-bottom: .75rem;
}

.confirm-primary-care {
  color: #313234;
  font-size: 1.5625rem;
  letter-spacing: 0;
  line-height: 33px;
}

.header_pcpSel {
  .close {
    margin-left: 3rem;
  }
}

.select-emilie-o-low {
  color: #292931;
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 24px;
}

.icons-close {
  height: 18px;
  width: 18px;
}

.hospital {
  height: 36.59px;
  width: auto;
}

.providence-medical-c {
  height: 16px;
  width: auto;
  color: #292931;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 13px;

  input[type=radio] {
    accent-color: $brand-color;
    transform: scale(1.3);
    top: 4px;
  }
}

.pcpmodal_location_name {
  height: 16px;
  width: 248px;
  color: #292931;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 16px;
}

.distance {
  height: 22px;
}

.icons-tab-bar-fc-a {
  height: 17px;
  width: 13px;
}

.est-location-miles-away {
  height: 22px;
  width: auto;
  color: #292931;
  font-family: Roboto;
  font-size: 0.9rem;
  letter-spacing: 0;
  line-height: 21.2px;
}

.accepting-new-patien {
  height: 16px;
  width: 221px;
  color: #292931;
  font-family: Roboto;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 16px;
}

.accepts-new {
  height: 16px;
  width: 241px;
}

.doesnt-old {
  height: 16.62px;
  width: 318px;
}

.molecules-inline-alert-warning {
  height: 14px;
  width: 14px;
}

.not-accepting-new-pa {
  height: 16px;
  width: 297.75px;
  color: #000000;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 16px;
}

.confirm-you-or-you {
  height: 16px;
  width: auto;
  color: #292931;
  font-family: Helvetica;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 18px;
}

.selectPcpMemberLabel {
  height: 27px;
  width: auto;
  color: #313234;
  font-family: Lora;
  font-size: 1.25rem;
  letter-spacing: 0;
  line-height: 27px;
}

.check-this-box {
  height: 27px;
  width: auto;
  color: #292931;
  font-size: 1.125rem !important;
  letter-spacing: -0.5px;
  line-height: 24px;
  text-transform: capitalize;
}

//start PCP SRC
.emilie-o-lowens-md {
  height: 33px;
  width: 95%;
  color: #313234;
  font-family: Lora;
  font-size: 1.5625rem;
  letter-spacing: 0;
  line-height: 33px;
}

.atoms-badge-small-pill {
  &.text {
    height: 1.525rem;
    width: 5.443125rem;
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    vertical-align: middle;
    padding: unset;
    border-radius: 12.5px;
    background-color: #117BAB;
    margin-left: 10px;
  }
}

//end PCP SRC
//Start Div
.group-18 {
  &.rectangle {
    float: right;
    box-sizing: border-box;
    height: 36px;
    width: 176px;
    border: 5px #82C341;
    border-radius: 5px;
    background-color: #82C341;
    font-size: 0.875rem;
    letter-spacing: 0.40px;
    font-weight: 600;
  }
}

//End Div

//View Details start
.select-view-details {
  height: 16px;
  width: 315px;
  color: #292931;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.contact_icon {
  margin-right: 1.5rem;
}

.pcp-subHead-locCont {
  height: 1.3125rem;
  width: 17.340625rem;
  color: #292931;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0;
}

.phone {
  color: #292931;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 100;
  text-decoration: underline;
}

.location {
  margin-top: -2%;
}

.pcpoffslectcol {
  height: 31px;
  width: 75px;
  border-radius: 15px;
  background-color: #737373;
}

.pcponslectcol {
  color: #117BAB;
}

.pcprowpad {
  padding-bottom: .75rem;
}

.selectPCPbtn {
  height: 36px;
  width: 100px;

  &.view-details {
    height: 36px;
    width: 100px;
    color: #000000;
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0.44px;
    line-height: 16px;
    text-align: center;
  }

  &.rectangle {
    float: right;
    box-sizing: border-box;
    height: 36px;
    width: 100px;
    border: 5px #82C341;
    border-radius: 5px;
    background-color: #82C341;
    margin-right: 8px;
  }

  &:disabled {
    background: #999;
    color: #555;
    cursor: not-allowed;
  }

}

.referralpadding {
  padding-bottom: 0.93rem;
}

.referral-padding-div {
  padding-bottom: 0rem;
}

.referral-start-date {
  color: #292931;
  font-size: 1.0625rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.375rem;

}

.display-guide {
  display: none;
}

.display-experience {
  display: inline;
}

.pcpCardMemeberName {
  text-transform: capitalize;
}

.patient-notify-mobile {
  display: none;
}

.priorauth-label-tt {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0;
  line-height: 16px;
}

.priorauthdetailpadding {
  padding-bottom: 25px;
}

.priorauthdetail-value {
  color: #000000;
  font-size: 1rem;
  font-weight: 550;
  letter-spacing: 0;
  line-height: 21px;
}

.denied-services-ser {
  color: #292931;
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 21px;
}


.priorauth-label-tt {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0;
  line-height: 16px;
}

.priorauthdetailpadding {
  padding-bottom: 25px;
}

.priorauthdetail-value {
  color: #292931;
  font-size: 1rem;
  font-weight: 550;
  letter-spacing: 0;
  line-height: 21px;
}

.denied-services-ser {
  color: #292931;
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 21px;
}
@media screen and (max-width:750px) {
  #pcpDoctorInfo {
    .pcp-block {
      display: block;

      .atoms-badge-small-pill {
        &.text {
          margin-left: unset;
        }
      }
    }

    .message-for-patients {
      display: none;
    }

    .patient-notify-mobile {
      display: block;
    }

    .goto-pcp-mobile {
      text-align: center;
    }

    .group-18 {
      &.rectangle {
        margin: 10px 0px 0px 0px;
        float: none;
      }
    }

    @media screen and (max-width:400px) {
      .goto-pcp-mobile {
        text-align: unset;

        .group-18 {
          &.rectangle {
            margin: 10px 0px 0px 20px;
          }
        }
      }

    }
  }
}

@media screen and (max-width:710px) {
  .display-guide {
    display: inline;
  }

  .display-experience {
    display: none;
  }
}

.pcp-badge {
  vertical-align: top;
  position: relative;
  top: 9px;
}

.pcp-innetwork {
  vertical-align: text-bottom;
  position: relative;
  top: 4px;
  border-radius: 2px;
  font-size: 1.3125rem;
}

.pcp-name {
  font-size: 1.5625rem;
}

.message-for-patients {
  text-align: right;
  float: right;
  font-size: 1.125rem;
}

.message-for-termination-of-pcp {
  color: #242424;
  background-color: #fff3f5;
  background-size: 30%;
  text-align: center;
  transform: translate(0, -50%);
  padding: 5px;
  position: relative;
  top: 50%;
}

@media screen and (max-width:400px) {
  #pcpSubSection pbc-pcp-search {
    #pcpSearchCriteriaSection.cls-nav-tabs .nav-tabs .nav-item {
      padding: unset;
      margin-right: 0.5rem;
    }
  }
}

.pcp-assigned-to {
  vertical-align: text-bottom;
  font-weight: bold;
}

.pcp-filter {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media screen and (min-width:350px) and (max-width:750px) {
  @media screen and (min-width:350px) and (max-width:378px) {
    .map-view-mobile {
      display: block;
      margin-top: 10px;
    }
  }

  @media screen and (min-width:400px) and (max-width:750px) {
    .ln-network-mobile {
      display: block;
      margin-top: 10px;
    }
  }

  .pcp-filter {
    padding: unset;

    #toggle-filter-button {
      padding: 4px;
      margin: unset;
    }
  }
}

@media screen and (min-width:800px) and (max-width:919px) {
  .map-view-mobile {
    display: block;
    margin-top: 10px;
    margin-left: -25px;
  }
}

@media screen and (min-width:1024px) {
  .map-view-mobile {
    margin-left: -27px;
  }
}

@media screen and (max-width:767px) {
  .pagi-mobile {
    display: flex !important;
  }

  @media screen and (max-width:500px) {
    .pcp-results {
      width: 26%;
    }
  }

  .bing-maps-mobile {
    margin-top: 40px;
  }

  .select-pcp-mobile {
    text-align: center;

    .float-right {
      float: unset !important;
    }
  }
}

@media screen and (max-width:920px) {
  .pcpblock-mobile {
    display: block;

    .pcp-badge {
      margin-left: unset !important;
    }
  }

  @media screen and (min-width:768px) and (max-width:860px) {
    .select-pcp-mobile {
      .float-right {
        padding: 10px 15px 10px 15px !important;
      }
    }
  }
}

.pcp-results {
  font-size: 1rem;
}

.buttonn-text {
  color: #469fc7;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0313rem;
  line-height: 1.875rem;
  margin-top: -1.125rem;

}

.fa-chevron-left:before {
  margin-right: 1.125rem;
  color: #F24B43;

}

.emptyhypen {
  height: 1.687rem;
  width: 36rem;
  color: #292931;
  font-family: Roboto;
  font-size: 1.45rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.687rem;
}

.horizontal-line {
  height: 0.125rem;
  width: 80%;
  border-color: #979797;
  padding: 0.125rem 0rem 0.125rem 0rem
}

.green-check {
  color: #2A8703;
  font-size: 13px;
}

.yellow-check {
  color: #ec7c0d;
  font-size: 15px;
}

@media screen and (max-width:400px) {
  .colsm-for-mobile {
    flex: 0 0 83.33333333% !important;
    max-width: 83.33333333% !important;
  }
}

.welcome-to-your-hmoplan {
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;

  a {
    color: #117BAB;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0;
  }
}

#preapprovals-chevron-left .fa-chevron-left:before {
  margin-right: 0.325rem;
  color: #F24B43;
}
ngb-modal-backdrop{
  z-index: 1050 !important;
}