ngb-datepicker {
  &.dropdown-menu {
    border: none;
    box-shadow: $default-drop-shadow;
    padding: 1rem !important;
  }

  .ngb-dp-header {
    background: #fff !important;
  }

  .ngb-dp-navigation-chevron {
    color: lighten($color: $neutral-color-dark, $amount: 20%);
  }

  .ngb-dp-navigation-select {
    display: inline;
    -ms-flex: unset;
    flex: unset;
}

  .custom-select {
    width: auto;
    border: none;
    background: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 0;
    font-weight: 500;
}

  .ngb-dp-week.ngb-dp-weekdays {
    background: #fff !important;
    border-bottom: none;
    margin: 1rem 0 .5rem 0;
  }

  .ngb-dp-week-number, .ngb-dp-weekday {
    font-style: normal !important;
    visibility: hidden;
    margin: 0.5rem !important;
    color: #222529;
    font-weight: 600;

    &::first-letter {
      visibility: visible;
    }
  }

  .ngb-dp-day {
    font-size: .8rem;
    margin: 0.5rem !important;
    font-weight: 600;
    color: $primary-color !important;

    [ngbdatepickerdayview] {
      border-radius: 1rem;
      color: $primary-color;
    }
  }

}

[ngbDatepickerDayView].outside {
  opacity: 1 !important;
  font-weight: 400;
}
