.quote {
  margin-bottom: 3rem;
  padding: 6rem 2rem;
  @include media-breakpoint-down(xs) {
    padding: 2rem;
  }
  border: 9px solid $primary-color;

  * {
    text-align: center;
  }

  .h1 {
    font-size: 3.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .h3 {
    margin-bottom: 1.3rem;
  }


  .row {
    margin-top: 3rem;
  }

  div[class*='col'] {
    padding-bottom: 4rem;
  }


  .carousel {

    .carousel-caption {
      background: url('https://designpatternlibrary.premera.com/assets/image/quote_mark_blue.svg') no-repeat 12% 10%;
      padding: 1rem 8rem 3rem 12rem;
      @include media-breakpoint-down(sm) {
        padding: 1rem 3rem 3rem 5rem;
        background-position-x: 3%;
        background-position-y: 10%;
        background-size: 5% auto;
      }
      @include media-breakpoint-down(xs) {
        padding: 1rem 3rem 3rem 5rem;
        background-position-x: 5%;
        background-position-y: 6%;
        background-size: 8% auto;
      }

      p:last-child {
        margin-bottom: 5rem;
      }
    }
  }

  .quote-content {
    background: url('https://designpatternlibrary.premera.com/assets/image/quote_mark_blue.svg') no-repeat 12% 16%;
    padding: 1rem 8rem 3rem 12rem;
    @include media-breakpoint-down(sm) {
      padding: 1rem 3rem 3rem 5rem;
      background-position-x: 3%;
      background-position-y: 14%;
      background-size: 5% auto;
    }
    @include media-breakpoint-down(xs) {
      padding: 1rem 3rem 3rem 5rem;
      background-position-x: 5%;
      background-position-y: 9%;
      background-size: 8% auto;
    }
  }

  &.background-primary {
    border: none;

    * {
      color: #fff;
    }

    .carousel {
      ol.carousel-indicators li {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: 1px solid #fff;
        background-color: $primary-color !important;

       &.active {
          background-color: #FFFFFF !important;
        }
      }

      .carousel-control-prev, .carousel-control-next {
        color: #FFF !important;
      }

      .carousel-control-prev-icon {
        fill: #fff;
    }

    .carousel-caption {
      background: url('https://designpatternlibrary.premera.com/assets/image/quote_mark_wt.svg') no-repeat 12% 0%;
      @include media-breakpoint-down(sm) {
        padding: 1rem 3rem 3rem 5rem;
        background-position-x: 3%;
        background-position-y: 10%;
        background-size: 5% auto;
      }
      @include media-breakpoint-down(xs) {
        padding: 1rem 3rem 3rem 5rem;
        background-position-x: 5%;
        background-position-y: 5%;
        background-size: 8% auto;
      }
    }

    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
    }

    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
      color: #fff !important;
    }
  }


    .quote-content {
      background: url('https://designpatternlibrary.premera.com/assets/image/quote_mark_wt.svg') no-repeat 12% 0%;
      @include media-breakpoint-down(sm) {
        padding: 1rem 3rem 3rem 5rem;
        background-position-x: 3%;
        background-position-y: 18%;
        background-size: 5% auto;
      }
      @include media-breakpoint-down(xs) {
        padding: 1rem 3rem 3rem 5rem;
        background-position-x: 5%;
        background-position-y: 9%;
        background-size: 8% auto;
      }
    }
  }
}


// background: url('https://www.premera.com/images/premera-web/footer-bg.png') bottom center;
