.btn-group {
  box-shadow: $default-drop-shadow;
  border-radius: $button-border-radius;

  .btn {
    margin-right: 0;
  }
}

.btn-group * {
  box-shadow: none;
}
