.fade-border {
  transition: all 0.6s ease-in-out;
  border: solid 100px white;
  position: absolute;
  top:0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.fade-border.in {
  border: solid 0px white;
}

.come {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity .4s, transform .4s cubic-bezier(0, 0, .58, 1);
}

.in {
  opacity: 1;
  transform: translateY(0);
}
