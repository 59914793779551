.text-primary {
  color: $primary-color;
}

.text-secondary,.secondary-color {
  color: $secondary-font-color;
}

.text-tertiary {
  color : $tertiary-color;
}

.text-tertiary1 {
  color: $tertiary1-color;
}

.text-accent { //use with care in regards to accessibility
  color: darken($brand-color, 10%);
}

.text-accent-secondary { //use with care in regards to accessibility
  color: $accent-secondary-color;
}

.text-accent-tertiary {
  color: $accent-tertiary-color;
}

.default-color {
  color: $default-font-color;
}

.brand-color {
  color: $brand-color;
}

.primary-color {
  color: $primary-color;
}

.secondary-color {
  color: $secondary-color;
}

.tertiary-color {
  color: $tertiary-color;
}

.display-one {
  font-size: $display1-size;
}
.pcp-backto-result {
  font-size: 0.875rem;
  .fa-chevron-left::before {
    color:$bg-hover;
    font-size:0.8125rem;
    margin-right : unset;
  }
}
