.radioInput{
  color: #292931;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

h7{
  color: #313234;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.questions-label{
  color: #313234;
  font-family: Lora;
  font-size: 1.758rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.063rem;
  
}

.vision-form-check-label{
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.provider-input{
  display: flex;
  width: 45%;
  height: 49px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid var(--neutral-gray-8-icons, #1F1F1F);
  background: var(--neutral-white, #FFF);
}

.floating-label{
  position: absolute;
  top: 0;
  left: 10px;
  pointer-events: none;
  transition: 0.2 ease-out;
  color: #666;
}

#provider:focus ~ .floating-label, input:valid ~ .floating-label{
  transform: translate(0, -18px) scale(0.8);
  color: #000 ;
}

.input-border{
  border-radius: 4px;
  border: 0.5px solid;
  border-right: 2px solid;
  background: var(--neutral-white, #FFF);
  width: 45%;
}

.oneProviderLabel{
  color: #63666A;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sales-shipping-label{
  color: var(--premera-neutral-2, #404144);
  font-feature-settings: 'clig' off, 'liga' off;
  //font-family: SF Pro Display;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.5rem;
  margin-bottom: 1.2rem;
}

#member{
  width: 45%;
}

@include media-breakpoint-down(sm) {
  .input-border{
    width: 70%;
  }

  #member{
    width: 70%;
  }

  .provider-input{
    width: 70%;
  }
} 

