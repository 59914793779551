.card {
  background: #fff;
  border: none;
  border-radius: $border-radius;
  box-shadow: $default-drop-shadow;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;

  &.icon-card {
    height: 100% !important;

    &.border-Style {
      box-sizing: border-box;
      border: 1px solid #C0BDB1;
      background-color: #FFFFFF;
      box-shadow: 0 0 10px 0 #D2CFC5;
    }

    .loading {
      position: relative;

      h4 {
        height: 1.5rem;
        width: 75%;
        background-color: #E2E2E2;
      }

      .card-img {
        height: 4rem;
        width: 4rem;
        margin-top: -.5rem;
        margin-left: -.5rem;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+50,ffffff+50,ffffff+100 */
        background: rgb(0, 0, 0);
        /* Old browsers */
        background: -moz-radial-gradient(center, ellipse cover, #E2E2E2 0%, #E2E2E2 50%, #FFFFFF 50%, #FFFFFF 100%);
        /* FF3.6-15 */
        background: -webkit-radial-gradient(center, ellipse cover, #E2E2E2 0%, #E2E2E2 50%, #FFFFFF 50%, #FFFFFF 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: radial-gradient(ellipse at center, #E2E2E2 0%, #E2E2E2 50%, #FFFFFF 50%, #FFFFFF 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#ffffff', GradientType=1);
        /* IE6-9 fallback on horizontal gradient */
      }

      .media-body {
        height: 4rem;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#E2E2E2+0,E2E2E2+25,ffffff+25,ffffff+25,ffffff+50,E2E2E2+50,E2E2E2+75,ffffff+75 */
        background: #E2E2E2;
        /* Old browsers */
        background: -moz-linear-gradient(top, #E2E2E2 0%, #E2E2E2 25%, #ffffff 25%, #ffffff 25%, #ffffff 50%, #E2E2E2 50%, #E2E2E2 75%, #ffffff 75%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #E2E2E2 0%, #E2E2E2 25%, #ffffff 25%, #ffffff 25%, #ffffff 50%, #E2E2E2 50%, #E2E2E2 75%, #ffffff 75%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #E2E2E2 0%, #E2E2E2 25%, #ffffff 25%, #ffffff 25%, #ffffff 50%, #E2E2E2 50%, #E2E2E2 75%, #ffffff 75%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#E2E2E2', endColorstr='#ffffff', GradientType=0);
        /* IE6-9 */
      }

      #cardCTA {
        display: block;
        background: #e2e2e2;
        width: 4rem;
        height: 1rem;
        margin-top: 0;
      }
    }

    @keyframes loading {
      100% {
        transform: translateX(100%);
      }
    }

    .media,
    .notification {
      margin-top: 1rem;
    }

    img {
      margin-right: 1rem;
    }

    h3 {
      margin-top: 0;
    }

    #cardCTA {
      margin: 1rem 0 0 0;
    }

    #cardAlert {
      margin-top: 1.5rem;
    }
  }

  &.card-promo .card-body {
    padding: 2rem;
  }

  &.card-secondary {
    border: 1px solid $neutral-color-light;
    box-shadow: none;
  }
}

.card-secondary {
  box-shadow: none;
  border: 1px solid $neutral-color-light ;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.card.horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.card.horizontal.small .card-image,
.card.horizontal.medium .card-image,
.card.horizontal.large .card-image {
  height: 100%;
  max-height: none;
  overflow: visible;
}

.card.horizontal.small .card-image img,
.card.horizontal.medium .card-image img,
.card.horizontal.large .card-image img {
  height: 100%;
}

.card.horizontal .card-image {
  max-width: 50%;
}

.card.horizontal .card-image img {
  border-radius: 2px 0 0 $border-radius;
  max-width: 100%;
  width: auto;
}

.card.horizontal .card-stacked {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
}

.card.horizontal .card-stacked .card-content {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.card.card-promo:first-child:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 0.3rem;
  background: linear-gradient(to left, $accent-color, $brand-color);
  top: 0;
  left: 0;
}

.card-title {
  color: #313234;
  font-family: Lora;
  font-size: 1.31rem;
  letter-spacing: 0;
  margin-bottom: 0.01rem;
}

.location-detail-style {
  color: #292931;
  font-size: 1.1rem;
}

.bottom-style {
  position: absolute;
  bottom: 2rem;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
}

.card-row {
  .card {
    height: 100%;
    color: #292931 !important;
  }
}

.text-color {
  color: #266726;

  &:visited {
    color: #266726;
  }

  &:active,
  &:hover {
    color: #fff;
  }
}

#pcpCardContainer{
  .card {
    .card-body{
      .media {
        a.btn-outline-success {
          background: transparent;
          border    : 0.125rem solid $secondary1-color;
          color     : #266726!important;

          &.btn-sm {
            padding  : .25rem 1rem;
            font-size: .75rem;
          }
          
          &:focus {
            outline: 2px solid $secondary1-color;
            outline-offset: 2px;
            box-shadow: none;
          }

          &:hover {
            color       : darken($secondary1-color, 3%);
            border-color: $secondary1-color;
            background  : transparent;
            box-shadow  : $default-drop-shadow;
            // box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.5);
            box-shadow: 0px 2px 4px 0 #000000;
          }

          &.pressed {
            box-shadow  : none;
            color       : #000;
            border-color: #000;
          }

            &:active,
            &:visited {
              box-shadow: none;
            }

          &:disabled,
          &.disabled {
            background  : $disabled-bg;
            border-color: $disabled-bg;
            box-shadow  : none;
            &:hover{
              background  : $disabled-bg;
              border-color: $disabled-bg;
              box-shadow  : none;
              color       : #fff;
            }
          }
          &.alternate {
            background  : transparent !important;
            border-color: #ffffff;
            color       : #ffffff;

            &:hover {
              background: $neutral-color-light !important;
              color     : darken($secondary1-color, 3%) !important;
            }
          }
        }
        a.btn-success{
          background:  $primary1-color;
          border-color:  $primary1-color;
          color: #1F1F1F !important;
          font-weight: 500!important;
          text &:hover {
            box-shadow: 0px 2px 4px 0 #000000;
            background-color: $primary1-color;
          }

          &:hover {
            box-shadow: 0px 2px 4px 0 #000000;
            background-color: $primary1-color;
          }

          &:focus {
            outline: 2px solid $primary1-color;
            outline-offset: 2px;
            box-shadow: none;
          }

          &:active,
          &:visited {
            box-shadow: none;
            background-color: $primary1-color !important;
            color: #fff;
          }

          &.alternate {
            background: #fff;
            color: $primary1-color !important;

            &:hover {
              color: $primary1-color;
              border-color: #fff;
            }

            &:active,
            &:visited {
              box-shadow: none;
              background-color: $primary1-color;
              color: #fff !important;
            }
          }

          &:disabled,
          &.disabled {
            background: $disabled-bg;
            border-color: $disabled-bg;
            box-shadow: none;

            &:hover {
              background: $disabled-bg;
              border-color: $disabled-bg;
              box-shadow: none;

            }
          } 
        }
      }
    }
  }
}

@media screen and (max-width:435px) {
  #pcpCardContainer{
    .card {
      &.responsive-card-height{
        min-height: 22rem !important;
      }
      .card-body{
        .media {
          max-width: 95%;
          a.btn-outline-success {
            width:10rem;
          }
          a.btn-success{
            width: 10rem;
            margin-left: 1rem;
            margin-top: 0.5rem;
          }
          .detailsCta-style{
            margin-right: 4rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width:436px) and (max-width:450px) {
  #pcpCardContainer{
    .card {
      &.responsive-card-height{
        min-height: 18rem !important;
      }
      .card-body{
        .media {
          max-width: 95%;
          a.btn-outline-success {
            width:10rem;
            background: transparent;
            border    : 0.125rem solid $secondary1-color;
            color     : #000!important;
            &:hover{
              font-weight: bold;
            }
          }
          a.btn-success{
            width: 10rem;
            margin-left: 1rem;
            margin-top: 0.5rem;
          }
          .detailsCta-style{
            margin-right: 4rem;
          }
        }
      }
    }
  }
}
@media screen and (min-width:451px) and (max-width:650px) {
  #pcpCardContainer{
    .card {
      &.responsive-card-height{
        min-height: 17rem !important;
      }
      .card-body{
        .media {
          max-width: 95%;
          a.btn-outline-success {
            width:10rem;
          }
          a.btn-success{
            width: 10rem;
          }
          
        }
      }
    }
  }
}
@media screen and (min-width:651px) and (max-width:767px) {
  #pcpCardContainer{
    .card {
      &.responsive-card-height{
        min-height: 16rem !important;
      }
      .card-body{
        .media {
          max-width: 95%;
          a.btn-outline-success {
            width:10rem;
          }
          a.btn-success{
            width: 10rem;
          }
          .detailsCta-style{
            margin-right: 4rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width:768px) and (max-width:986px) {
  #pcpCardContainer{
    .card {
      &.responsive-card-height{
        min-height: 20rem !important;
      }
      .card-body{
        .media {
          max-width: 95%;
          a.btn-outline-success {
            width:10rem;
          }
          a.btn-success{
            width: 10rem;
            margin-left: 1rem;
            margin-top: 0.5rem;
          }
          .detailsCta-style{
            margin-right: 4rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width:987px) and (max-width:1023px) {
  #pcpCardContainer{
    .card {
      &.responsive-card-height{
        min-height: 16rem !important;
      }
      .card-body{
        .media {
          max-width: 95%;
          a.btn-outline-success {
            width:10rem;           
          }
          a.btn-success{
            width: 10rem;
          }
          .detailsCta-style{
            margin-right: 4rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width:1024px) and (max-width:1188px) {
  #pcpCardContainer {
    .card{
      &.responsive-card-height{
        min-height: 20rem !important;
      }
      .card-body{
        .media{
          a.btn-success {
            width: 10rem;
            margin-left: 1rem;
            margin-top: 0.5rem;
          }
          a.btn-outline-success {
            width: 10rem;            
          }
          
        }
      }
    }
  }
}

@media screen and (min-width:1089px) and (max-width:1600px) {
  #pcpCardContainer {
    .card{
      .card-body {
        .media{
          a.btn-success {
            width: 10rem;
          }
          a.btn-outline-success {
            width: 10rem;            
          }
          .detailsCta-style{
            margin-right: 4rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width:1600px){
  #pcpCardContainer {
    .card{
      .card-body {
        .media{
          a.btn-success {
            width: 12rem;
          }
          .detailsCta-style{
            padding-left: 0;
            padding-right: 2.2rem;
          }
          a.btn-outline-success {
            width: 12rem;           
          }
        }
      }
    }
  }
}

.card-main {
  box-sizing: border-box;
  border-radius: 3px;

  .select.compact::after {
    top: 0rem;
    right: 0.6rem;
  }

  .idcard-pcp-name {
    color: #292931;
    font-size: 0.9rem;
    text-align: center;
  }

  .three-dot {
    height: 2.4rem;
    border: 1px solid #0099d8;
    width: 3.125rem;
    border-radius: 3px;
  }

  #cardPopover {
    .id-card {
      border: 2px solid #aeadade3;
      box-shadow: unset;
      border-radius: 5px;
      padding: 0px;
      margin: 0px;

      img {
        margin: unset;
      }
    }

    .card-front-back {
      color: #292931;
      font-size: 0.9rem;
      line-height: 16px;

      a {
        font-weight: 600;
      }
    }
  }
}

.icard-heading {
  color: #313234;
  font-size: 1.0625rem;
  font-weight: bold;
}

@media screen and (min-width:768px) and (max-width:1040px) {
  pbc-card {
    .cls-mobile {
      .cls-height-mobile {
        height: 200px;
      }
      .bottom-style {
        position: unset;
      }
      @media screen and (min-width:987px) and (max-width:1023px) {
        .bottom-style {
          position: absolute;
        }
      }
    }
  }
}
@media screen and (min-width:1041px) and (max-width:1200px) {
  pbc-card {
    .cls-mobile {
      .cls-height-mobile {
        height: 290px;
        .location-detail-style {
          margin-right:1rem !important;
        }
      }
    }
  }
}
#futureHmoIdCard {
  .card {
    box-shadow: none;
  }
  .text-error {
    color: #0078d4;
    font-size: 1.8rem;
  }
  .cls-futureHmo {
    font-size: 1.1rem;
  }
}