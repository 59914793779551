.border-light {
  border: 1px solid darken($color: $neutral-color-light, $amount: 0%) !important;
}

.border-medium {
  border: 1px solid $neutral-color-medium;
}

.border-dark {
  border: 1px solid $neutral-color-dark;
}

.border-light-bottom {
  border-bottom: 1px solid darken($color: $neutral-color-light, $amount: 0%) !important;
}

.border-medium-bottom {
  border-bottom: 1px solid $neutral-color-medium;
}

.border-dark-bottom {
  border-bottom: 1px solid $neutral-color-dark;
}

.border-light-top {
  border-top: 1px solid darken($color: $neutral-color-light, $amount: 0%) !important;
}

.border-medium-top {
  border-top: 1px solid $neutral-color-medium;
}

.border-dark-top {
  border-top: 1px solid $neutral-color-dark;
}

.border-light-left {
  border-left: 1px solid darken($color: $neutral-color-light, $amount: 0%) !important;
}

.border-medium-left {
  border-left: 1px solid $neutral-color-medium;
}

.border-dark-left {
  border-left: 1px solid $neutral-color-dark;
}

.border-light-right {
  border-right: 1px solid darken($color: $neutral-color-light, $amount: 0%) !important;
}

.border-medium-right {
  border-right: 1px solid $neutral-color-medium;
}

.border-dark-right {
  border-right: 1px solid $neutral-color-dark;
}

.border-gradient {
  border: 5px solid $brand-color;
  -o-border-image: linear-gradient(to left, $brand-color 50%, $accent-secondary-color, 75%, $accent-color);
  border-image: linear-gradient(to left, $brand-color 50%, $accent-secondary-color, 75%, $accent-color);
  border-image-slice: 1;
}

.border-blue-box {
  border: 4px solid #7BCBED; 
  border-radius: 17px; 
  border-left: 12px solid #7BCBED;
}

.border-circle-black{
  border:1px solid #1F1F1F; 
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  height: 26px;
  width: 26px;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
}
