.background-primary {
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 15 24'%3E%3Cpath d='M14.8854 2.124L12.7614 0L0.761375 12L12.7614 24L14.8854 21.876L5.00938 12L14.8854 2.124Z'/%3E%3C/svg%3E");
    width: 14.12px;
    color: #FFFFFF;
    position: absolute;
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 15 24'%3E%3Cpath d='M-0.000244141 21.876L2.12376 24L14.1238 12L2.12376 0L-0.000244141 2.124L9.87576 12L-0.000244141 21.876Z'/%3E%3C/svg%3E");
    position: absolute;
    right: 0.5rem;
    color: #FFFFFF;
  }

  ol.carousel-indicators {
    .button{
      box-sizing: border-box;
      height: 600px;
      width: 800px;
      border: 1px solid #FFFFFF;
      border-radius: 100px;
      background-color: #dfe6e9;
      box-shadow: 0 4px 10px 0 rgba(0,0,0,0.5);
    }
    li.active {
      border-color: #fff;
    }
  }

  @include media-breakpoint-down (sm) {
    .carousel-control-prev, .carousel-control-next {
      background-color: $primary-color;
      opacity: 1;
    }
  }
}

.carousel-modal-header{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #004053;
  border: none;
  border-radius: 0.625rem;
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 1.50rem;
  padding-top: 1.25rem;
  button{
    background: none;
    border: none;
    font-weight: bold;
    height: 24px;
    width: 23.97px;
    padding: 0.063rem;
    color: #FFFFFF;
    outline: none;
  }
  
}

ol.carousel-indicators {
  li {
    width: 10px;
    height: 10px;
    background-color: $neutral-color-light;
    margin-right: .75rem;
    border-radius: 100%;
    margin-left: 0rem;
    border: 1px solid #000000;
  }

  li.active {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}

.carousel-item {
  /* Force to always be block */
  display: block !important;
  z-index: 1;
  position: absolute;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  margin-bottom: 3rem;

  & > * {
    width: 100%;
  }

  &.active {
    position: relative;
    visibility: visible;
    opacity: 1;
  }
}

.carousel-content{
  background-color: #004053;
  border: none;
}

h1::after{
  position: relative;
  height: 0px;
  width: 0px;
}

h1{
  margin: 0;
}

.myCustomModalClass .modal-dialog {
  min-height: 37.5 !important;
  max-height: 37.5 !important;
  min-width: 50rem;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-self: center;
  align-content: center;
}

.carousel-modal-content{
  min-height: 37.5 !important;
  max-height: 37.5 !important;
  min-width: 50rem;
  background-color: #004053;
  border: 0.12rem solid #FFFFFF;
  border-radius: 0.625rem;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.5);
}

.premera-logo{
  max-width: 100% ;
  height: auto;
}

.header-para{
  height: 3.25rem;
  color: #FFFFFF;
  font-family: Lora;
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 3.84rem;
  text-align: center;
  margin-top: 2.5rem;
}

.carousel-item-frame {
  padding: 0.75rem 0;
  border-radius: $card-border-radius;
  background-color: #004053;
  text-align: center;

  @include media-breakpoint-down (xs) {
    padding: 0;
  }

  .image-gradient{
    text-align: center;
    background-image: url('/assets/image/gradientbar-pluses-green-1.svg');
    background-repeat: repeat-x;
    background-size: 40% 100%;
  }

  img {
    width: 31.25rem;
    height: 15.831rem;
  }
}

.clsSubHeader {
  height: 51px;
  width: 100%;
  color: #FFFFFF;
  font-family: Lora;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 51.2px;
  text-align: center;
}

.hmo-welcome-link {
  height: 51px;
  width: 78%;
  color: #0099D8;
  font-size: 2.938rem;
  letter-spacing: 0;
  line-height: 51.2px;
  text-align: center;
  margin: auto;
  a{
    color: #0099D8;
    text-decoration: none;
    font-weight: 600;
    font-family: Lora;
    text-wrap: nowrap;
  }
}

.clsBody {
  height: 52px;
  //width: 78%;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 23.44px;
  text-align: center;
  margin: auto;
  p{
    margin-top: 0;
    margin-bottom: 0;
    text-wrap: nowrap;
  }
}

.section-link{
  display: block;
  text-align: center;
  position: absolute;
  height: 25rem;
  width: 100%;
  a{
    height: 21px;
    width: 155px;
    color: #0099D8;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21.48px;
    text-decoration: none;
  }
}

.referral-arrow-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(130, 195, 65)' viewBox='0 0 15 24'%3E%3Cpath d='M-0.000244141 21.876L2.12376 24L14.1238 12L2.12376 0L-0.000244141 2.124L9.87576 12L-0.000244141 21.876Z'/%3E%3C/svg%3E") !important;
  display: inline-block;
  height: 12px;
  width: 20px;
  margin-left: 0.1rem;
  background: 50%/100% 100% no-repeat;
}

.carousel-caption {
  position: relative;
  right: auto;
  bottom: auto;
  top: auto;
  left: auto;
  z-index: 10;
  
  padding: 1rem 8rem 3rem 8rem;
  @include media-breakpoint-down(xs) {
    padding: 1rem 2rem 3rem 2rem;
  }
  color: black;
  text-align: center;
}

.carousel-indicators {
  top: 32rem;
  button{
    height: 0.625rem;
    width: 0.563rem;
    background-size: 100%;
    background-color: #FFFFFF;
    border-radius: 100%;
    margin: 4px;
    border: none;
    cursor: not-allowed;
    pointer-events: none;
  }

  .active{
    background-color: #0099D8;
  }
}

.carousel-control-prev, .carousel-control-next {
  width: 4%;
  z-index: 1;
  opacity: 1;
  top: 32.5rem;
  font-style: normal;
}

.carousel-control-prev{
  left: 4rem;
}

.carousel-control-next{
  right: 4rem;
}

@include media-breakpoint-up (sm) {
  .carousel-control-prev {
    margin-left: -3rem;
  }

  .carousel-control-next {
    margin-right: -3rem;
  }
}

.carousel-control-prev-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 15 24'%3E%3Cpath d='M14.8854 2.124L12.7614 0L0.761375 12L12.7614 24L14.8854 21.876L5.00938 12L14.8854 2.124Z'/%3E%3C/svg%3E");
} 

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 15 24'%3E%3Cpath d='M-0.000244141 21.876L2.12376 24L14.1238 12L2.12376 0L-0.000244141 2.124L9.87576 12L-0.000244141 21.876Z'/%3E%3C/svg%3E");
}

@media screen and (min-width : 2799px) {
  .myCustomModalClass .modal-dialog {
    margin-top: 3rem;
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
    width: 55rem;
  }

  .carousel-modal-content{
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
  }

  pbc-carousel{
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
  }
}

@include media-breakpoint-down(xl) {
  .myCustomModalClass .modal-dialog {
    margin-top: 3rem;
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
    width: 55rem;
  }

  .carousel-modal-content{
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
  }

  pbc-carousel{
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
  }
}

@include media-breakpoint-down(lg) {
  .myCustomModalClass .modal-dialog {
    margin-top: 3rem;
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
    width: 55rem;
  }

  .carousel-modal-content{
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
  }

  pbc-carousel{
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
  }
}

@include media-breakpoint-down(md) {
  .myCustomModalClass .modal-dialog {
    margin-top: 3rem;
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
    width: 55rem;
  }

  .carousel-modal-content{
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
  }

  pbc-carousel{
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
  }
}

@include media-breakpoint-down(sm) {
  .myCustomModalClass .modal-dialog{
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
    min-width: 45rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: relative;
  }

  .carousel-modal-content{
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
    min-width: 45rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  pbc-carousel{
    max-height: 37.5rem !important;
    min-height: 37.5rem !important;
    width: 100%;
  }

  .header-para{
    margin-top: 1.5rem;
  }

  .carousel-control-next {
    right: 4rem;
    top: 32.5rem;
  }

  .carousel-control-prev {
    left: 4rem;
    top: 32.5rem;
  }

  .carousel-indicators {
    top: 32rem;
  }

  .header-para{
    margin-top: 0;
  }

  .clsbody{
    width: auto;
  }
}

@include media-breakpoint-down(xs) {
  .myCustomModalClass .modal-dialog{
    max-height: 39rem !important;
    min-height: 39rem !important;
    min-width: 40rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }

  .carousel-modal-content{
    max-height: 39rem !important;
    min-height: 39rem !important;
    min-width: 40rem;
    border: none;
    box-shadow: none;
    background-color: transparent;
    border: none;
  }

  pbc-carousel{
    margin-bottom: 0.375rem;
    max-height: 39rem !important;
    min-height: 39rem !important;
    width: 35.5rem;
    background-color: #004053;
    border: 0.12rem solid #FFFFFF;
    border-radius: 0.625rem;
  }

  .carousel-item-frame {
    display: inline-block;
    .image-gradient{
      background-image: none;
      background-color: transparent;
    }

    img{
      background-color: #004053 !important;
      height: 80%;
      width: 80%;
      position: relative;
      z-index: 5;
    }
  }
  
  .background-gradient-img{
    width: 100%;
    height: 25rem;
    position: absolute;
    z-index: 2;
    top: 20rem;
    background-image: url('/assets/image/gradientbar-pluses-green-1.svg');
    background-repeat:repeat-x;
  }

  .premera-logo{
    max-width: 80%;
    height: auto;
  }

  .section-body{
    display: block;
    //position: absolute;
    height: 8rem;
    width: 100%;
    bottom: -23rem;
    text-wrap: wrap;
  }

  .carousel-control-next {
    right: 1.5rem;
    top: 33.5rem;
  }

  .carousel-control-prev {
    left: 1.5rem;
    top: 33.5rem;
  }

  .carousel-indicators {
    top: 33rem;
    button{
      height: 0.6rem;
    }
  }

  .clsSubHeader{
    height: 35px;
    width: 303px;
    color: #FFFFFF;
    font-family: Lora;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 35.84px;
    text-align: center;
    display: inline-block;
  }

  .clsBody{
    width: 78%;
    p{
      text-wrap: wrap;
    }
  }

  .hmo-welcome-link {
    font-size: 2.3rem;
    a{
      text-wrap: nowrap;
    }
  }
}
@media screen and (min-width:300px) and (max-width:510px) {
  .modal-dialog{
    max-height: 38rem !important;
    min-height: 38rem !important;
    width: 350px;
    min-width: 350px !important;
    margin-top: 1rem !important;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    align-self: flex-start !important;
  }

  .carousel-modal-content{
    max-height: 38rem !important;
    min-height: 38rem !important;
    min-width: 350px !important;
    width: 350px;
    border: none;
    box-shadow: none;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  pbc-carousel{
    max-height: 38rem !important;
    min-height: 38rem !important;
    min-width: 350px !important;
    width: 350px !important;
    background-color: #004053;
    border: 0.12rem solid #FFFFFF;
    border-radius: 0.625rem;
    right: 5rem;
    align-self: flex-start !important;
    margin-left: 18px;
  }

  .section-body{
    bottom: -23rem;
    height: 10.2rem;
  }

  .header-para{
    margin-top: 0;
    height: 2.563rem;
    font-size: 2rem;
  }

  .carousel-control-next {
    right: 1.5rem;
    top: 32.5rem;
  }

  .carousel-control-prev {
    left: 1.5rem;
    top: 32.5rem;
  }

  .carousel-indicators {
    top: 31.8rem;
    button{
      height: 0.6rem;
    }
  }

  .carousel-item-frame {
    img{
      height: 18%;
      width: 65%;
    }
  }

  .background-gradient-img{
    top: 14rem;
  }

  .clsBody{
    font-size: 18px;
  }

  .hmo-welcome-link {
    font-size:1.6rem;
    a{
      text-wrap: nowrap;
    }
  }  
}