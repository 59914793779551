ul#primary-nav {
  list-style-type: none;
  padding: 0px;

  .nav-primary-dropdown-menu {
    li a {
      padding: 0.5rem 1.5rem;
    }
    li:first-child a {
      padding-top: 0.75rem;
    }
    li:last-child a {
      padding-bottom: 0.75rem;
    }
  }

  @include media-breakpoint-up(md) {
    li {
      z-index: 10;
      position: relative;

      ul {
        left: 0;
        padding-top: .5rem;
        border-radius: 0 0 5px 5px;
        background-color: #FFFFFF;
        position: absolute;
        list-style-type: none;
        padding: 0px;
        box-shadow: 2px 2px 3px #666, -2px 2px 3px #666, ;
        border-top: 3px solid $primary-color;
        &:before {
          position: absolute;
          font-family: $font-family-icons-secondary;
          font-weight: 900;
          content: '\f0d8' !important;
          color: $primary-color;
          left: .65rem;
          top: -1.75rem;
          font-size: 2rem;
        }
        li {
          border-radius: 5px;
          background-color: #FFFFFF;

          a.dropdown-item:visited {
            color: $default-font-color;
          }
        }
      }
    }
  }


  @include media-breakpoint-down(sm) {
    ul {
      list-style-type: none;
      padding: 0px;

      li {
        a.dropdown-item {
          padding-top: 1rem;
          padding-bottom: 1rem;
          padding-right: 1rem;
          padding-left: 3rem;
          border-bottom: 1px solid ;
          color: $default-font-color;
        }
      }
    }
  }
}

pbc-footer-top-mobile {
  @include media-breakpoint-down(sm) {
    ul {
      list-style-type: none;
      padding: 0px;

      li {
        a.dropdown-item {
          padding-top: 1rem;
          padding-bottom: 1rem;
          padding-right: 1rem;
          padding-left: 3rem;
          border-bottom: 1px solid $neutral-color-light;
          color: $default-font-color;
        }
      }
    }
  }
}


#secondary-nav {
  position: relative;
  padding-left: 1rem;
  justify-content: end;
  ul {
    border-radius: 0 0 5px 5px;
    background-color: #FFFFFF;
    position: absolute;
    list-style-type: none;
    padding: 0px;
    box-shadow: 2px 2px 3px #666, -2px 2px 3px #666, ;
    border-top: 3px solid $primary-color;

    li {
      border-radius: 5px;
      background-color: #FFFFFF;

      a.dropdown-item:visited {
        color: $default-font-color;
      }

    }
  }
}

.switch-lob-container {
  a {
    font-weight: 400;
    font-size: .9rem;
    padding: 0;
    color:#000;
  }
}