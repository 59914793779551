.badge {
  font-family: $font-family-sans-serif;
  font-weight: 500;
  padding: 0.4em 0.5em;
}
.badge-termination-warning {
  color: #242424;
  background-color: #fff3f5;
  font-weight: 400;
  font-style: normal;
  display: flex;
  padding: 1rem 1rem;
  .material-icons {
    color: #a7344c !important;
    vertical-align: top !important;
    font-size: 1.3rem;
    padding-top:0.1rem;
  }
}

