@mixin display-secondary {
  font-family:$font-family-sans-serif;
  color: $display-secondary-font-color;
  font-weight: $font-weight-light;
}

.display-1 {
  @include display-secondary();
  font-size: $display1-secondary-size;
  color: #000 !important;
}
.display-2 {
  @include display-secondary();
  font-size: $display2-secondary-size;
  color: #000 !important;
}
.display-3 {
  @include display-secondary();
  font-size: $display3-secondary-size;
  color: #000 !important;
}
.display-4 {
  @include display-secondary();
  font-size: $display4-secondary-size;
  color: #000 !important;
}

.display-secondary-1 {
  @include display-secondary();
  font-size: $display1-secondary-size;
}
.display-secondary-2 {
  @include display-secondary();
  font-size: $display2-secondary-size;
}
.display-secondary-3 {
  @include display-secondary();
  font-size: $display3-secondary-size;
}
.display-secondary-4 {
  @include display-secondary();
  font-size: $display4-secondary-size;
}
.display-tertiary-1 {
  @include display-secondary();
  font-size: $display1-secondary-size;
  color: $accent-tertiary-color !important;
}
.display-tertiary-2 {
  @include display-secondary();
  font-size: $display2-secondary-size;
  color: $accent-tertiary-color !important;
}
.display-tertiary-3 {
  @include display-secondary();
  font-size: $display3-secondary-size;
  color: $accent-tertiary-color !important;
}
.display-tertiary-4 {
  @include display-secondary();
  font-size: $display4-secondary-size;
  color: $accent-tertiary-color !important;
}
.fallback-card{
  font-size: 0.9rem;
}