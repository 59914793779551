.popover-container {
  display: inline-block;
  position: relative;

  &.expanded:before {
    content: '';
    right: 30%;
    top: 3rem;
    position: absolute;
    z-index: 2;
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid #DEDEDC;
  }

  &.expanded:after {
    content: '';
    right: 32%;
    top: 3.1rem;
    position: absolute;
    z-index: 2;
    width: 0;
    height: 0;
    border-left: 22px solid transparent;
    border-right: 22px solid transparent;
    border-bottom: 22px solid $accent-background;
  }

  .popover-container-header {
    display: none;

    @include media-breakpoint-down(xs) {
      display: block;
      background: $neutral-color-dark;
      color: white;
      padding: 1.5rem 1rem;
      height: 4.5rem;
      top: 0;
      width: 100%;
      right: 0;
      bottom: 0;
      position: fixed;
      z-index: 2;

      * {
        color: #fff;
      }
    }

    a {
      color: #fff;
      float: right;

      &:hover {
        cursor: pointer;
      }
    }

    i {
      color: #fff;
    }
  }

  .popover-container-body {
    position: absolute;
    top: 3.8rem;
    width: calc(100vw - 120%);
    right: 0;
    padding: 2rem;
    z-index: 2;
    background-color: $accent-background;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    border: 1px solid #DEDEDC;

    @include media-breakpoint-down(xs) {
      top: 4.5rem;
      width: 100%;
      right: 0;
      bottom: 0;
      position: fixed;
      padding: 1rem;
      z-index: 2;
      overflow-y: scroll; //mobile only, scrollbar won't show
    }
  }

  .popover-container-body-fullwidth {
    position: absolute;
    top: 3.8rem;
    width: calc(100vw - 100%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    right: 0;
    padding: 2rem;
    z-index: 4;
    background-color: $accent-background;
    border: 1px solid #DEDEDC;

    @include media-breakpoint-down(xs) {
      top: 4.5rem;
      width: 100%;
      right: 0;
      bottom: 0;
      position: fixed;
      padding: 1rem;
      z-index: 2;
      overflow-y: scroll; //mobile only, scrollbar won't show
    }
  }

  .popover-container-footer {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid $neutral-color-dark;

    @include media-breakpoint-down(xs) {
      border-top: none;
    }
  }

  .popover-container-footer-bt {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: none;

    @include media-breakpoint-down(xs) {
      border-top: none;
    }
  }

  .select select,
  .form-control {
    background: $accent-background;
  }

  .btn {
    margin-right: 0;
  }
  .btn-pad {
    padding: 5px 30px;
  }
}