.media {
  img.icon-small {
    max-width: 1.8rem;
    margin-right: .5rem;
    margin-top: .2rem;
  }
}




