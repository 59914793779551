.expanding-row {
  padding: 10px 20px;
  transition: all .15s ease-in-out;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  background:#fff;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &.expanded {
    margin: 10px 0;
    .expand-detail {
      border-top: solid 1px #ddd;
      margin-top:12px;
      padding: 12px 0;
    }
  }
}
