// .well {
//   .donut {
//     .donut-hole {
//       fill: $accent-background;
//       stroke: #c0bdb1;
//       stroke-width: 1;
//     }
//   }
// }
// .donut {
//   height: 100%;
//   width: 100%;

  .stop1 {
    stop-color: $primary-color;
    offset: 0%;
  }

  .stop2 {
    stop-color: $accent-color;
    offset: 100%;
  }

//   .donut-text {
//     fill: #000;
//     -moz-transform: translateY(0.25em);
//     -ms-transform: translateY(0.25em);
//     -webkit-transform: translateY(0.25em);
//     transform: translateY(0.25em);
//   }

//   .donut-segment {
//     // fill: transparent;
//     // stroke: url(#gradient1);
//     // stroke-width: 3;
//     // stroke-dashoffset: 25;
//   }

//   .donut-ring {
//     // fill: transparent;
//     // stroke: #d2d3d4;
//     // stroke-width: 3;
//   }

//   .donut-hole {
//     fill: #fff;
//     stroke: #c0bdb1;
//     stroke-width: 1;
//   }

//   .donut-number {
//     font-size: .6em;
//     font-weight: 300;
//     line-height: 1;
//     text-anchor: middle;
//     -moz-transform: translateY(-0.2rem);
//     -ms-transform: translateY(-0.2rem);
//     -webkit-transform: translateY(-0.2rem);
//     transform: translateY(-0.2rem);
//   }

//   .donut-label {
//     font-size: 0.16em;
//     text-anchor: middle;
//     -moz-transform: translateY(0.01rem);
//     -ms-transform: translateY(0.01rem);
//     -webkit-transform: translateY(0.01rem);
//     transform: translateY(0.01rem);
//   }

//   .donut-total {
//     font-size: 0.12em;
//     text-anchor: middle;
//     -moz-transform: translateY(0.15rem);
//     -ms-transform: translateY(0.15rem);
//     -webkit-transform: translateY(0.15rem);
//     transform: translateY(0.15rem);
//   }
// }

.svg-container {
	display: inline-block;
	position: relative;
	width: 100%;
	padding-bottom: 61%;
	vertical-align: middle;
  overflow: hidden;
  margin: 1rem 0;
}

.svg-content {
	display: inline-block;
	position: absolute;
	top: 0;
  left: 0;

}

.well {
  .donut-hole {
    fill: $accent-background;
  }
}

.donut-ring {
  fill: $neutral-color-light;
  stroke: #c0bdb1;
  stroke-width: 1;
  stroke-miterlimit: 10;
}

.donut-segment {
  fill: transparent;
  // stroke: pink;
  stroke: url(#gradient1);
  stroke-width: 20;
}

.donut-hole {
  fill: #fff;
  stroke: #c0bdb1;
  stroke-width: 1;
}

.donut-text-container {
    z-index: 9999;
    position: absolute;
    text-align: center;
    /* top: 0; */
    /* bottom: 0; */
    /* left: 0; */
    /* right: 0; */
    /* padding-top: 16%; */
    /* position: relative; */
    top: 50%;
    transform: translateY(-50%);
    /* left: 50%; */
    /* transform: translateX(-50%); */
    width: 100%;

}

.donut-text {
  background: pink;
  width: 60%;
  margin: 15% auto;

  // position: absolute;
  // top: 25%;
  // left: 38%;
// height: 100%;

//   div {
//     display: block;
//     width: 100%;
//   }

  // padding: 3rem;
  // opacity: .5;
  // @include media-breakpoint-up(xxl) {
  //   background: red;
  // }
  // @include media-breakpoint-down(xl) {
  //   background: green;
  // }
  // @include media-breakpoint-down(lg) {
  //   background: lime;
  // }
  // @include media-breakpoint-down(md) {
  //   background: yellow;
  // }
  // @include media-breakpoint-down(sm) {
  //   background: pink;
  // }
  // @include media-breakpoint-down(xs) {
  //   background: white;
  //   padding: 8rem;
  // }
}
