// -----------------------------------------------------------------------------
// This file contains all styles related to the link
// -----------------------------------------------------------------------------

/***************************************************
        TABLE OF CONTENTS

- links base
- link primary
- link secondary
- link tertiary
- link uppercase
- links with icons

******************************************************/


/**
** Base Link style
**/


a.disabled {
  pointer-events: none;
  color: #D3D3D3 !important;
}

a.link {
  color: $primary-color;
  &:hover {
    color: $primary-color;
    text-decoration: underline;
  }
}

/**
** Primary link
**/
a.link-primary {
  background  : $primary-color;
  border-color: $primary-color;
  color       : #fff;

  &:hover {
    box-shadow: $default-drop-shadow;
  }

  &:active,
  &:visited {
    box-shadow: none;
    color     : #fff;
  }

  &.alternate {
    background: #fff;
    color     : $default-font-color;

    &:hover {
      color: #fff;
      background: $primary-color;
      border-color: #fff;
    }

    &:active,
    &:visited {
      box-shadow: none;
      color     : #fff;
    }


  }

  &:disabled,
  &.disabled {
    background  : $disabled-color;
    border-color: $disabled-color;
    box-shadow  : none;
    &:hover{
      background  : $disabled-color;
      border-color: $disabled-color;
      box-shadow  : none;

    }
  }
}



/**
** Secondary link
**/
a.link-secondary {
  background    : none;
  padding       : 0 1rem 0 0;
  color         : $default-font-color;
  font-weight   : 500;
  box-shadow    : none;
  text-transform: none;
  font-size     : 1rem;
  padding-right : 1rem;

  &.alternate {
    background: transparent;
    color     : #ffffff;
    text-decoration: underline;
    &:after {
      color: #fff;
    }
    &:hover {
      color: #fff !important;

      &:after {
        color: #ffffff;
      }
    }
  }

  &:hover {
    color: darken($primary-color, 5%);
    &:after {
      color: $primary-color;
    }
  }
}



/**
** Tertiary link
**/
a.link-tertiary {
  background    : none;
  padding       : 0 1rem 0 0;
  color         : $primary-color;
  font-weight   : 500;
  box-shadow    : none;
  text-transform: none;
  font-size     : 1rem;
  padding-right : 1rem;

  &.alternate {
    background: transparent;
    color     : #ffffff;
    &:after {
      color: #fff;
    }
    &:hover {
      color: #fff !important;

      &:after {
        color: #ffffff;
      }
    }
  }

  &:hover {
    color: darken($primary-color, 5%);
    &:after {
      color: $primary-color;
    }
  }

  &::after {
    font-family: $font-family-icons-secondary;
    content    : '\f054';
    font-weight: 600;
    font-size  : .8rem;
    line-height: 1rem;
    position   : relative;
    bottom     : .08rem;
    left       : .3rem;
    color      : $tertiary-color;
    margin-right: 1rem;
  }
  &.ex-link::after {
    font-family: $font-family-icons-secondary;
    font-size  : .8rem;
    bottom     : .1rem;
    left       : .2rem;
    margin-right: 1rem;
    content    : '\f35d';
  }
}


/**
**  link Uppercase
**/
a.link-uppercase {
  text-transform: uppercase;

  &:hover  {

  }

  &:active,
  &:visited {

  }

  &.alternate {

    &:hover {

    }

    &:active,
    &:visited {

    }
  }
}

/**
** links with icons
**/

a.download {
  position: relative;
  padding-right: 1.2rem;

  &::after {
    font-family: $font-family-icons-secondary;
    content: '\f019';
    position: absolute;
    right: 0;
    font-size: 1rem;
  }
}

a.download {
  position: relative;
  padding-right: 1.2rem;

  &::after {
    font-family: $font-family-icons-secondary;
    content: '\f019';
    position: absolute;
    font-weight: 600;
    right: 0;
    font-size: 1rem;
    margin-right: .2rem;
    margin-top: -.1rem;
  }
}

a.external {
  position: relative;
  padding-right: 1.2rem;

  &::after {
    font-family: $font-family-icons-secondary;
    font-weight: 600;
    position: absolute;
    right: 0;
    font-size: 1rem;
    margin-right: .2rem;
    margin-top: 0;
    content: '\f35d';
  }
}

a.word, a[href$=".doc"], a[href$=".docx"] {
  position: relative;
  padding-right: 1.2rem;

  &::after {
    font-family: $font-family-icons-secondary;
    content: '\f1c2';
    position: absolute;
    right: 0;
    font-size: 1rem;
    margin-right: .2rem;
    margin-top: 0;
  }

  &.no-icon {
    padding-right: 0;
    margin-right: 0;

    &::after {
      content: ' ';
    }
  }
}

a.pdf, a[href$=".pdf"] {
  position: relative;
  padding-right: 1.2rem;

  &::after {
    font-family: $font-family-icons-secondary;
    content: '\f1c1';
    position: absolute;
    right: 0;
    font-size: 1rem;
    margin-right: .2rem;
    margin-top: 0;
  }

  &.no-icon {
    padding-right: 0;
    margin-right: 0;

    &::after {
      content: ' ';
    }
  }
}

a.ppt, a[href$=".ppt"]  {
  position: relative;
  padding-right: 1.2rem;

  &::after {
    font-family: $font-family-icons-secondary;
    content: '\f1c4';
    position: absolute;
    right: 0;
    font-size: 1rem;
    margin-right: .2rem;
    margin-top: 0;
  }

  &.no-icon {
    padding-right: 0;
    margin-right: 0;

    &::after {
      content: ' ';
    }
  }
}

a.xls, a[href$=".xls"] {
  position: relative;
  padding-right: 1.2rem;

  &::after {
    font-family: $font-family-icons-secondary;
    content: '\f1c3';
    position: absolute;
    right: 0;
    font-size: 1rem;
    margin-right: .2rem;
    margin-top: 0;
  }

  &.no-icon {
    padding-right: 0;
    margin-right: 0;

    &::after {
      content: ' ';
    }
  }
}

.hover:hover {
  cursor: pointer !important;
}

.bold-links {
 a {
   font-weight: bold;
   text-decoration: underline;

   &:hover, &:active, &:focus,&:visited{
    font-weight: bold;
    text-decoration: underline;
   }
 }
}

a.bold-link {
  font-weight: bold;
  text-decoration: underline;

  &:hover, &:active, &:focus,&:visited{
   font-weight: bold;
   text-decoration: underline;
}
}