.popover {
  box-shadow: $default-drop-shadow;
  border-radius: $border-radius;
  border: none;
  @include media-breakpoint-up(sm) {
    max-width: 300;
  }

  a.download {
    display: block;

    &::before {
    font-weight: 900;
    content: '\f56d';
    }
  }

  .popover-body {
    background: #454545;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
    padding: 1.5rem;
    border-radius: $border-radius;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}


a[ngbpopover],a[ng-reflect-ngb-popover]  {
  i {
    color: #636054;

    &:hover {
      cursor: pointer;
     }
  }
}

.bs-popover-top .arrow::after, .bs-popover-auto .arrow::after
 {
  bottom: 1px;
  border-top-color: #454545;
}

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #454545;
}

.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 2px;
  border-bottom-color: #454545;
}

  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 1px;
  }

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #454545;
}


// Form some reason, default ngb renders the arrow off by a couple pixels.  This fixes
.bs-popover-bottom[_nghost-c3] .arrow[_ngcontent-c3], .bs-popover-top[_nghost-c3] .arrow[_ngcontent-c3] {
  margin-left: -9px !important;
}

.organisms-modal-dialog-screen- {
  height: 1502px;
  width: 1440px;
}

.background_selpcp {
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #FFFFFF;

  .modal-content {
    width: fit-content;
    border: none;
  }

  @media screen and (max-width:700px) {
    width: 580px;
  }

  @media screen and (min-width:950px) and (max-width:1023px) {
    width: 600px;
  }
   
  @media screen and (min-width:350px) and (max-width:540px) {
    width: unset;
  }
  
  @media screen and (min-width:541px) and (max-width:600px) {
    width: 500px;
  }

  @media screen and (min-width:601px) and (max-width:700px) {
    width: 580px;
  }

  @media screen and (min-width:701px) and (max-width:1024px) {
    width: 590px;
  }

  @media screen and (min-width:1025px) and (max-width: 1366px) {
    width: 640px;
  }
}

@media screen and (min-width:350px) and (max-width:1024px) {
  .background_selpcp {
    .header_pcpSel {
      .close {
        position: relative;
        top: -55px;
        left: 5px;
      }
    }
    .providence-medical-c input[type=radio] {
      top :2px;
    }
    @media screen and (min-width:531px) and (max-width:625px) {
      .header_pcpSel {
        .close {
          top: -45px;
          left: 10px;
        }
      }
    }

    @media screen and (min-width:626px) and (max-width:700px) {
      .header_pcpSel {
        .close {
          top: -30px;
          left: 8px;
        }
      }
    }
    @media screen and (min-width:701px) and (max-width:715px) {
      .header_pcpSel {
        .close {
          top: -25px;
          left: 45px;
        }
      }
    }

    @media screen and (min-width:716px) and (max-width:767px) {
      .header_pcpSel {
        .close {
          top: -25px;
          left: 100px;
        }
      }
    }
    @media screen and (min-width:768px) and (max-width:1024px) {
      .header_pcpSel .close {
        top:-0px
      }
    }
  }
}
@media screen and (min-width:1440px) and (max-width:2199px) {
  .header_pcpSel .close {
    margin-left: 9rem;
  }
}
@media screen and (min-width:2200px) and (max-width:3000px) {
  .modal-header .close {
    margin: -1rem -7rem -1rem auto;
  }
  .providence-medical-c input[type=radio] {
    top:13px;
  }
  .pcpsel_modal_btn {
    padding: unset;
  }
}