// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/* override variable paths */
$image-path: 'img';

/* global style settings */
html,
body {
  background: $white;
  color: $default-font-color !important;
  font-family: $font-family-sans-serif !important;
  font-weight: 400 !important;
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 18px; //rems relative to this setting

  @include media-breakpoint-up(xxl) {
    font-size: 36px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 24px;
  }

  @include media-breakpoint-down(lg) {
    font-size: 18px;
  }

  @include media-breakpoint-down(md) {
    font-size: 16px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 14px;
  }
}

body .container-fluid {
  background: $white;
  max-width: 100% !important;
}

body.fixed {
  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
}

p.highlighted-paragraph {
  font-weight: 500;
}

hr {
  margin: 20px 0px !important;
}

/* material gaps? */
.material-icons {
  vertical-align: text-top;
}
.material-icons.error {
  vertical-align: top !important;
  color: #117bab !important;
}

select {
  padding: 10px 0;
  width: 100%;
  border: none;
  border-bottom: solid 1px #bdbdbd;
  // outline: none;
}

// this is so that each page section can be set to container or container-fluid and extra markup isn't needed for the h1
// if an H1 needs to be container-fluid, use class put on in router file
// h1, h1 + pbc-alert > div {
//   max-width: $max-width;
//   margin: 0 auto;
// }

.container-fluid.main-loading {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

main {
  padding-left: 3rem;
  padding-right: 3rem;

  @include media-breakpoint-down(sm) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include media-breakpoint-down(xs) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

#mainContent:focus {
  outline: none;
}
