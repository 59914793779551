.toggle-container {
  display: block;
  position: relative;

  h3 {
    height: 33px;
    width: 96px;
    color: #313234;
    font-family: Lora;
    font-size: 1.56rem;
    letter-spacing: 0;
    line-height: 33px;
    margin-bottom: 2rem;
  }

  label {
    color: #292931;
    font-family: "Roboto", sans-serif;
    font-size: 1.125rem;
    letter-spacing: 0;
    line-height: 27px;
  }

  .right-text {
    text-align: right !important;
  }

  @media only screen and (min-device-width:500px) and (max-device-width:540px) {
    .filterButton {
      margin-top:40px;
    }
  }

  .filterButton {
    position: relative;
    float: right;
    box-sizing: border-box;
    border: 2px solid #63666A;
    color: #63666A;
    border-radius: 5px;
    background-color: #FFFFFF;  
    padding: 10px 30px;
    font-size: 16px;
    
    &.pressed {
      background: #454545;
      color: #ffffff;
    }
  }

  .filterButton:hover {
    background: #454545;
    color: #ffffff;
  }

  select {
    box-sizing: border-box;
    height: 30px !important;
    width: 179px;
    border: 1px solid #EFEFEC !important;
    background-color: #EFEFEC !important;
    padding: 0;
    min-height: 30px;
  }

  .form-check {
    margin-top: 2rem !important;
  }

  .toggle-container-header {
    display: none;

    @include media-breakpoint-down(xs) {
      display: block;
      background: $neutral-color-dark;
      color: white;
      padding: 1.5rem 1rem;
      height: 4.5rem;
      top: 0;
      width: 100%;
      right: 0;
      bottom: 0;
      position: fixed;
      z-index: 2;

      * {
        color: #fff;
      }
    }

    a {
      color: #fff;
      float: right;

      &:hover {
        cursor: pointer;
      }
    }

    i {
      color: #fff;
    }
  }

  .toggle-container-body {
    position: absolute;
    display: inline-block;
    width: 100%;
    padding: 2rem;
    background-color: #FFFFFF;
    border: 0.5px solid #000000;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.5);
    border: 1px solid #DEDEDC;
    margin-bottom: 3rem;
    box-sizing: border-box;
    margin-top: 50px;
    z-index: 101;

    @include media-breakpoint-down(xs) {
      top: 4.5rem;
      width: 100%;
      position: absolute;
      padding: 1rem;
      z-index: 101;
    }
  }

  .toggle-container-footer {
    padding-top: 1rem;
    margin-top: 2rem;


    @include media-breakpoint-down(xs) {
      border-top: none;
    }
  }

  .select select,
  .form-control {
    background: $accent-background;
  }

  .btn {
    margin-right: 0;
  }

}

.toggle-footer button {
  font-size: 0.875rem;
}

@media screen and (max-width:767px) {
  .filter-rightBtn {
    margin-top: -33px;
  }
}

@media screen and (min-width:1920px) {
  .filter-rightBtn {
    margin-top: unset !important;
  }  
}
