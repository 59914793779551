.background-info {
  // background: lighten($color: $blue, $amount: 45%);
  padding: 1rem;
  border-radius: 0.0625rem;
  background-color: $bg-info;
  padding-left: 2.3rem;

  i {
    color: #117BAB;
  }
}

.border-dark-notify {
  border-bottom: 0.0625rem solid #117bab;

  .btn-tertiary::after {
    top: 0.10rem;
  }
}

.circle-color {
  background-color: #117BAB;
}

.notifications {
  height: 3.9375rem;
  width: 4.75rem;
  float: right;
  border-width: 0rem;
  background-color: white;
}

.rectangle-text {
  height: 1.25rem;
  width: 3.25rem;
  border-radius: 0.625rem;
  background-color: #117BAB;
}

.text-msg {
  height: 0.875rem;
  width: 2rem;
  color: #FFFFFF;
  font-size: 0.6875rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 0.875rem;
  position: relative;
  top: -0.125rem;
}

.hearder-over {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-top-left-radius: calc(0.3rem - 0.0625rem);
  border-top-right-radius: calc(0.3rem - 0.0625rem);
}

.bg {
  height: 2.8125rem;
  // width: 31.25rem;
}

.notify-popover .popover {
  box-shadow: $default-drop-shadow;
  border-radius: $border-radius;
  border: 0.0625rem;
  border-color: black;
  max-width: 31.25rem;

  @include media-breakpoint-up(sm) {
    max-width: 300;
  }

  a.download {
    display: block;

    &::before {
      font-weight: 900;
      content: '\f56d';
    }
  }
}

.notify-popover .popover-body {
  background: none;
  color: black;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.0625rem;
  padding: 0rem;
  border-top: 0.25rem solid #117BAB;
  border-left: 0.125rem solid black;
  border-right: 0.125rem solid black;
  border-bottom: 0.125rem solid black;
  //border-radius: $border-radius;

  a {
    color: transparent;
    text-decoration: none;
  }
}

.notify-popover .bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 0.125rem;
  //border-bottom-color: #454545;
  border-bottom-color: #117BAB;
}

.notify-popover .bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  /* margin-top: 0.5rem; */
  margin-top: 0rem;
}

.auto-message {
  color: #000000;
  font-size: 0.875rem;
  font-weight: 550;
  letter-spacing: 0;
}

.auto-message-read {
  color: #606060;
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0;
}

.notification_count {
  width: auto;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  border-radius: 0.125rem;
  background-color: #B00020;
  position: relative;
  float: right;
  left: -39%;
  top: 0.4rem;
  padding: 0rem 0.125rem 0rem 0.125rem;
}

.bs-popover-bottom {
  .popover-header {
    border-top: 0.25rem solid #117BAB;
    border-bottom: 0.125rem solid #117bab99;
    padding: 0.15rem 0.75rem 0.45rem 0.75rem;
    background-color: white;
  }

  .popover-header::before {
    border-bottom: none !important;
  }

  .popover-body {
    border: none;
  }
}

.notify_nullMsg {
  height: 1.1875rem;
  color: #117BAB;
  font-family: "Roboto", sans-serif !important;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.1875rem;
  text-align: center;
}

.notify_nullbg {
  height: 3.4375rem;
}

.notify_style {
  border: 0.0625rem solid black;
  border-top-left-radius: calc(0.3rem - 0.0625rem);
  border-top-right-radius: calc(0.3rem - 0.0625rem);
  letter-spacing: 0;

  .offset-3 {
    margin-left: 22%;
  }
}

@media screen and (min-width:1360px) and (max-width:1439px) {
  .notify-popover .popover {
    max-width: 27.50rem;
  }

  .notify_style {
    .fs-16 {
      font-size: 1rem;
    }
  }
}

@media screen and (min-width:1440px) and (max-width:2199px) {
  .notify-popover .popover {
    max-width: 25.50rem;
  }

  .notify_style {
    .fs-16 {
      font-size: 1rem;
    }
  }
}

@media screen and (min-width:2200px) and (max-width:3000px) {
  .notify-popover .popover {
    max-width: 26rem;
  }

  .notify_style {
    .fs-16 {
      font-size: 16px;
    }
  }
}

@media only screen and (min-width:340px) and (max-width:640px) {

  /* For tablets: */
  .notify-popover .popover {
    max-width: 23.8125rem;
  }

  .notify_style {
    .offset-2 {
      margin-left: 10%;
    }

    .offset-3 {
      margin-left: 15%;
    }

    .fs-16 {
      font-size: 0.8rem;
    }

    .notify-delete {
      font-size: 0.8rem;
      position: absolute;
      right: -3.5rem;
    }

  }
}

.notification-scroll {
  overflow-y: scroll;
  max-height: 31.25rem;

  .card .card-body {
    padding-left: 2.3rem !important
  }
}

.notify-close {
  border: none;
  background-color: white;
  font-size: 1.5625rem;
  position: relative;
  top: -0.5625rem;
}

.notify-header {
  font-size: 1.125rem;
  margin-right: 0.625rem;
  font-weight: bold;
}

.notify-delete {
  font-size: 12px;
  position: absolute;
  right: -3.5rem;
}

.unread-notify-date {
  color: #606060;
  font-size: 1rem;
}

// .notify-name-left{
//   margin-left:-1.5625rem;
// }

.notification-background-normal {
  padding: 1rem;
  border-radius: 0.0625rem;
  padding-left: 2.3rem;
}