
/***************************************************
        TABLE OF CONTENTS

- Grid
- Utilities
- Color
- Typography
- Buttons
- Form
- Table
- Cards

******************************************************/


/**********
**  GRID
**
**********/

$max-width      : 1175px;
$max-width-fluid: 100%;

// BREAK POINTS
$grid-breakpoints: (
  xs  : 0,
  sm  : 768px,
  md  : 1024px,
  lg  : 1440px,
  xl  : 2200px,
  xxl : 2800px,
  xxxl: 3800px
  );

// $container-max-widths: (
//   xs  : 100%,
//   sm  : 100%,
//   md  : 100%,
//   lg  : 100%,
//   xl  : 100%,
//   xxl : 100%,
//   xxxl: 100%
//   );



/// Relative or absolute URL where all assets are served from
// @type String
// @example scss - When using a CDN
// $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/';

/************
**  UTILITY
**
*************/
$spacer : 1rem !default;
$spacers: () !default;
$spacers: map-merge((
    0: 0,
    1: ($spacer * 0.25),
    2: ($spacer * 0.5),
    3: $spacer,
    4: ($spacer * 1.5), 
    5: ($spacer * 3)),
  $spacers);

/************
**  COLOR
**
*************/
$brand-color           : #0099d8;
$primary-color         : #117bab;  // accessibility blue
$primary1-color        : #82c341;
$primary2-color        : #004053; // HMO accessibility blue 
$primary3-color        : #0C2340; // Kinwell Navy
$primary4-color        : #FF671F; // Kinwell Orange
$secondary-color       : #63666a;
$secondary1-color      : #266726;
$tertiary-color        : #dd6162;
$tertiary1-color       : #266726;
$accent-color          : #67c5c9;
$accent1-color         : #e6f3d9;
$accent-secondary-color: #00A7b5;
$accent-tertiary-color : #2A8703;
$success               : #2A8703;
$warning               : #E67300;
$bg-success            : #DFE9DB;
$bg-warning            : #FEF8e1;
$bg-danger             : #FFF3F5;
$bg-info               : #F3FBFE;
$bg-hover              : #82C341;
$success-primary       : #266726;




$disabled-color: #737373;
$disabled-bg: #707478;

/**
** Neutral Colors
**/
$accent-background     : #FAFAFA;
$accent1-background    : #ececec;
$neutral-color-lightest: #F1F1F1;
$neutral-color-lighter : #E8E8E8;
$neutral-color-light   : #D6D5CE;
$neutral-color-medium  : #C1C1C1;
$neutral-color-dark    : #404144;
$neutral-color-darkest : #313234;

/**
** Typography Colors
**/
$default-font-color          : #292931;
$secondary-font-color        : #63666a;
$display-font-color          : #292931;
$display-secondary-font-color: #0099d8;
$link-font-color             : #117bab;
$link-hover-font-color       : $link-font-color;
$link-visited-font-color     : #7b23aa;

/**********
**   TYPOGRAPHY
**
************/

/**
** Familys
**/
$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-serif: 'Lora',   serif;
$font-family-base: 'Roboto', sans-serif !default;

/**
** Icons
**/
$font-family-icons: 'Material Icons';
$font-family-icons-secondary: 'Font Awesome 5 free';

/**
** Sizes Variables
**/
$font-size-base: 1rem !default;
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.8) !default;

/**
** Weights Variables
**/
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

/**
** Headers Size
**/
$h1-font-size: 2.65rem !default;
$h2-font-size: $font-size-base * 1.563 !default;
$h3-font-size: $font-size-base * 1.25 !default;
$h4-font-size: $font-size-base * 1 !default;
$h5-font-size: $font-size-base * 0.95 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: calc($spacer / 2) !default;
$headings-font-family  : $font-family-sans-serif !default;
$headings-font-weight  : 500 !default;
$headings-line-height  : 1.2 !default;
$headings-color        : #292931 !default;

$display1-size: 3.998rem !default;
$display2-size: 2.827rem !default;
$display3-size: 1.999rem !default;
$display4-size: 1.414rem !default;

$display1-secondary-size: 3.16rem !default;
$display2-secondary-size: 2.7rem !default;
$display3-secondary-size: 1.8rem !default;
$display4-secondary-size: 1.3rem !default;

// icons
$icon-size-small: 1.263rem;
$icon-size-medium: 1.5rem;
$icon-size-large: 1.894rem;
$icon-size-xl-large: 2.526rem;


/**********
**   FORM
**
************/
$formfield-background-color: $neutral-color-lightest;
$formfield-border-color    : #454545;
$formfield-border-radius   : 0.25rem;
$formfield-focused-color   : $primary-color;


/**********
**   BUTTON
**
************/
$button-border-radius: 0.25rem;

/**
** Shadow
**/
$default-drop-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12),
0 2px 10px 0 rgba(0, 0, 0, 0.12);

/**
** Table
**/
$table-header-font-weight: 600;
$table-header-font-color : $secondary-color;

$table-cell-padding: 0.8rem;
$table-condensed-cell-padding: calc($table-cell-padding/2);

$table-bg: #fff;
$table-border-color: $neutral-color-light;
$table-border-header-color: $neutral-color-medium;

/**
** Cards
**/
$card-border-radius: 0.15rem;
$border-radius: .15rem;
$border-color : #C3D5D4;
