// -----------------------------------------------------------------------------
// This file contains all styles related to the Accodion
// -----------------------------------------------------------------------------

.accordion {
  .card {
    box-shadow: none;

    .card-header {
      padding: 0;
      margin-bottom: 0;
      background-color: #FFF;
      border-bottom: none;
      // border-top: 1px solid rgba(0, 0, 0, 0.125);
      button {
        @include media-breakpoint-down(sm) {
          >div {
            >.row {
              div{
                padding-left:0;
              }
            }
          }
        }
        >div{
          >.row {
            margin:0;
            div:nth-of-type(1){
              padding-left:0;
            }
          }
        }
        .claim-header {
          &.row {
            &.mt-1 {
              div:nth-child(2){
                white-space: normal;
              }
            }
          }
        }
      }
      &:hover {
        background: $neutral-color-lightest;
        border-bottom: 1px solid $neutral-color-light;

        * {
          text-decoration: none;
        }
      }
    }

    .card-body {
      padding: 0;
    }

    .btn {
      white-space: normal;
      padding-right: 1.5rem !important;
    }

    .btn-link {
      margin:0;
      padding: 1.3rem 0rem 1.3rem 1.3rem;
      border-radius: 0;
      font-family: $font-family-sans-serif;
      display: block !important;
      width: 100%;
      text-align: left;
      font-size: 1rem;
      font-weight: 400;
      color: $default-font-color;
      position: relative;
      text-transform: none;
      border-bottom: none;
      border-top: 1px solid $neutral-color-light;
      //open
      &:before{
        font-family: $font-family-icons-secondary;
        content: '\f077';
        font-weight: 900;
        position: absolute;
        font-size: 1rem;
        position: absolute;
        right: 1.5rem;
        color: $default-font-color;
        top: 50%;
        transform: translateY(-50%);
      }
      //closed
      &.collapsed:before {
        font-family: $font-family-icons-secondary;
        content: '\f078';
        font-weight: 900;
        font-size: 1rem;
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
  }

  .card:first-child {
    box-shadow: none;
  }

  + h3 {
    margin-top: 2rem;
  }

  &.transparent {
    .card, .card-header {
      background: transparent;
    }

    .card-header .btn-link {
      padding: .5rem 0rem .5rem 0rem;

      &:before {
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }

  &.indented {
    .card .btn-link {
      padding: 1.3rem 1.3rem 1.3rem 1rem;;
    }
  }

  &.compact {
    .card .btn-link {
      margin: 0;
      padding: .5rem .5rem .5rem 0rem;

      &:before{
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      &.collapsed:before {
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }
}
