.multiple-image-columns,.multiple-icon-columns  {
  margin-bottom: 1rem;

  * {
    text-align: center;
  }

  .h1 {
    margin-top: 1.5rem;
  }

  .row {
    margin-top: 3rem;
  }

  img {
    margin-bottom: 3rem;
  }

  i {
    margin-bottom: 2rem;
    font-size: 10rem;
  }

  div[class*='col'] {
    padding-bottom: 4rem;
  }

  .bottom-centered {
    @include media-breakpoint-up(sm) {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .single-call-to-action {
    margin-top: -3.2rem; // pull up the button to counter the padding bottom on the col in the row above
  }
}
