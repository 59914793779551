.nav-pills {
  margin-bottom: 1.5rem;

  .nav-link {
    border-radius: 2px;
    display: block;
    padding: 0 16px;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size:  0.7368rem;
  font-weight: 500;
  color: #fff;
  background-color: $primary-color;
  line-height: 1.895rem;
}


.nav-selected
{
  color: #292931 !important
}