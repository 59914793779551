.account-settings {
    .account-settings-details{
        .account-info-section {
            .form-summary{
                position: relative;
                .btn {
                    position: absolute;
                    top:0;
                    right:0;
                    padding-right:0;
                    &.btn-tertiary {
                        text-transform: capitalize;
                        &:after{
                            content: "";
                        }
                    }
                }
            }
        }
        .account-privacy-section {
            table {
                margin-bottom:1.5rem;
                .exp-date {
                    text-align: right;
                }
                &.collapsible {
                    tr {
                        td {
                            font-weight: normal;
                            i {
                                font-size:1rem;
                                margin-right:3px;
                            }
                            .check {
                                i{
                                    color:$success;
                                }
                            }
                            .remove {
                                i{
                                    color:$red;
                                }
                            }
                            .sensitive-claims {
                                position: relative;
                                padding-left:1.2rem;
                                i {
                                    position: absolute;
                                    left: 0;
                                    top:3px;
                                }
                            }
                            &.collapse-col{
                                &:before, &:after {
                                    font-family: "Material Icons";
                                    position: absolute;
                                    font-size: 2rem;
                                    position: absolute;
                                    right: 0;
                                    color: $default-font-color;
                                    top: 50%;
                                    -webkit-transform: translateY(-50%);
                                    transform: translateY(-50%);
                                }
                                &.true {
                                    &:before {
                                        content:'\e313';
                                    }
                                }
                                &.false {
                                    &:before {
                                        content:'\e316';
                                    }
                                }
                            }
                        }
                        &.child-row {
                            background-color:$accent-background;
                            border:1px solid $neutral-color-light;
                            border-width:1px 1px 0;
                            overflow: hidden;
                            .accordion {
                              .card {
                                background-color: $accent-background;
                              }
                              .card-header {
                                background-color: $accent-background;
                              }
                            }
                        }
                    }
                }
            }
        }
    }
    .account-settings-email-text {
        //background-color:$accent-background;//rgba(0, 0, 0, 0.2);
        .disabled {
            >span {
                color:rgba(0, 0, 0, 0.2);;
            }
            .slider {
                background-color:rgba(0, 0, 0, 0.1);
                &:before {
                    background-color:rgba(255, 255, 250, 0.7);
                }
                &:after {
                    color:rgba(255, 255, 250, 0.7);
                }
            }
        }
        .edit-email, .edit-mobile-phone{
            position: relative;
            .btn {
                &.edit-mobile, &.add-mobile, &.edit-button{
                    position: absolute;
                    top:0;
                    right:0;
                    padding-right:0;
                }
                &.btn-tertiary {
                    text-transform: capitalize;
                    //color:#0070b3;
                    &:after{
                        content: "";
                    }
                }
                &.send-code, &.verify-code {
                    width:100%;
                }
            }
        }
    }
}

.add-auth {
    .step-form {
        .step {
            .form-check {
                padding-left:1.3rem;
            }
            .step-done {

            }
        }
    }
    .auth-btns {
        border-top:1px solid #e1e0db;;
    }
}

.add-auth-confirm {
    .sensitive-claims {
        position: relative;
        padding-left:1.2rem;
        i {
            font-size:1rem;
            margin-right:3px;
            position: absolute;
            left: 0;
            top:3px;
        }
        .check {
            i{
                color:$success;
            }
        }
        .remove {
            i{
                color:$red;
            }
        }
    }
}
