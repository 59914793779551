// -----------------------------------------------------------------------------
// This file contains all styles related to the utility classes
// -----------------------------------------------------------------------------

/***************************************************
        TABLE OF CONTENTS

-

******************************************************/


/**
** Base Link style
**/



.bleed {
  margin-left: -3rem !important;
  width: calc(100% + 6rem) !important;
  overflow-x: hidden;
  @include media-breakpoint-down(sm) {
    margin-left: -2rem !important;
    width: calc(100% + 4rem) !important;
  }
  @include media-breakpoint-down(xs) {
    margin-left: -1rem !important;
    width: calc(100% + 2rem) !important;
  }
}

.mobile-bleed {
  @include media-breakpoint-down(xs) {
    margin-left: -1rem !important;
    width: calc(100% + 2rem) !important;
    overflow-x: hidden;
  }
}


.tablet-bleed {
  @include media-breakpoint-down(sm) {
    margin-left: -2rem !important;
    width: calc(100% + 4rem) !important;
    overflow-x: hidden;
  }
}

.bottom {
  position: absolute;
  bottom: 0;

  &.centered {
    left: 50%;
    transform: translateX(-50%);
  }

  &.left {
    left: 1rem;
  }

  &.right {
    right: 1rem;
  }
}

.content-wrap {
  width: 70vw;
}
