@import './display';
@import './headers';



a {
  color: $link-font-color;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    color: $link-hover-font-color;
    text-decoration: underline;
    cursor: pointer;
  }

  &:visited {
    color: $link-hover-font-color;
    text-decoration: underline;
  }
}

// Utilities
small {
  font-size: .66rem !important;
}

.body-small {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
}

.body-smaller {
  font-size: 0.65rem;
  font-weight: 400;
}

.legal-small {
  font-size: 0.63rem;
}

// Faces
.serif {
  font-family: $font-family-serif !important;
}

.sans-serif {
  font-family: $font-family-sans-serif !important;
}

// Weights
.strong {
  font-weight: $font-weight-bold !important;
}

.stronger {
  font-weight: $font-weight-bold !important;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.cls-table:nth-child(even) {
  background-color: #F1F1F1;
}

.check-mark:before {
  content: 'L';
  font-family: arial;
  -ms-transform: scaleX(-1) rotate(-35deg);
  -webkit-transform: scaleX(-1) rotate(-35deg);
  transform: scaleX(-1) rotate(-35deg);
  display: inline-block;
  vertical-align: top;
  line-height: 1.2em;
  width: 1em;
  color: white;
  height: 1em;
  margin-right: 0.3em;
  text-align: center;
  position: absolute;
  left: 0;
  margin-left: 3px;
}

.check-dropdown-list,
.check-dropdown-list:hover {
  background-color: grey;
  color: white
}

.dropdown-toggle::after {
  float: right;
}

.preapproval-color {
  color: $primary-color;
  font-size: 1.312rem;
  letter-spacing: 0;
  line-height: 28px;
  height: 112px;
  width: 1240px;
}

.preapproval-subtitle {
  height: 112px;
  width: 1240px;
  color: #000000;
  font-size: 1.312rem;
  letter-spacing: 0;
  line-height: 28px;
}

.green-color {
  font-size: 1.125rem;
  color: #82C341;
}

.red-color {
  height: 20px;
  width: 20px;
  color: #DD6162;
}

.no-border-top {
  border-top: unset !important;
}

.chevron {
  color: #82C341;
  height: 14.59px;
  width: 9.19px;

  &:before {
    border-style: none !important;
    border-width: 0 !important;
    content: "\f054" !important;
    display: inline-block !important;
    height: 8px !important;
    margin: 0 5px 2px 0 !important;
    position: relative !important;
    width: 8px !important;
  }
}

#preapprovals-family {
  width: auto;
  color: #000000;
  font-family: Helvetica;
  font-size: 14px;
  letter-spacing: 0;
}

.pagination-summary {
  font-weight: bold;
  font-size: small;
}

.display-value-date {
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.0rem;

}

.status-icon {
  font-size: 1.9rem;
  padding: 1.47rem;
  border-radius: 2rem;
}

.status-bar {
  @include make-col(3);
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  position: relative;
  text-align: center;

  &:not(:last-child):after {
    content: '';
    height: 1px;
    background: #0791d1;
    width: 90%;
    position: absolute;
    // z-index: 99;
    left: 54%;
    top: 45%;
  }
}



@media screen and (min-width: 300px) and (max-width: 850px) {
  .filter-preapprovals {
    margin-top: 10px;
    margin-left: 20px;
  }

  @media screen and (min-width: 768px) and (max-width: 850px) {
    .filter-preapprovals {
      margin-left: unset;
      margin-top: 20px;
    }
  }
}

#filter-preapprovals {
  .btn-secondary-light {
    background: transparent;
    border: 0.1rem solid #2A8703 !important;
    color: #1a5502;
  }

  .text-success {
    color: #266726 !important;
  }
}

.visitor-table {
  width: 50%;

  td,
  th,
  tr {
    border: 1px solid grey;
    padding: 10px
  }

  .theader {
    background: $primary-color ;
    color: white;
  }
}