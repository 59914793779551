ngb-tabset {
  &.compact {
    .nav-tabs .nav-item .nav-link {

      margin-left: .5rem !important;
    }
  }
  .nav-tabs {
    overflow-x:auto;
    overflow-y: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
    flex-wrap: nowrap;
    margin-bottom: 2.9rem;
    border-color:  $neutral-color-medium;
    @media screen and (max-width: 420px) {
      margin-left:-14px;
      margin-right:-14px;
    }

    .nav-item {
      margin-bottom: 0;
      padding:.0 1rem 0 1rem;

      .nav-link {
        color:$gray-700;
        font-size: 1rem;
        font-weight: 400;
        border: 1px solid transparent;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 1.5rem 0 0.6rem 0;
        position:relative;
        background: transparent;
        &:hover::after  {
          content: '';
          position: absolute;
          width: 100%;
          height: 5px;
          background: $brand-color;
          bottom: -2px;
          left: 0;
        }

        &.active{
          font-weight: bold;
          &:after  {
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            background: $brand-color;
            bottom: -2px;
            left: 0;
          }
        }

        &.disabled:hover {
          cursor: not-allowed;
        }
      }
    }
  }
  .tab-content {
    .tab-pane {
      margin: 0rem 2rem 1rem 0rem;
    }
  }
}

.card, .well {
  ngb-tabset {
    .nav-tabs {
      margin-bottom: 0;
    }

    .tab-content .tab-pane {
      padding: 0;
      margin: 0;
    }

    .nav-tabs .nav-item .nav-link {
      font-weight: 400;
      margin-top: 1.5rem;

      &:first-child {
        margin-left: 1.5rem;
      }
    }
  }
}

.nav-tabs {
  border-bottom: 1px solid $neutral-color-medium;
  padding-left: 1rem;

  .nav-link {
    color: $default-font-color;
    font-weight: 400;
    font-size: 1.25rem;
    margin-bottom: -1px;
    padding-bottom: 1.25rem;
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &.active {
      color: $default-font-color;
      font-weight: 500;
      background-color: #fff;
      border-bottom: 5px solid $primary-color;
    }
  }

    .nav-item.show .nav-link {
      color: $default-font-color;
      font-weight: 500;
      background-color: #fff;
      border-bottom: 5px solid $primary-color;
  }
}

#by-name-tab-panel .pbc-selection-subcontent{
  justify-content: center;
}