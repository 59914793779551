/* Rules for sizing the icon. */

.material-icons,
.far {
  &.icon-small {
    font-size: $icon-size-small;
    line-height: $icon-size-small;
  }

  &.icon-medium {
    font-size: $icon-size-medium;
    line-height: $icon-size-medium;
  }

  &.icon-large {
    font-size: $icon-size-large;
    line-height: $icon-size-large;
  }

  &.icon-xl-large {
    font-size: $icon-size-xl-large;
    line-height: $icon-size-xl-large;
  }
}


i.circle-icon {
  border-radius: 50%;
  background: $neutral-color-light;
  padding: 0.2rem;
  min-width: 1rem;
  text-align: center;
  font-size: .6rem;
  vertical-align: middle;

  &.text-warning {
    background: $warning;
    color: #fff !important;
  }

  &.text-success {
    background: $success;
    color: #fff !important;
  }

  &.text-danger {
    background: $danger;
    color: #fff !important;
  }

  &.text-info {
    background: $info;
    color: #fff !important;
  }

  //doesn't pass accessibility
  .text-brand {
    color: $brand-color;
  }

  &.text-secondary {
    background: $secondary-font-color;
    color: #fff !important;
  }
}

i.fa-check,
i.fa-file-download {
  &.primary-blue {
    color: $primary-color;
  }
}

.icon-link-text {
  position: relative;
  display: block;

  i {
    position: absolute;
    left: 0;
    top: 5px;
  }

  padding-left:1rem;
}

i.circles-icon {
  border-radius: 50%;
  background: $neutral-color-light;
  padding: 0.7rem;
  min-width: 1.3rem;
  text-align: center;
  font-size: 1.5rem;
  vertical-align: middle;

  &.texts-info {
    background: #0099d8;
    color: #fff !important;
  }
}

.circles-div {
  padding-right: 1.2%;
}


// /* Rules for using icons as black on a light background. */
// .material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
// .material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

// /* Rules for using icons as white on a dark background. */
// .material-icons.md-light { color: rgba(255, 255, 255, 1); }
// .material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }
.icon-small {
  font-size: $icon-size-small;
  line-height: 1rem;
}

.icon-medium {
  font-size: $icon-size-medium;
}

.icon-large {
  font-size: $icon-size-large;
  line-height: 1rem;
}

i.primary-icon-color {
  color: $primary-color  !important;
}

i.primary1-icon-color {
  color: $primary1-color  !important; 
}

//PCP SRC start
i.circles-icon {
  border-radius: 50%;
  background: $neutral-color-light;
  padding: 0.7rem;
  min-width: 1.3rem;
  text-align: center;
  font-size: 1.5rem;
  vertical-align: middle;

  &.texts-info {
    background: #0099d8;
    color: #fff !important;
  }
}

.circles-div {
  padding-right: 1.2%;
}

//PCP SRC end
.text-Warning {
  // background: $warning;
  color: #E67300;
  position: relative;
}

.text-Success {
  color: #2A8703 !important;
  position: relative;
}

.text-AlignmentWarning {
  text-align: left;
  font-size: 18px;
  position: absolute;
  right: 20px;
}

.text-AlignmentSuccess {
  text-align: left;
  font-size: 18px;
  position: absolute;
  right: 18px;
}

.toggleSlider {
  margin-bottom: -10px;
  margin-top: 10px;
}
.icon-clr {
  color:#117bab;
  vertical-align: baseline;
}

.referral-icon {
  font-size: 15px;
  color: #82C341;
}

.referral-icon-success {
  font-size: 1.125rem;
  color: #82C341;
}

.referral-icon-denied {
  font-size: 1.125rem;
  color: #DD6162;
}

.referral-icon-voided {
  font-size: 1.125rem;
  color: #117bab;
}

.referral-icon-warning {
  font-size: 1.125rem;
}